import { Alert, Box, Button, ColumnLayout, Container, DatePicker, FormField, Header, Input, Link, Multiselect, RadioGroup, Select, SpaceBetween, Spinner, StatusIndicator, Toggle, TokenGroup } from "@amzn/awsui-components-react"
import { useEffect, useState } from "react";
import { PackagingJobProps } from "./BasicInput";
import { PackagingBarcode, PackagingJob } from "../NewPackaging";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { CREATE_TECH_DATA_REQUEST, GET_PACKAGING_JOB_DETAIL, GET_USER_LIST, TECH_DATA_REMINDER } from "src/config/urlConfig";
import { UserResponse } from "src/pages/admin/Users";

const ValueWithLabel = ({ label, children } : {label: string, children: any})  => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

interface Users {
    id: string;
    name: string;
}

const TechnicalDataContainer = ({index, packaging, job}: 
    {
        index:number, 
        packaging: PackagingBarcode,
        job: PackagingJob,
    }) => {
    const [samePocChecked, setSamePocChecked] = useState(false);
    const [dataSource, setDataSource] = useState("supplier");
    const [dueDate, setDueDate] = useState("");

    const [specsPocs, setSpecsPocs] = useState<any>([]);

    const [specsDelivered, setSpecsDelivered] = useState(false);

    const [specsReviewers, setSpecsReviewers] = useState<any>([]);

    const [tdPocs, setTDPocs] = useState<any>([]);

    const [tdDelivered, setTDDelivered] = useState(false);

    const [tdReviewers, setTDReviewers] = useState<any>([]);

    const [requestedInfo, setRequestedInfo] = useState(false);
    const [reminderSent, setReminderSent] = useState(false);

    const [buddyBarcode, setBuddyBarcode] = useState("");

    const navigate = useNavigate();

    const location = useLocation();

    const client = createClient();

    const getUserIds = (users: any[]) => {
        let ids: string[] = [];
        for (const user of users) {
            ids.push(user.value);
        }
        return ids;
    }

    const createTechnicalDataRequest = async() => {
        let reqBody: any = {};
        if (dataSource == "supplier") {
            reqBody = {
                "requests": [
                    {
                        "barcode": packaging.barcode,
                        "technicalDataType": "FROM_SUPPLIER_DATA",
                        "dateDue": Date.parse(dueDate),
                        "specsPocUserIds": getUserIds(specsPocs),
                        "specsReviewerUserIds": getUserIds(specsReviewers),
                        "technicalDrawingPocUserIds": getUserIds(tdPocs),
                        "technicalDrawingReviewerUserIds": getUserIds(tdReviewers),
                    }
                ]
            }
        }
        if (dataSource == "duplicate") {
            reqBody = {
                "requests": [
                    {
                        "barcode": packaging.barcode,
                        "technicalDataType": "DUPLICATE_PACKAGING_REPO",
                        "referenceBarcode": buddyBarcode,
                    }
                ]
            }
        }
        if (dataSource == "master") {
            reqBody = {
                "requests": [
                    {
                        "barcode": packaging.barcode,
                        "technicalDataType": "MASTER_DATA",
                    }
                ]
            }
        }
        console.log(reqBody);
        const response = await client.post(CREATE_TECH_DATA_REQUEST.replace("{id}", job.id), reqBody);
        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }
        return;
    }

    const sendRequestReminder = async() => {
        const response = await client.put(TECH_DATA_REMINDER.replace("id", job.id).replace("{barcode}", packaging.barcode));
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    const handleContainerButtonClick = (props: any) => {
        if (requestedInfo == false) {
            setRequestedInfo(true);
            createTechnicalDataRequest();
            return;
        }
        if (reminderSent == false) {
            setReminderSent(true);
            sendRequestReminder();
            // TODO: Make a backend call to send reminder for the requested data
            // Whenever this page is opened again, make a get call to see if the item is delivered
            setSpecsDelivered(true);
            setTDDelivered(true);
            return;
        }
        
        navigate('/pe/newpackaging/review/td/' + job.id + '/barcode/' + packaging.barcode, {state: {jobData: job, packagingData: packaging}});
    }

    function getContainerButtonText() {
        if (reminderSent == true) {
            // TODO: The backend calls will change this part, we will keep the reminder sent button unless
            // we get the data back from the supplier
            // It will be displayed only if the current user is the reviewer
            return "Review";
        }
        if (requestedInfo == true) {
            return "Send reminder";
        }
        return "Request information";
    }

    function getStatusText() {
        const data = location.state || {};

        if (data && data.reviewStatus) {
            if (data.reviewStatus == "approved") {
                return "Approved";
            }
            else return "Rejected"
        }

        if (reminderSent == true) {
            // TODO: This logic will change depending upon whether we have the data back from supplier
            return "Waiting for reviewer"
        }
        if (requestedInfo == true) {
            return "Waiting for supplier";
        }
        return "Requesting technical data";
    }

    function getStatusType() {
        const data = location.state || {};

        if (data && data.reviewStatus) {
            if (data.reviewStatus == "approved") {
                return "success";
            }
            else return "error";
        }
        return "pending";
    }

    const [supplierUsers, setSupplierUsers] = useState<any[]>([]);
    const [peUsers, setPeUsers] = useState<any[]>([]);
    const [pocUsers, setPocUsers] = useState<any[]>([]);


    const fetchSupplierUsers = async() => {
        const response = await client.get(GET_USER_LIST);
        if (response.data) {
            const data: UserResponse[] = response.data.users;

            const currentSupplierUsers = data.filter((obj) => obj.externalCompanyId == job.supplierId);
            const parsedDataSupplier = currentSupplierUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setSupplierUsers(parsedDataSupplier);

            const currentPEUsers = data.filter((obj) => obj.roles.includes("PACKAGING_ENGINEER"));

            const parsedDataPE = currentPEUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setPeUsers(parsedDataPE);

            const currentAdminUsers = data.filter((obj) => obj.roles.includes("ADMIN"));

            const parsedDataAdmin = currentAdminUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            console.log(currentAdminUsers);

            setPocUsers(parsedDataSupplier.concat(parsedDataAdmin));
        
            return response.data.users;
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        const fetchUserList = async () => {
            await fetchSupplierUsers();
        };
        fetchUserList()
    }, []);

    return (
    <Container
                header={
                    <Header variant="h2"
                        info={
                            <Link>Info</Link>
                        }
                        description={
                                <StatusIndicator type={getStatusType()}>
                                    {getStatusText()}
                                </StatusIndicator>
                        }
                        actions={
                            <Button onClick={handleContainerButtonClick}>{getContainerButtonText()}</Button>
                        }
                    >
                        {packaging.masterPackName + "(" + packaging.barcode + ")"}
                    </Header>
                    }
            >
                <SpaceBetween direction="vertical" size="l">
                {!requestedInfo && <RadioGroup
                    onChange={({ detail }) => setDataSource(detail.value)}
                    value={dataSource}
                    items={[
                        { value: "supplier", label: "Request data from supplier", description: "Use for standard packaging" },
                        { value: "duplicate", label: "Create duplicate from packaging repository", description: "Select if you want to create specific packaging" },
                        { value: "master", label: "Use master data", description: "Select if you don't need specific specs from supplier" }
                    ]}
                    />}
                {dataSource=="duplicate" &&
                <div>
                    <FormField label="Reference barcode">
                        <Input value={buddyBarcode} onChange={({detail}) => setBuddyBarcode(detail.value)}/>
                    </FormField>
                </div>
                    
                }
                {dataSource=="supplier" && 
                <div>
                {!requestedInfo && 
                    <Alert type="info">The fields cannot be altered after requesting information</Alert>
                }
                {!requestedInfo && <FormField
                    label="Due date"
                    >
                        <DatePicker
                            onChange={({ detail }) => setDueDate(detail.value)}
                            value={dueDate}
                            placeholder="YYYY/MM/DD"
                        />
                </FormField>}
                {requestedInfo && <ValueWithLabel label="Due date">{dueDate}</ValueWithLabel>}
                <ColumnLayout columns={2} borders="vertical">
                        <ValueWithLabel label="Specs">
                        <div>
                        <SpaceBetween direction="vertical" size="l">
                            {!requestedInfo &&<FormField label="Point of contact">
                                <Multiselect 
                                    selectedOptions={specsPocs}
                                    onChange={({ detail }) =>
                                        setSpecsPocs(detail.selectedOptions)
                                    }
                                    options={pocUsers}
                                />
                                
                            </FormField>}
                            {requestedInfo && <ValueWithLabel label="Point of contact">{specsPocs.map(item => item.label).join(', ')}</ValueWithLabel>}
                            {reminderSent && <StatusIndicator type="success">Delivered</StatusIndicator>}
                            {!requestedInfo && <FormField label="Reviewer">
                                <Multiselect
                                    selectedOptions={specsReviewers}
                                    onChange={({ detail }) =>
                                        setSpecsReviewers(detail.selectedOptions)
                                    }
                                    options={peUsers}
                                />
                            </FormField>}
                            {requestedInfo && <ValueWithLabel label="Reviewer">{specsReviewers.map(item => item.label).join(', ')}</ValueWithLabel>}
                        </SpaceBetween>
                        </div>
                        </ValueWithLabel>
                        <ValueWithLabel label="Technical Drawing">
                            <div>
                                    <SpaceBetween direction="vertical" size="l">
                                    {!requestedInfo && <FormField label="Point of contact">
                                        <Multiselect 
                                            selectedOptions={tdPocs}
                                            onChange={({ detail }) =>
                                                setTDPocs(detail.selectedOptions)
                                            }
                                            options={pocUsers}
                                        />
                                        
                                    </FormField>}
                                    {requestedInfo && <ValueWithLabel label="Point of contact">{tdPocs.map(item => item.label).join(', ')}</ValueWithLabel>}
                                    {reminderSent && <StatusIndicator type="success">Delivered</StatusIndicator>}
                                    {!requestedInfo && <FormField label="Reviewer">
                                        <Multiselect
                                            selectedOptions={tdReviewers}
                                            onChange={({ detail }) =>
                                                setTDReviewers(detail.selectedOptions)
                                            }
                                            options={peUsers}
                                        />
                                    </FormField>}
                                    {requestedInfo && <ValueWithLabel label="Reviewer">{tdReviewers.map(item => item.label).join(', ')}</ValueWithLabel>}
                                </SpaceBetween>
                            </div>
                        </ValueWithLabel>
                </ColumnLayout>
                </div>}
                </SpaceBetween>
        </Container>
)}

export const TechnicalData: React.FC<PackagingJobProps> = ({job, onJobDetailsChange}) =>  {

    const [packagingJob, setPackagingJob] = useState<PackagingJob>();

    const { jobId } = useParams<{ jobId: string }>();
    
    const client = createClient();

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", jobId || ""));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchJob = async () => {
            await fetchJobDetail();
        };
        fetchJob()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner size="big" />
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Container
                header={
                        <Header variant="h2">
                            Supplier
                        </Header>
                        }
                    >
                    <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel label="Supplier name">{packagingJob.supplierName}</ValueWithLabel>
                    </SpaceBetween>
            </Container>
            {packagingJob.barcodes.map((barcode, index) => (
                <TechnicalDataContainer
                    key={index}
                    index={index}
                    packaging={barcode}
                    job={packagingJob}
                />
            ))}
            </SpaceBetween>
        </div>
    )
}