import React, {useEffect, useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {Navigation} from "../common/navigation";
import {
    Box,
    BreadcrumbGroup,
    Button,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";
import {packnamesBreadcrumbs} from "../common/breadcrumbs";
import {PACK_NAME_TABLE_COLUMN_DEFINITION, PACK_NAME_TABLE_COLUMN_DISPLAY} from "../common/table-config";
import {GET_PACK_NAMES} from "src/config/urlConfig";
import {useNavigate} from "react-router-dom";
import {createClient} from "src/client/AxiosClient";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={packnamesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface PackNamesResponse {
    approvalStatus: string;
    id: string;
    name: string;
    packTypeId: string;
    packTypeName: string;
    productTypeId: string;
    productTypeName: string;
    subPackTypeId: string;
    subPackTypeName: string;
}

const PackNamesTable = (props: any) => {

    const [
        selectedItems,
        setSelectedItems
      ] = React.useState<any[]>([]);
      const [
          filteringText,
          setFilteringText
        ] = React.useState("");
  
      const [rawPackNamesData, setRawPackNamesData] = React.useState<PackNamesResponse[]>([]);
      const [tableLoading, setTableLoading] = useState(true);
      const [tableItems, setTableItems] = useState<PackNamesResponse[]>([]);
      const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const client = createClient();

    const fetchPackNames = async () => {
        const response = await client.get(GET_PACK_NAMES);
        if (response.data) {
            console.log(response.data.packTypes);
            const data: PackNamesResponse[] = response.data.masterPackNames;
            const parsedData = data.map(item => ({
                id: item.id,
                name: item.name,
                packTypeId: item.packTypeId,
                packTypeName: item.packTypeName,
                subPackTypeId: item.subPackTypeId,
                subPackTypeName: item.subPackTypeName,
                productTypeId: item.productTypeId,
                productTypeName: item.productTypeName,
                approvalStatus: item.approvalStatus,
            }));

            setTableItems(parsedData);
        
            return response.data.masterPackNames;
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchPackNamesList = async () => {
            setRawPackNamesData(await fetchPackNames());
            setTableLoading(false);
        };
        fetchPackNamesList()
    }, []);

    const navigate = useNavigate();

    const handleDetailsClick = () => {
        const selectedId = selectedItems[0].id;
        const filteredArray = rawPackNamesData.filter(obj => obj.id === selectedId);
        navigate('/packname/detail/'+selectedId, {state: filteredArray[0]})
    }

    const handleEditClick = () => {
      const selectedId = selectedItems[0].id;
      const filteredArray = rawPackNamesData.filter(obj => obj.id === selectedId);
      navigate('/packname/edit/'+selectedId, {state: filteredArray[0]})
    }

    return (
        <div>
        <Table
            onSelectionChange={({ detail }) =>
            {
                setSelectedItems(detail.selectedItems);
                setActionButtonDisabled(false);
            }
            }
            selectedItems={selectedItems}
            columnDefinitions={PACK_NAME_TABLE_COLUMN_DEFINITION}
            columnDisplay={PACK_NAME_TABLE_COLUMN_DISPLAY}
            items = {tableItems}
            loading={tableLoading}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="id"
            empty={
            <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
            >
                <SpaceBetween size="m">
                <b>No resources</b>

                </SpaceBetween>
            </Box>
            }
            filter={
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Find pack name"
                filteringAriaLabel="Filter pack name"
                onChange={({ detail }) =>
                    setFilteringText(detail.filteringText)
                }
            />
            }
            header={
            <Header
                counter={"(" + tableItems.length + ")"}
                info={
                    <Link id="main-info-link" variant="info">
                    Info
                    </Link>
                }
                variant="h1"
                actions={
                    <SpaceBetween
                    direction="horizontal"
                    size="xs"
                >
                    <Button onClick={() => handleDetailsClick()} disabled={actionButtonDisabled}>View Details</Button>
                    <Button onClick={() => handleEditClick()} disabled={actionButtonDisabled}>Edit</Button>
                    <Button href="#" disabled={true}>Deactivate</Button>
                    <Button variant="primary" href="/packname/add">Add pack name</Button>
                </SpaceBetween>
                }
            >
                Pack names
            </Header>
            }
            pagination={
            <Pagination currentPageIndex={1} pagesCount={1} />
            }
        />
      </div>
    );
}

export class PackNames extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout 
                    contentType="table"
                    content={
                      <PackNamesTable />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/packnames" />}
                />
            </body>
        );
    }
}