import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Navigation } from "../common/navigation";
import { Box, BreadcrumbGroup, Button, Header, Link, Pagination, SpaceBetween, Table, TextFilter } from "@amzn/awsui-components-react";
import { SUB_PACK_TYPE_TABLE_COLUMN_DEFINITION, SUB_PACK_TYPE_TABLE_COLUMN_DISPLAY } from "../common/table-config";
import { subpacktypesBreadcrumbs } from "../common/breadcrumbs";
import { useNavigate } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { GET_SUB_PACK_TYPES } from "src/config/urlConfig";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={subpacktypesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface SubPackTypesResponse {
    id: string;
    name: string;
    packTypeId: string;
    packTypeName: string;
    productTypeId: string;
    productTypeName: string;
    specFields: any[];
    dcCodeFields: string[];
}

const SubPackTypeTable = (props: any) => {
    const [
        selectedItems,
        setSelectedItems
      ] = React.useState<any[]>([]);
    const [
          filteringText,
          setFilteringText
        ] = React.useState("");
    
    const [tableItems, setTableItems] = useState<SubPackTypesResponse[]>([]);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const [rawSubPackTypesData, setRawSubPackTypesData] = React.useState<SubPackTypesResponse[]>([]);
    const [tableLoading, setTableLoading] = useState(true);

    const client = createClient();

    const fetchSubPackTypes = async () => {
        const response = await client.get(GET_SUB_PACK_TYPES);
        if (response.data) {
            const data: SubPackTypesResponse[] = response.data.subPackTypes;
            const parsedData = data.map(item => ({
                id: item.id,
                name: item.name,
                packTypeId: item.packTypeId,
                packTypeName: item.packTypeName,
                productTypeId: item.productTypeId,
                productTypeName: item.productTypeName,
                specFields: item.specFields,
                dcCodeFields: item.dcCodeFields,
            }));

            setTableItems(parsedData);
      
          return response.data.subPackTypes;
        }
        else {
          console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchPackTypesList = async () => {
            setRawSubPackTypesData(await fetchSubPackTypes());
            setTableLoading(false);
        };
        fetchPackTypesList()
    }, []);

    const navigate= useNavigate();

    const handleDetailsClick = () => {
        const selectedId = selectedItems[0].id;
        const filteredArray = rawSubPackTypesData.filter(obj => obj.id === selectedId);
        navigate('/subpacktypes/detail/'+selectedId, {state: filteredArray[0]})
    }

    const handleEditClick = () => {
        const selectedId = selectedItems[0].id;
        const filteredArray = rawSubPackTypesData.filter(obj => obj.id === selectedId);
        navigate('/subpacktypes/edit/'+selectedId, {state: filteredArray[0]});
    }
    
    return (
        <div>
        <Table
            onSelectionChange={({ detail }) =>
            {
                setSelectedItems(detail.selectedItems);
                setActionButtonDisabled(false);
            }
            }
            loading={tableLoading}
            selectedItems={selectedItems}
            columnDefinitions={SUB_PACK_TYPE_TABLE_COLUMN_DEFINITION}
            columnDisplay={SUB_PACK_TYPE_TABLE_COLUMN_DISPLAY}
            items = {tableItems}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="id"
            empty={
            <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
            >
                <SpaceBetween size="m">
                <b>No resources</b>
                </SpaceBetween>
            </Box>
            }
            // TODO: Filtering is a very complex functionality. Take time to understand what works best for APP
            filter={
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Find sub pack type"
                filteringAriaLabel="Filter sub pack type"
                onChange={({ detail }) =>
                    setFilteringText(detail.filteringText)
                }
            />
            }
            header={
            <Header
                counter={"(" + tableItems.length + ")"}
                info={
                    <Link id="main-info-link" variant="info">
                    Info
                    </Link>
                }
                variant="h1"
                actions={
                <SpaceBetween
                    direction="horizontal"
                    size="xs"
                >
                    <Button onClick={() => handleDetailsClick()} disabled={actionButtonDisabled}>View Details</Button>
                    <Button onClick={() => handleEditClick()} disabled={actionButtonDisabled}>Edit</Button>
                    <Button href="#" disabled={true}>Deactivate</Button>
                    <Button variant="primary" href="/subpacktypes/add">Add sub pack type</Button>
                </SpaceBetween>
                }
            >
                Sub Pack types
            </Header>
            }
            pagination={
            <Pagination currentPageIndex={1} pagesCount={1} />
            }
        />
      </div>
    );
}

export class SubPackType extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout 
                    contentType="table"
                    content={
                      <SubPackTypeTable />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/subpacktypes" />}
                />
            </body>
        );
    }
}