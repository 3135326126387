export const STAGE: string = "alpha";

export interface Environment {
    apiUrl: string;
    cognito: {
        domain: string,
        clientId: string,
        redirectUriSignIn: string,
        redirectUriSignOut: string,
        userPoolId: string
    }
}

export enum StageName {
    ALPHA = 'alpha',
    BETA = 'beta',
    PROD = 'prod',
}

const environments: Map<string, Environment> = new Map<string, Environment>([
    [StageName.ALPHA, {
        apiUrl: "https://api.alpha.packaging-portal.ops-integration.amazon.dev",
        cognito: {
            domain: "packaging-portal-alpha.auth.eu-west-1.amazoncognito.com",
            clientId: "5nfqviha28p5hrlardhrtfl6q7",
            redirectUriSignIn: "https://alpha.packaging-portal.ops-integration.amazon.dev/home",
            redirectUriSignOut: "https://alpha.packaging-portal.ops-integration.amazon.dev/home",
            userPoolId: "eu-west-1_XXiz6hlUR"
        }
    }],
    [StageName.BETA, {
        apiUrl: "https://api.beta.packaging-portal.ops-integration.amazon.dev",
        cognito: {
            domain: "packaging-portal-beta.auth.eu-west-1.amazoncognito.com",
            clientId: "7ia97mk2m5q9o1jld0qh207595",
            redirectUriSignIn: "https://beta.packaging-portal.ops-integration.amazon.dev/home",
            redirectUriSignOut: "https://beta.packaging-portal.ops-integration.amazon.dev/home",
            userPoolId: "eu-west-1_WY4p3G5f7"
        }
    }],
    [StageName.PROD, {
        apiUrl: "https://api.packaging-portal.amazon",
        cognito: {
            domain: "packaging-portal-prod.auth.eu-west-1.amazoncognito.com",
            clientId: "1gd3reuh3vcev5vn24cp9o5703",
            redirectUriSignIn: "https://packaging-portal.amazon/home",
            redirectUriSignOut: "https://packaging-portal.amazon/home",
            userPoolId: "eu-west-1_NJR21A94y"
        }
    }]
])

export function getEnvironmentConfig(): Environment {
    if (environments.has(STAGE)) {
        return environments.get(STAGE)!;
    } else {
        throw new Error(`Cannot find configuration for ${STAGE}.`);
    }
}
