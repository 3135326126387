import { Container, FormField, Header, Select } from "@amzn/awsui-components-react"
import { useEffect, useState } from "react";
import { createClient } from "src/client/AxiosClient";
import { CREATE_APPROVAL_REQUEST, GET_USER_LIST } from "src/config/urlConfig";
import { UserResponse } from "../Users";
import { PackNamesResponse } from "../PackNames";

interface PackNameApprovalProps {
    packNameDetail: PackNamesResponse;
    onPackNameApprovalChange: (updatedId: string) => void;
}

export const Approval: React.FC<PackNameApprovalProps> = ({packNameDetail, onPackNameApprovalChange}) => {

    const [
        selectedOption,
        setSelectedOption
      ] = useState<any>({});

    const [rawUsersData, setRawUsersData] = useState<UserResponse[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<any>([]);

    const client = createClient()

    const fetchUsers = async () => {
      const response = await client.get(GET_USER_LIST);
      if (response.data) {
        const data: UserResponse[] = response.data.users;
        const adminData = data.filter((data) => data.roles.includes("ADMIN"));
        const parsedData = adminData.map((item) => ({
            value: item.id,
            label: item.name,
        }));

        setTableItems(parsedData);

        return response.data.users;
      }
      else {
        console.log(response);
      }
      return [];
    }

    useEffect(() => {
        const fetchUserList = async () => {
            setRawUsersData(await fetchUsers());
            setTableLoading(false);
        };
        fetchUserList()
    }, []);

    const handleApprovalIdChange = (userId: string) => {
        onPackNameApprovalChange(userId);
    }

    return (
        <div>
            <Container
                header={
                    <Header variant="h2"
                        description="A new master pack name needs to be approved before it can be created"
                    >
                        {packNameDetail.name}
                    </Header>
                }
            >
                <FormField label="Approver">
                <Select
                    selectedOption={selectedOption}
                    onChange={({ detail }) =>
                        {
                            setSelectedOption(detail.selectedOption);
                            handleApprovalIdChange(detail.selectedOption.value || "");
                        }

                    }
                    options={tableItems}
                    />
                </FormField>
            </Container>
        </div>
    )
}