import { Container, Header, SpaceBetween, ColumnLayout, Checkbox, FormField, DatePicker, Multiselect, Button, Spinner } from "@amzn/awsui-components-react";
import { useState, useEffect } from "react";
import { createClient } from "src/client/AxiosClient";
import { GET_PACK_NAME, GET_SUPPLIER, GET_USER_LIST, POST_REVISION_DOCUMENT_REQUEST } from "src/config/urlConfig";
import { UserResponse } from "src/pages/admin/Users";
import { ValueWithLabel } from "src/pages/common/types";
import { RevisionDocumentBarcodeModel, RevisionJob, RevisionJobWithDocProps, RevisionJobWithDocument } from "../Revision";

const BarcodeContainer = ({ index, initialjob, job, packageData, onPackageChange }: { index: number, initialjob: RevisionJob, job: RevisionJobWithDocument, packageData: RevisionDocumentBarcodeModel, onPackageChange: (updatedData: RevisionDocumentBarcodeModel) => void}) => {
    
    const { barcode, techDrawing, artwork } = packageData;

    const [artworkChecked, setArtworkChecked] = useState(false);
    const [techDrawingChecked, setTechDrawingChecked] = useState(false);

    const [artworkDueDate, setArtworkDueDate] = useState("");
    const [techDrawingDueDate, setTechDrawingDueDate] = useState("");

    const [artworkPocs, setArtworkPocs] = useState<any>([]);
    const [artworkReviewers, setArtworkReviewers] = useState<any>([]);

    const [tdPocs, setTDPocs] = useState<any>([]);
    const [tdReviewers, setTDReviewers] = useState<any>([]);

    const [supplierUsers, setSupplierUsers] = useState<any[]>([]);
    const [peUsers, setPeUsers] = useState<any[]>([]);
    const [pocUsers, setPocUsers] = useState<any[]>([]);

    const client = createClient();

    const fetchSupplierUsers = async() => {
        const response = await client.get(GET_USER_LIST);
        if (response.data) {
            const data: UserResponse[] = response.data.users;

            console.log("job");
            console.log(job);

            const currentSupplierUsers = data.filter((obj) => obj.externalCompanyId == job.supplierId);
            const parsedDataSupplier = currentSupplierUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            console.log(currentSupplierUsers);
            setSupplierUsers(parsedDataSupplier);

            const currentPEUsers = data.filter((obj) => obj.roles.includes("PACKAGING_ENGINEER"));

            const parsedDataPE = currentPEUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            console.log(currentPEUsers);
            setPeUsers(parsedDataPE);

            const currentAdminUsers = data.filter((obj) => obj.roles.includes("ADMIN"));

            const parsedDataAdmin = currentAdminUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            console.log(currentAdminUsers);

            setPocUsers(parsedDataSupplier.concat(parsedDataAdmin));
        
            return response.data.users;
        } else {
            console.log(response);
        }
    }

    const [mpnName, setMPNName] = useState("");

    const fetchMPN = async () => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', initialjob.barcodes[index].masterPackNameId || ""));
        if (response.data) {
            console.log(response.data);
            setMPNName(response.data.masterPackName.name);
        }
        else {
            console.log(response);
        }
        return;
      }

    useEffect(() => {
        const fetchUserList = async () => {
            await fetchSupplierUsers();
        };
        fetchUserList();
        fetchMPN();
        onPackageChange({ ...packageData, barcode: initialjob.barcodes[index].barcode });
    }, []);

    const handleArtworkDueDateChange = (newDate: string) => {
        const updatedArtwork = { ...artwork, dateDue: Date.parse(newDate), externalCompanyType: "SUPPLIER"};
        onPackageChange({ ...packageData, artwork: updatedArtwork });
    };

    const handleArtworkPocsChange = (ids: string[]) => {
        const updatedArtwork = { ...artwork, pocUserIds: ids};
        onPackageChange({ ...packageData, artwork: updatedArtwork });
    };

    const handleArtworkReviewersChange = (ids: string[]) => {
        const updatedArtwork = { ...artwork, reviewerUserIds: ids};
        onPackageChange({ ...packageData, artwork: updatedArtwork });
    };

    const handleTDDueDateChange = (newDate: string) => {
        const updatedTD = { ...techDrawing, dateDue: Date.parse(newDate), externalCompanyType: "SUPPLIER"};
        onPackageChange({ ...packageData, techDrawing: updatedTD });
    };

    const handleTDPocsChange = (ids: string[]) => {
        const updatedTD= { ...techDrawing, pocUserIds: ids };
        onPackageChange({ ...packageData, techDrawing: updatedTD });
    };

    const handleTDReviewersChange = (ids: string[]) => {
        const updatedTD = { ...techDrawing, reviewerUserIds: ids };
        onPackageChange({ ...packageData, techDrawing: updatedTD });
    };

    if (mpnName == "") {
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    return (
        <div>
            <Container
              header={
                <Header description="Select the documents that need revision">
                  {initialjob.barcodes[index].barcode + " (" + mpnName + ")"}
                </Header>
              }
            >
                <SpaceBetween direction="vertical" size="l">
                  <ColumnLayout columns={2} borders="vertical">
                      <div>
                        <SpaceBetween direction="vertical" size="m">
                          <Checkbox
                            checked={artworkChecked}
                            onChange={({ detail }) =>
                                {
                                  setArtworkChecked(detail.checked);
                                }
                            }
                          >Artwork</Checkbox>

                          <FormField
                              label="Due date"
                              >
                                  <DatePicker
                                      disabled={!artworkChecked} 
                                      onChange={({ detail }) => {
                                        setArtworkDueDate(detail.value);
                                        handleArtworkDueDateChange(detail.value);
                                      }}
                                      value={artworkDueDate}
                                      placeholder="YYYY/MM/DD"
                                  />
                          </FormField>

                          <FormField label="Point of contact">
                              <Multiselect
                                  disabled={!artworkChecked} 
                                  selectedOptions={artworkPocs}
                                  onChange={({ detail }) =>
                                      {
                                        setArtworkPocs(detail.selectedOptions);

                                        let ids: string[] = [];
                                        for (const user of detail.selectedOptions) {
                                            ids.push(user.value || "");
                                        }
                                        handleArtworkPocsChange(ids);
                                      }
                                  }
                                  options={pocUsers}
                              />
                                        
                          </FormField>

                          <FormField label="Reviewer">
                              <Multiselect
                                disabled={!artworkChecked} 
                                  selectedOptions={artworkReviewers}
                                  onChange={({ detail }) =>
                                      {
                                        setArtworkReviewers(detail.selectedOptions);
                                        let ids: string[] = [];
                                        for (const user of detail.selectedOptions) {
                                            ids.push(user.value || "");
                                        }
                                        handleArtworkReviewersChange(ids);
                                      }
                                  }
                                  options={peUsers}
                              />
                          </FormField>
                          </SpaceBetween>
                      </div>
                      <div>
                          <SpaceBetween direction="vertical" size="m">
                          <Checkbox
                            checked={techDrawingChecked}
                            onChange={({ detail }) =>
                                {
                                  setTechDrawingChecked(detail.checked);
                                }
                            }
                          >Tech Drawing</Checkbox>

                          <FormField
                              label="Due date"
                              >
                                  <DatePicker
                                      disabled={!techDrawingChecked} 
                                      onChange={({ detail }) => {
                                        setTechDrawingDueDate(detail.value);
                                        handleTDDueDateChange(detail.value);
                                      }}
                                      value={techDrawingDueDate}
                                      placeholder="YYYY/MM/DD"
                                  />
                          </FormField>


                          <FormField label="Point of contact">
                              <Multiselect
                                  disabled={!techDrawingChecked} 
                                  selectedOptions={tdPocs}
                                  onChange={({ detail }) =>
                                      {
                                        setTDPocs(detail.selectedOptions);
                                        let ids: string[] = [];
                                        for (const user of detail.selectedOptions) {
                                            ids.push(user.value || "");
                                        }
                                        handleTDPocsChange(ids);
                                      }
                                  }
                                  options={pocUsers}
                              />
                                        
                          </FormField>

                          <FormField label="Reviewer">
                              <Multiselect
                                  disabled={!techDrawingChecked}
                                  selectedOptions={tdReviewers}
                                  onChange={({ detail }) =>
                                      {
                                        setTDReviewers(detail.selectedOptions);
                                        let ids: string[] = [];
                                        for (const user of detail.selectedOptions) {
                                            ids.push(user.value || "");
                                        }
                                        handleTDReviewersChange(ids);
                                      }
                                  }
                                  options={peUsers}
                              />
                          </FormField>
                          </SpaceBetween>
                      </div>
                  </ColumnLayout>
                </SpaceBetween>
            </Container>
        </div>
    )
}

export const RevisionInfo: React.FC<RevisionJobWithDocProps> = ({job, jobWithDoc, onJobDetailsChange, onJobWithDocDetailsChange})=> {

    const [packageData, setPackageData] = useState<RevisionDocumentBarcodeModel[]>([]);

    const [documentRequestSent, setDocumentRequestSent] = useState(false);

    const handlePackageChange = (index: number, newData: RevisionDocumentBarcodeModel) => {
        const updatedPackageData = [...packageData];
        updatedPackageData[index] = newData;
        setPackageData(updatedPackageData);

        const updatedJobWithDoc = { ...jobWithDoc };
        updatedJobWithDoc.revisionBarcodes = updatedPackageData;
        onJobWithDocDetailsChange(updatedJobWithDoc);
    };

    const client = createClient();

    const sendDocumentRequest = async() => {
        const reqBody = {
            "barcodes": packageData
        }
        console.log(reqBody);

        const response = await client.post(POST_REVISION_DOCUMENT_REQUEST.replace("{id}", job.id), reqBody);
        if (response.data) {
            console.log(response.data);
            setDocumentRequestSent(true);
        }
        else {
            console.log(response);
        }
        return;
    }

    const [supplierName, setSupplierName] = useState("");

    const fetchSupplier = async () => {
        const response = await client.get(GET_SUPPLIER.replace('{id}', job.supplierId || ""));
        if (response.data) {
            console.log(response.data.supplier);
            setSupplierName(response.data.supplier.name);
        }
        else {
            console.log(response);
        }
        return;
      }
  
      useEffect(() => {
          const fetchSupplierDetail = async () => {
              await fetchSupplier();
          };
          fetchSupplierDetail();
      }, []);


    if (!job || !job.barcodes || job.barcodes.length == 0 || supplierName == "") {
        console.log(job);
        return (
            <div>
                <Spinner size="big" />
            </div>
        )
    }


    return (
        <div>
          <Container
            header={
              <Header
                actions={
                    <Button disabled={documentRequestSent} onClick={sendDocumentRequest}>Request information</Button>
                }
              >
                Supplier
              </Header>
            }
          >
            <ValueWithLabel label="Supplier name">{supplierName}</ValueWithLabel>
          </Container>
          <SpaceBetween direction="vertical" size="l">
              <Header>
                  Packaging
              </Header>
              <div>
                  {
                    job.barcodes.map((packageItem, index) => (
                      <BarcodeContainer
                          key={index}
                          index={index}
                          initialjob={job}
                          job={job}
                          packageData={packageData[index] || {}}
                          onPackageChange={(newData) => handlePackageChange(index, newData)}
                      />
                    ))
                  }
              </div>
          </SpaceBetween>
        </div>
    )
}