import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { ColumnLayout, ContentLayout, FormField, Header, Link, Spinner } from "@amzn/awsui-components-react";
import { createClient } from "src/client/AxiosClient";
import { useNavigate, useParams } from "react-router-dom";
import { MPNDetail } from "../admin/PackNameDetail";
import { GET_DOCUMENT_FILE, GET_PACK_NAME } from "src/config/urlConfig";
import { ValueWithLabel } from "../common/types";

const ViewSpecs = () => {
    const [mpnDetails, setMPNDetails] = useState<MPNDetail>();

    const client = createClient();
    const navigate = useNavigate();

    const { mpnId } = useParams<{ mpnId: string }>();

    const [s3url, setS3Url] = useState<string | null>(null);

    const fetchPackName = async () => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', mpnId || ""));
        if (response.data) {
            console.log(response.data.masterPackName);
            setMPNDetails(response.data.masterPackName);
            fetchReviewDocument(response.data.masterPackName.techDrawing.versions[response.data.masterPackName.techDrawing.approvedVersion].id)
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchReviewDocument = async (fileId: string) => {
        const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", fileId))
        if (response.data) {
            setS3Url(response.data.url);
        }
        console.log(response);
    }
    
    useEffect(() => {
        const fetchMPN = async () => {
            await fetchPackName();
        };
        fetchMPN()
    }, []);
  
    if (!mpnDetails) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }
    
    return (
        <div>
            {s3url && (
                    <div style={{height: "82rem"}}>
                        <iframe style={{width: "100%", height: "80rem", overflow: "auto"}} src={s3url}/>
                    </div>
            )}
        </div>
    )
}

export class ViewMasterTD extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    navigationHide={true}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            variant="awsui-h1-sticky"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                        >
                            Technical drawing
                        </Header>
                        }
                    >
                         <ViewMasterTD />
                    </ContentLayout>
                    }
                />
            </body>
        )
    }
}