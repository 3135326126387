import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, Button, ColumnLayout, Container, ContentLayout, Header, Link, Pagination, SpaceBetween, StatusIndicator, Table, TextFilter } from "@amzn/awsui-components-react";
import { OPEN_TASKS_ADMIN_COLUMN_DEFINITION, OPEN_TASKS_ADMIN_COLUMN_DISPLAY } from "../common/table-config";
import users_thumbnail from 'src/images/users_thumbnail.png';
import product_type_thumbnail from 'src/images/product_type_thumbnail.png';
import supplier_thumbnail from 'src/images/supplier_thumbnail.png';
import job_thumbnail from 'src/images/job_thumbnail.png';
import pack_type_thumbnail from 'src/images/pack_type_thumbnail.png';
import sub_pack_thumbnail from 'src/images/pack_type_thumbnail.png';
import pack_name_thumbnail from 'src/images/pack_name_thumbnail.png';
import packaging_repository from 'src/images/packaging_repository.png';
import { Navigation } from "../common/navigation";
import { OpenTasks, TaskJobData } from "../common/types";
import { createClient } from "src/client/AxiosClient";
import { GET_OPEN_TASKS } from "src/config/urlConfig";
import { getBarcode, getDataType, getJobId, getJobType } from "../common/utils";
import { useNavigate } from "react-router-dom";

const AdminHomeContent = (props: any) => {
    const [
        selectedItems,
        setSelectedItems
    ] = useState<TaskJobData[]>([]);

    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<TaskJobData[]>([]);

    const [rawOpenTasks, setRawOpenTasks] = useState<OpenTasks[]>();

    const client = createClient();

    const fetchAllTasks = async () => {
        const response = await client.get(GET_OPEN_TASKS);
        if (response.data) {
            console.log(response);
            const data: OpenTasks[] = response.data.openTasks;

            const parsedData = data.map(item => ({
                id: item.id,
                jobType: getJobType(item.reference),
                dataType: getDataType(item.reference),
                jobId: getJobId(item.referenceId),
                barcode: getBarcode(item.referenceId),
                requiredAction: item.requiredAction,
            }));

            setRawOpenTasks(data);
            setTableItems(parsedData);
        
            return response.data.openTasks;
        }
        else {
            console.log(response);
        }
        return [];
    }

    const navigate = useNavigate();

    const handleEditClick = () => {
        if (selectedItems[0].requiredAction == "REQUIRED_APPROVAL") {
            const packName = rawOpenTasks?.filter((obj) => obj.id == selectedItems[0].id)
            navigate('/approve/task/'+selectedItems[0].id+'/packname/'+packName?.[0].referenceId)
        }
        if (selectedItems[0].dataType == "SPECS") {
            if (selectedItems[0].requiredAction == "SUBMIT_SPECS") {
                navigate('/supplier/providedata/task/'+selectedItems[0].id+'/specs/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
                return;
            }
            navigate('/review/task/'+selectedItems[0].id+'/specs/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
        }
        if (selectedItems[0].dataType == "TECH_DRAWING") {
            if (selectedItems[0].requiredAction == "UPLOAD_DOCUMENT") {
                navigate('/supplier/providedata/task/'+selectedItems[0].id+'/technicaldrawing/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
            }
            navigate('/review/task/'+selectedItems[0].id+'/technicaldrawing/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
        }
        if (selectedItems[0].dataType == "ARTWORK") {
            if (selectedItems[0].requiredAction == "UPLOAD_DOCUMENT") {
                navigate('/supplier/providedata/task/'+selectedItems[0].id+'/artwork/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
            }
            navigate('/review/task/'+selectedItems[0].id+'/artwork/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
        }
    }

    useEffect(() => {
        const fetchTaskList = async () => {
            await fetchAllTasks();
            setTableLoading(false);
        };
        fetchTaskList()
    }, []);
    
    return (
        <SpaceBetween size="xl">
            <Table
                onSelectionChange={({ detail }) =>
                    setSelectedItems(detail.selectedItems)
                }
                selectedItems={selectedItems}
                ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
                }}
                columnDefinitions={OPEN_TASKS_ADMIN_COLUMN_DEFINITION}
                columnDisplay={OPEN_TASKS_ADMIN_COLUMN_DISPLAY}
                items={tableItems}
                loadingText="Loading resources"
                selectionType="single"
                trackBy="id"
                header={
                <Header
                    counter={"("+tableItems.length+")"}
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h2"
                    actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="xs"
                    >
                        <Button onClick={handleEditClick}>Edit</Button>
                        {/* <Button>Reassign</Button> */}
                    </SpaceBetween>
                    }
                    description="Always complete your open tasks first"
                >
                    Open tasks
                </Header>
                }
            />
            <Box>
                <Header
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h2"
                    description="Click on a card below to see more details and administer the data"
                >
                    Data
                </Header>
            </Box>
            <ColumnLayout columns={3}>
                <Container
                    media={{
                        content: (
                        <img
                            src={users_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/users">Users</Link></Box>
                        <Box color="text-body-secondary">Add or edit users</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={supplier_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/suppliers">Suppliers</Link></Box>
                        <Box color="text-body-secondary">Add or edit Suppliers</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={job_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/jobs">Job overview</Link></Box>
                        <Box color="text-body-secondary">Add or edit jobs</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={product_type_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/producttypes">Product Types</Link></Box>
                        <Box color="text-body-secondary">Add or edit product types</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={pack_type_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packtypes">Pack Types</Link></Box>
                        <Box color="text-body-secondary">Add or edit pack types</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={sub_pack_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/subpacktypes">Sub Pack Types</Link></Box>
                        <Box color="text-body-secondary">Add or edit sub pack types</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={pack_name_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packnames">Pack Name</Link></Box>
                        <Box color="text-body-secondary">Add or edit pack names</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={packaging_repository}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packages">Packaging Repository</Link></Box>
                        <Box color="text-body-secondary">View all final packaging data including barcodes</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
            </ColumnLayout>
        </SpaceBetween>
    )
}

export interface Session {
    idToken: {
        jwtToken: string;
    }
}

export class AdminHome extends React.Component {
    render() {
        const mainAppProps = this.props;
        console.log(mainAppProps);
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                    <CustomAppLayout
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/home"/>}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            data-custom-color="black"           //added custom workaround for color of header title
                            variant="awsui-h1-sticky"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                            description={<span data-custom-description="black">Administer the tool to help Amazon manage packaging jobs and data</span>}
                        >
                            Amazon Packaging Portal
                        </Header>
                        }
                    >
                        <AdminHomeContent session={mainAppProps}/>
                    </ContentLayout>
                    } />
            </body>
        );
    }
}