export const appBreadcrumbs = [
    {
      text: 'APP',
      href: '/home',
    },
];

export const suppliersBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Suppliers',
        href: '/suppliers',
    },
];

export const editSupplierBreadcrumbs = [
    ...suppliersBreadcrumbs,
    {
        text: 'Edit supplier',
        href: '/suppliers/edit',
    },
];

export const addSupplierBreadcrumbs = [
    ...suppliersBreadcrumbs,
    {
        text: 'Add supplier',
        href: '/suppliers/add',
    },
];

export const supplierDetailsBreadcrumbs = [
    ...suppliersBreadcrumbs,
    {
        text: 'Supplier details',
        href: '/suppliers/detail',
    },
];

export const usersBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Users',
        href: '/users',
    },
];

export const addUsersBreadcrumbs = [
    ...usersBreadcrumbs,
    {
        text: 'Add user',
        href: '/users/add',
    },
];

export const packtypesBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Pack Types',
        href: '/packtypes',
    },
];

export const addPackTypesBreadcrumbs = [
    ...packtypesBreadcrumbs,
    {
        text: 'Add pack type',
        href: '/packtypes/add',
    },
];

export const subpacktypesBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Sub Pack Types',
        href: '/subpacktypes',
    },
];

export const addSubpacktypesBreadcrumbs = [
    ...subpacktypesBreadcrumbs,
    {
        text: 'Add Sub Pack Types',
        href: '/subpacktypes/add',
    },
];

export const editSubPackTypeBreadcrumbs = [
    ...subpacktypesBreadcrumbs,
    {
        text: 'Edit sub pack type',
        href: '/subpacktypes/edit',
    },
];

export const subPackTypeDetailBreadcrumbs = [
    ...subpacktypesBreadcrumbs,
    {
        text: 'Sub pack type details',
        href: '/subpacktypes/detail',
    },
];

export const producttypesBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Product Types',
        href: '/producttypes',
    },
];

export const addProductTypesBreadcrumbs = [
    ...producttypesBreadcrumbs,
    {
        text: 'Add product type',
        href: '/producttypes/add',
    },
];

export const productTypeDetailsBreadcrumbs = [
    ...producttypesBreadcrumbs,
    {
        text: 'Product type details',
        href: '/producttypes/detail',
    },
];

export const editProductTypesBreadcrumbs = [
    ...producttypesBreadcrumbs,
    {
        text: 'Edit product type',
        href: '/producttypes/edit',
    },
];

export const packnamesBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Pack Names',
        href: '/packnames',
    },
];

export const packnameDetailsBreadcrumbs = [
    ...packnamesBreadcrumbs,
    {
        text: 'Pack name details',
        href: '/packname/detail',
    },
];

export const addPackNameBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Add Pack Name',
        href: '/packname/add',
    },
];

export const editPacknameBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Edit Pack Name',
        href: '/packname/edit',
    },
];

export const peAppBreadcrumbs = [
    {
      text: 'APP',
      href: '/home',
    },
];

export const newPackagingBreadcrumbs = [
    ...peAppBreadcrumbs,
    {
        text: 'New packaging',
        href: '/pe/newpackaging',
    }
]

export const revisionBreadcrumbs = [
    ...peAppBreadcrumbs,
    {
        text: 'Revision',
        href: '/pe/revision',
    }
]

export const newPackagingTechnicalDataBreadcrumbs = [
    ...newPackagingBreadcrumbs,
    {
        text: 'Review technical data',
        href: '/pe/newpackaging/review/td',
    }
]

export const newPackagingArtworkBreadcrumbs = [
    ...newPackagingBreadcrumbs,
    {
        text: 'Review artwork',
        href: '/pe/newpackaging/review/artwork',
    }
]

export const newPackagingFinalSpecsBreadcrumbs = [
    ...newPackagingBreadcrumbs,
    {
        text: 'Review final specs',
        href: '/pe/newpackaging/review/finalspecs',
    }
]

export const supplierProvideDataBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Provide information',
        href: '/supplier/providedata',
    }
]

export const supplierProvideTechnicalDataBreadcrumbs = [
    ...supplierProvideDataBreadcrumbs,
    {
        text: 'Technical data',
        href: '/supplier/providedata/technicaldata',
    }
]

export const supplierProvideSpecsBreadcrumbs = [
    ...supplierProvideTechnicalDataBreadcrumbs,
    {
        text: 'Specs',
        href: '/supplier/providedata/technicaldata/specs',
    }
]

export const supplierProvideArtworkBreadcrumbs = [
    ...supplierProvideDataBreadcrumbs,
    {
        text: 'Artwork',
        href: '/supplier/providedata/artwork',
    }
]

export const jobsOverviewBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Job overview',
        href: '/jobs',
    }
]

export const jobDetailsBreadcrumbs = [
    ...jobsOverviewBreadcrumbs,
    {
        text: 'Job details',
        href: '/jobs/detail',
    }
]

export const packagesOverviewBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Packaging repository',
        href: '/repository',
    }
]

export const packageDetailBreadcrumbs = [
    ...appBreadcrumbs,
    {
        text: 'Packaging repository',
        href: '/repository/detail',
    }
]