import { ButtonDropdown, TableProps } from "@amzn/awsui-components-react";
import { useState } from "react";

export const SUPPLIER_TABLE_COLUMN_DEFINITION = [
    {
      id: "name",
      header: "Name",
      cell: (e: any) => e.name,
      sortingField: "name",
      isRowHeader: true
    },
    {
      id: "sites",
      header: "Manufacturing sites",
      cell: (e: any) => e.sites,
    },
    {
      id: "users",
      header: "Number of users",
      cell: (e: any) => e.users,
    },
    {
      id: "poc",
      header: "Main POC",
      cell: (e: any) => e.poc,
    },
    /* {
      id: 'actions',
      header: 'Actions',
      cell: (item: any) => (
        <ButtonDropdown
          variant="inline-icon"
          expandToViewport={true}
          items={[
            { id: 'view', text: 'View details' },
            { id: 'edit', text: 'Edit' },
            { id: 'deactivate', text: 'Deactivate' },
          ]}
        />
      ),
    }, */
];

export const SUPPLIER_TABLE_COLUMN_DISPLAY = [
  { id: "name", visible: true },
  { id: "sites", visible: true },
  { id: "users", visible: true },
  { id: "poc", visible: true },
  { id: "actions", visible: true }
];

export const USER_TABLE_COLUMN_DEFINITION = [
  {
    id: "name",
    header: "Name",
    cell: (item: any) => <span style={{ color: item.activeStatus === 'INACTIVE' ? 'gray' : 'inherit' }}>{item.name}</span>,
    sortingField: "name",
    isRowHeader: true
  },
  {
    id: "id",
    header: "ID",
    cell: (item: any) => <span style={{ color: item.activeStatus === 'INACTIVE' ? 'gray' : 'inherit' }}>{item.id}</span>,
  },
  {
    id: "email",
    header: "E-mail address",
    cell: (item: any) => <span style={{ color: item.activeStatus === 'INACTIVE' ? 'gray' : 'inherit' }}>{item.email}</span>,
  },
  {
    id: "userCompany",
    header: "Company", // Note: Either company or supplier/agency
    cell: (item: any) => <span style={{ color: item.activeStatus === 'INACTIVE' ? 'gray' : 'inherit' }}>{item.userCompany}</span>,
  },
  {
    id: "roles",
    header: "Role(s)",
    cell: (item: any) => <span style={{ color: item.activeStatus === 'INACTIVE' ? 'gray' : 'inherit' }}>{item.roles}</span>,
  },
  {
    id: "activeStatus",
    header: "Status",
    cell: (item: any) => <span style={{ color: item.activeStatus === 'INACTIVE' ? 'gray' : 'inherit' }}>{item.activeStatus}</span>,
  }
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'view', text: 'View details' },
          { id: 'edit', text: 'Edit' },
          { id: 'deactivate', text: 'Deactivate' },
        ]}
      />
    ),
  }, */
];

export const USER_TABLE_COLUMN_DISPLAY = [
  { id: "name", visible: true },
  { id: "email", visible: true },
  { id: "userCompany", visible: true },
  { id: "roles", visible: true },
  { id: "activeStatus", visible: true }
  // { id: "actions", visible: true }
];

export const PRODUCT_TYPE_TABLE_COLUMN_DEFINITION = [
  {
    id: "name",
    header: "Name",
    cell: (e: any) => e.name,
    sortingField: "name",
    isRowHeader: true
  },
  {
    id: "steps",
    header: "Required Steps",
    cell: (e: any) => e.steps,
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'view', text: 'View details' },
          { id: 'edit', text: 'Edit' },
          { id: 'deactivate', text: 'Deactivate' },
        ]}
      />
    ),
  }, */
];

export const PRODUCT_TYPE_TABLE_COLUMN_DISPLAY = [
  { id: "name", visible: true },
  { id: "steps", visible: true },
  { id: "actions", visible: true }
];

export const PACK_TYPE_TABLE_COLUMN_DEFINITION = [
  {
    id: "name",
    header: "Name",
    cell: (e: any) => e.name,
    sortingField: "name",
    isRowHeader: true
  },
  {
    id: "id",
    header: "ID",
    cell: (e: any) => e.id,
  },
  {
    id: "producttype",
    header: "Product Type",
    cell: (e: any) => e.productTypeName,
  },
  {
    id: "producttypeid",
    header: "Product Type ID",
    cell: (e: any) => e.productTypeId,
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'deactivate', text: 'Deactivate' },
        ]}
      />
    ),
  }, */
];

export const PACK_TYPE_TABLE_COLUMN_DISPLAY = [
  { id: "name", visible: true },
  { id: "producttype", visible: true },
  { id: "actions", visible: true }
];

export const SUB_PACK_TYPE_TABLE_COLUMN_DEFINITION = [
  {
    id: "name",
    header: "Name",
    cell: (e: any) => e.name,
    sortingField: "name",
    isRowHeader: true
  },
  {
    id: "id",
    header: "ID",
    cell: (e: any) => e.id,
  },
  {
    id: "packtype",
    header: "Pack Type",
    cell: (e: any) => e.packTypeName,
  },
  {
    id: "packtypeid",
    header: "Pack Type ID",
    cell: (e: any) => e.packTypeId,
  },
  {
    id: "producttype",
    header: "Product Type",
    cell: (e: any) => e.productTypeName,
  },
  {
    id: "producttypeid",
    header: "Product Type ID",
    cell: (e: any) => e.productTypeId,
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'view', text: 'View Details'},
          { id: 'edit', text: 'Edit'},
          { id: 'deactivate', text: 'Deactivate' },
        ]}
      />
    ),
  }, */
];

export const SUB_PACK_TYPE_TABLE_COLUMN_DISPLAY = [
  { id: "name", visible: true },
  { id: "packtype", visible: true},
  { id: "producttype", visible: true },
  { id: "actions", visible: true }
];

export const PACK_NAME_TABLE_COLUMN_DEFINITION = [
  {
    id: "name",
    header: "Name",
    cell: (e: any) => e.name,
    sortingField: "name",
    isRowHeader: true
  },
  {
    id: "id",
    header: "ID",
    cell: (e: any) => e.id,
  },
  {
    id: "approvalStatus",
    header: "Approval Status",
    cell: (e: any) => e.approvalStatus,
  },
  {
    id: "packtype",
    header: "Pack Type",
    cell: (e: any) => e.packTypeName,
  },
  {
    id: "packtypeid",
    header: "Pack Type ID",
    cell: (e: any) => e.packTypeId,
  },
  {
    id: "subpacktype",
    header: "Sub Pack Type",
    cell: (e: any) => e.subPackTypeName,
  },
  {
    id: "subpacktypeid",
    header: "Sub Pack Type ID",
    cell: (e: any) => e.subPackTypeId,
  },
  {
    id: "producttype",
    header: "Product Type",
    cell: (e: any) => e.productTypeName,
  },
  {
    id: "producttypeid",
    header: "Product Type ID",
    cell: (e: any) => e.productTypeId,
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'view', text: 'View Details'},
          { id: 'edit', text: 'Edit'},
          { id: 'deactivate', text: 'Deactivate' },
        ]}
      />
    ),
  }, */
];

export const PACK_NAME_TABLE_COLUMN_DISPLAY = [
  { id: "name", visible: true },
  { id: "packtype", visible: true},
  { id: "subpacktype", visible: true},
  { id: "producttype", visible: true },
  { id: "actions", visible: true }
];

export const OPEN_TASKS_PE_COLUMN_DEFINITION = [
  {
    id: "jobType",
    header: "Job type",
    cell: (e: any) => e.jobType,
    sortingField: "jobType",
    isRowHeader: true
  },
  {
    id: "status",
    header: "Status",
    cell: (e: any) => e.status,
  },
  {
    id: "packName",
    header: "Pack name(s)",
    cell: (e: any) => e.packName,
  },
  {
    id: "assignedTo",
    header: "Assigned to",
    cell: (e: any) => e.assignedTo,
  },
  {
    id: "created",
    header: "Created",
    cell: (e: any) => e.created,
  },
  {
    id: "supplier",
    header: "Supplier",
    cell: (e: any) => e.supplier,
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'edit', text: 'Edit' },
          { id: 'reassign', text: 'Reassign' },
        ]}
      />
    ),
  }, */
];

export const OPEN_TASKS_SUPPLIER_COLUMN_DEFINITION = [
  {
    id: "ID",
    header: "ID",
    cell: (e: any) => e.id,
    isRowHeader: true
  },
  {
    id: "jobType",
    header: "Job type",
    cell: (e: any) => e.jobType,
    sortingField: "jobType",
    isRowHeader: true
  },
  {
    id: "dataType",
    header: "Data type",
    cell: (e: any) => e.dataType,
    isRowHeader: true
  },
  {
    id: "jobId",
    header: "Job ID",
    cell: (e: any) => e.jobId,
    isRowHeader: true
  },
  {
    id: "barcode",
    header: "Barcode",
    cell: (e: any) => e.barcode,
    isRowHeader: true
  },
  {
    id: "requiredAction",
    header: "Required action",
    cell: (e: any) => e.requiredAction,
    isRowHeader: true
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'edit', text: 'Edit' },
          { id: 'reassign', text: 'Reassign' },
        ]}
      />
    ),
  }, */
];

export const OPEN_TASKS_SUPPLIER_COLUMN_DISPLAY = [
  { id: "jobType", visible: true },
  { id: "dataType", visible: true },
  { id: "barcode", visible: true },
  { id: "requiredAction", visible: true },
  { id: "actions", visible: true }
]

export const OPEN_TASKS_ADMIN_COLUMN_DEFINITION = [
  {
    id: "ID",
    header: "ID",
    cell: (e: any) => e.id,
    isRowHeader: true
  },
  {
    id: "jobType",
    header: "Job type",
    cell: (e: any) => e.jobType,
    sortingField: "jobType",
    isRowHeader: true
  },
  {
    id: "dataType",
    header: "Data type",
    cell: (e: any) => e.dataType,
    isRowHeader: true
  },
  {
    id: "jobId",
    header: "Job ID",
    cell: (e: any) => e.jobId,
    isRowHeader: true
  },
  {
    id: "barcode",
    header: "Barcode",
    cell: (e: any) => e.barcode,
    isRowHeader: true
  },
  {
    id: "requiredAction",
    header: "Required action",
    cell: (e: any) => e.requiredAction,
    isRowHeader: true
  },
  /* {
    id: 'actions',
    header: 'Actions',
    cell: (item: any) => (
      <ButtonDropdown
        variant="inline-icon"
        expandToViewport={true}
        items={[
          { id: 'edit', text: 'Edit' },
          { id: 'reassign', text: 'Reassign' },
        ]}
      />
    ),
  }, */
];

export const OPEN_TASKS_ADMIN_COLUMN_DISPLAY = [
  { id: "jobType", visible: true },
  { id: "dataType", visible: true },
  { id: "barcode", visible: true },
  { id: "requiredAction", visible: true },
  { id: "actions", visible: true }
]

export const ALL_JOBS_COLUMN_DEFINITION = [
  {
    id: "id",
    header: "Job ID",
    cell: (e: any) => e.id,
    isRowHeader: true,
    sortingField: "id",
  },
  {
    id: "jobState",
    header: "Status",
    cell: (e: any) => e.jobState,
  },
  {
    id: "packNames",
    header: "Pack name(s)",
    cell: (e: any) => e.packNames,
  },
  {
    id: "assignedToUserName",
    header: "Assigned to",
    cell: (e: any) => e.assignedToUserName,
  },
  {
    id: "dateStart",
    header: "Created",
    cell: (e: any) => {
      const date = new Date(e.dateStart*1000);
      const month = date.getMonth() + 1;
      return date.getDate() + "/" + month + "/" + date.getFullYear();
    },
  },
  {
    id: "supplierName",
    header: "Supplier",
    cell: (e: any) => e.supplierName,
  },
];

export const ALL_JOBS_TABLE_COLUMN_DISPLAY = [
  { id: "id", visible: true },
  { id: "jobState", visible: true},
  { id: "packNames", visible: true},
  { id: "assignedToUserName", visible: true },
  { id: "dateStart", visible: true },
  { id: "supplierName", visible: true}
];

export const ALL_PACKAGES_COLUMN_DEFINITION = [
  {
    id: "id",
    header: "ID",
    cell: (e: any) => e.id,
    isRowHeader: true,
    sortingField: "id",
  },
  {
    id: "packName",
    header: "Pack name",
    cell: (e: any) => e.masterPackName,
  },
  {
    id: "packType",
    header: "Pack type",
    cell: (e: any) => e.packType,
  },
  {
    id: "subPackType",
    header: "Sub pack type",
    cell: (e: any) => e.subPackType,
  },
  {
    id: "barcode",
    header: "Barcode",
    cell: (e: any) => e.barcode,
  },
  {
    id: "supplierName",
    header: "Supplier",
    cell: (e: any) => e.supplierName,
  },
  {
    id: "siteCount",
    header: "Sites",
    cell: (e: any) => e.siteCount,
  }
];

export const ALL_PACKAGES_TABLE_COLUMN_DISPLAY = [
  { id: "packName", visible: true},
  { id: "packType", visible: true},
  { id: "subPackType", visible: true},
  { id: "barcode", visible: true},
  { id: "supplierName", visible: true},
  { id: "siteCount", visible: true},
];

export function createTableSortLabelFn(
  column: TableProps.ColumnDefinition<unknown>
): TableProps.ColumnDefinition<unknown>['ariaLabel'] {
  if (!column.sortingField && !column.sortingComparator && !column.ariaLabel) {
    return;
  }
  return ({ sorted, descending }) => {
    return `${column.header}, ${sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted'}.`;
  };
}