
export const GET_SUPPLIER_LIST = '/suppliers';
export const CREATE_SUPPLIER = '/suppliers';
export const UPDATE_SUPPLIER = '/suppliers/{id}';
export const DELETE_SUPPLIER = '/suppliers/{id}';
export const DEACTIVATE_SUPPLIER = '/suppliers/{id}/status';
export const ACTIVATE_SUPPLIER = '/suppliers/{id}/status';
export const GET_SUPPLIER = '/suppliers/{id}'

export const GET_PRODUCT_TYPE = '/productTypes';
export const CREATE_PRODUCT_TYPE = '/productTypes';
export const EDIT_PRODUCT_TYPE = '/productTypes/{id}';

export const GET_PACK_TYPE = '/packTypes';
export const CREATE_PACK_TYPE = '/packTypes';

export const CREATE_SUB_PACK_TYPE = '/subPackTypes';
export const GET_SUB_PACK_TYPES = '/subPackTypes';
export const GET_SUB_PACK_TYPE = '/subPackTypes/{id}';
export const PUT_SUB_PACK_TYPE = '/subPackTypes/{id}';

export const GET_PACK_NAMES = '/masterPackNames';
export const GET_PACK_NAME = '/masterPackNames/{id}';
export const CREATE_PACK_NAME = '/masterPackNames';
export const PUT_PACK_NAME_SPECS = '/masterPackNames/{id}/specFields';
export const UPLOAD_TECH_DRAWING_PACK_NAME = '/masterPackNames/{id}/type/TECH_DRAWING';
export const UPLOAD_ARTWORK_PACK_NAME =  '/masterPackNames/{id}/type/ARTWORK';
export const CREATE_APPROVAL_REQUEST = '/masterPackNames/{id}/request';
export const MPN_CREATE_DOCUMENT_REQUEST = '/masterPackNames/{id}/type/{type}/request';
export const PACK_NAME_APPROVAL = '/masterPackNames/{id}/request/{approvalStatus}';

export const GET_USER_LIST = '/users';
export const CREATE_USER = '/users/{userCompany}';
export const UPDATE_USER = '/users/{userCompany}/{id}';
export const DELETE_USER = '/users/{id}';
export const DEACTIVATE_USER = '/users/{userCompany}/{id}/status';
export const ACTIVATE_USER = '/users/{userCompany}/{id}/status';

export const CREATE_PACKAGING_JOB = '/packageJobs';
export const GET_PACKAGING_JOBS = '/packageJobs';
export const GET_PACKAGING_JOB_DETAIL = '/packageJobs/{id}';
export const REASSIGN_JOB = '/packageJobs/{id}/reassign';

export const POST_JOB_BASIC = '/packageJobs/{id}/basic/general';
export const CREATE_TECH_DATA_REQUEST = '/packageJobs/{id}/techData/request';
export const CREATE_ARTWORK_REQUEST = '/packageJobs/{id}/artwork/request';
export const TECH_DATA_REMINDER = '/packageJobs/{id}/techData/barcode/{barcode}/request/reminder';
export const ARTWORK_REMINDER = '/packageJobs/{id}/artwork/barcode/{barcode}/request/reminder';

export const POST_PACKAGING_SPECS = '/packageJobs/{id}/barcode/{barcode}/specs/manufacturingSite/{manufacturingSiteId}';
export const POST_PACKAGING_SPECS_FILE = '/packageJobs/{id}/barcode/{barcode}/specs/manufacturingSite/{manufacturingSiteId}/additionalFile';
export const POST_PACKAGING_ARTWORK_FILE = '/packageJobs/{id}/barcode/{barcode}/artwork/manufacturingSite/{manufacturingSiteId}';
export const POST_PACKAGING_TECH_DRAWING_FILE = '/packageJobs/{id}/barcode/{barcode}/techDrawing';
// export const SUBMIT_TECH_DATA = '/packageJobs/{id}/techData/submit';
export const SUBMIT_SPECS = '/packageJobs/{id}/specs/submit';
export const SUBMIT_ARTWORK = '/packageJobs/{id}/artwork/submit';

export const REVIEW_SPECS = '/packageJobs/{id}/barcode/{barcode}/specs/manufacturingSite/{manufacturingSiteId}/{approvalStatus}';
export const REVIEW_TECH_DRAWING = '/packageJobs/{id}/barcode/{barcode}/techDrawing/{approvalStatus}';
export const REVIEW_ARTWORK = '/packageJobs/{id}/barcode/{barcode}/artwork/manufacturingSite/{manufacturingSiteId}/{approvalStatus}';

export const POST_CO2_VALUE = '/packageJobs/{id}/CO2';
export const GET_EPACK_FILE = '/packageJobs/{id}/generate/epackfile';

export const PUT_JOB_STATUS = '/packageJobs/{id}/status/{jobStatus}';

export const POST_BARCODE = '/barcode';

export const GET_OPEN_TASKS = '/openTasks';

export const GET_PACKAGES = '/packages';
export const GET_PACKAGE_DETAIL = '/packages/{id}';
export const GET_PACKAGE_SPECS = '/packages/{id}/manufacturingSite/{manufacturingSiteId}/specs/{versionNumber}';
export const GET_PACKAGE_TECHNICAL_DRAWING = '/packages/{id}/manufacturingSite/{manufacturingSiteId}/techDrawing/{versionNumber}';
export const GET_PACKAGE_ARTWORK = '/packages/{id}/manufacturingSite/{manufacturingSiteId}/artwork/{versionNumber}';

export const GET_DOCUMENT_FILE = '/documentFiles/{id}';

export const GET_MASTER_FIELDS = '/masterFields';

export const GET_SUPPLIER_BARCODES = '/barcodes/supplier/{supplierId}';

export const POST_REVISION_JOB_BASIC = '/packageJob/revision';
export const POST_REVISION_DOCUMENT_REQUEST = '/packageJobs/{id}/document/request';