import { Box, BreadcrumbGroup, Container, ContentLayout, Header, Link, SpaceBetween } from "@amzn/awsui-components-react";
import React from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Navigation } from "../common/navigation";
import { productTypeDetailsBreadcrumbs } from "../common/breadcrumbs";
import { useLocation, useParams } from "react-router-dom";


const Breadcrumbs = () => (
    <BreadcrumbGroup items={productTypeDetailsBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

interface ProductTypeDetailProps {
  id: string;
  name: string;
  jobSteps: any[];
}

const Content = (props: any) => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    
    const elementDetails = location.state as ProductTypeDetailProps | undefined;

    return (
            <SpaceBetween size="l">
            <Container
              header={<Header 
                        variant="h2" 
                        description="General information about the product type">
                        Product Type
                    </Header>}
            >
                <ValueWithLabel label="Name">{elementDetails?.name}</ValueWithLabel>
            </Container>
            <Container
              header={<Header 
                variant="h2" 
                description="The steps which must be taken when creating a new packaging with this product type">
                Required steps
                </Header>}
            >
              <SpaceBetween size="l">
                <ValueWithLabel label="Steps">
                {elementDetails?.jobSteps.map((name) => (
                    <div>{name}</div>
                ))}
                </ValueWithLabel>
              </SpaceBetween>
            </Container>
            </SpaceBetween>
      );
};

export class ProductTypeDetail extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
              <GlobalNav  />
            </div>
            <CustomAppLayout
            content={
              <ContentLayout
                header={
                  <Header
                    variant="h1"
                    info={
                      <Link id="main-info-link" variant="info">
                        Info
                      </Link>
                    }
                    description="View the detail settings for this product type"
                  >
                    Product Type Details
                  </Header>
                }
              >
                <Content />
              </ContentLayout>
            }
            breadcrumbs={<Breadcrumbs />}
            headerSelector="#h"
            navigation={<Navigation activeHref="/producttypes" />}
            navigationOpen={false}
          />
          </body>
        );
    }
}