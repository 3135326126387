import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Navigation } from "../common/navigation";
import { BreadcrumbGroup, Button, Checkbox, ColumnLayout, Container, DatePicker, FormField, Header, Multiselect, Select, SpaceBetween, Wizard } from "@amzn/awsui-components-react";
import { revisionBreadcrumbs } from "../common/breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { GET_SUPPLIER_BARCODES, GET_SUPPLIER_LIST, GET_USER_LIST, POST_REVISION_JOB_BASIC, PUT_JOB_STATUS } from "src/config/urlConfig";
import { ValueWithLabel } from "../common/types";
import { UserResponse } from "../admin/Users";
import { RevisionItems } from "./revisionsteps/Basic";
import { Confirmation } from "./revisionsteps/Confirmation";
import { RevisionInfo } from "./revisionsteps/Info";

export interface RevisionJobProps {
    job: any;
    onJobDetailsChange: (updatedDetails: Partial<any>) => void;
}

export interface RevisionJob {
    id: string;
    supplierId: string;
    supplierName: string;
    barcodes: RevisionPackage[];
}

export interface RevisionPackage {
    barcode: string;
    masterPackName: string;
    masterPackNameId: string;
}

export interface RevisionJobWithDocument {
    id: string;
    supplierId?: string;
    revisionBarcodes: RevisionDocumentBarcodeModel[];
}

export interface RevisionJobWithDocProps {
    job: any;
    jobWithDoc: any;
    onJobDetailsChange: (updatedDetails: Partial<any>) => void;
    onJobWithDocDetailsChange: (updatedDetails: Partial<any>) => void;
}

export interface RevisionDocumentBarcodeModel {
    barcode: string;
    techDrawing: DocumentRequest;
    artwork: DocumentRequest;
}

export interface DocumentRequest {
    externalCompanyType: string;
    externalCompanyId: string;
    dateDue: number;
    pocUserIds: string[];
    reviewerUserIds: string[];
}

export const RevisionWizard = (props: any) => {
    const {step} = useParams();

    const [
        activeStepIndex,
        setActiveStepIndex
      ] = React.useState<number>(parseInt(step? step : "0"));

    const [revisionJob, setRevisionJob] = useState<RevisionJob>({
        id: "",
        supplierId: "",
        supplierName: "",
        barcodes: []
    });

    const [revisionJobWithDoc, setRevisionJobWithDoc] = useState<RevisionJobWithDocument>({
        id: "",
        supplierId: "",
        revisionBarcodes: []
    });

    const client = createClient();

    const getNextButtonLabel = () => {
        return "Next";
    }

    const getActiveStepIndex = () => {
        return activeStepIndex;
    }

    const handleJobDetailsChange = (updatedDetails: Partial<RevisionJob>) => {
      setRevisionJob({
          id: updatedDetails.id || revisionJob.id,
          supplierId: updatedDetails.supplierId || revisionJob.supplierId,
          supplierName: updatedDetails.supplierName || revisionJob.supplierName,
          barcodes: updatedDetails.barcodes || revisionJob.barcodes,
      });
    }

    const handleJobWithDocDetailsChange = (updatedDetails: Partial<RevisionJobWithDocument>) => {
      setRevisionJobWithDoc({
          id: updatedDetails.id || revisionJobWithDoc.id,
          supplierId: updatedDetails.supplierId || revisionJobWithDoc.supplierId,
          revisionBarcodes: updatedDetails.revisionBarcodes || revisionJobWithDoc.revisionBarcodes,
      });
    }

    const saveRevisionJobBasic = async () => {
      const validBarcodeList = revisionJob.barcodes.filter((item) => item !== undefined);
      revisionJob.barcodes = validBarcodeList;
      
      const reqBody = {
          "supplierId": revisionJob.supplierId,
          "supplierName": revisionJob.supplierName,
          "barcodes": revisionJob.barcodes,
      }
      console.log(reqBody);
      const response = await client.post(POST_REVISION_JOB_BASIC, reqBody);
      if (response.data) {
          console.log(response.data);
          setRevisionJob({
            id: response.data.packageJob.id,
            supplierId: response.data.packageJob.supplierId,
            supplierName: response.data.packageJob.supplierName,
            barcodes: response.data.packageJob.barcodes,
          })
      }
      else {
          console.log(response);
      }
      return;
    }

    const navigate = useNavigate();

    const closeJob = async() => {
      const response = await client.put(PUT_JOB_STATUS.replace("{id}", revisionJob.id).replace("{jobStatus}", "CLOSED"));
      if (response.data) {
          console.log(response.data);
      }
      else {
          console.log(response);
      }
    }

      return (
        <div>
        <Wizard
          i18nStrings={{
            stepNumberLabel: stepNumber =>
              `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            navigationAriaLabel: "Steps",
            cancelButton: "Cancel",
            previousButton: "Previous",
            nextButton: getNextButtonLabel(),
            submitButton: "Close job",
            optional: "optional"
          }}
          onNavigate={async ({ detail }) =>
            {
              if (activeStepIndex == 0 && detail.requestedStepIndex == 1) {
                await saveRevisionJobBasic();
                setActiveStepIndex(detail.requestedStepIndex);
                return;
              }
              setActiveStepIndex(detail.requestedStepIndex);
            }
          }
          onSubmit={() => {
            closeJob();
            navigate('/home');
          }}
          activeStepIndex={getActiveStepIndex()}
          steps={[
            {
              title: "Revision Item(s)",
              content: <RevisionItems job={revisionJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
            {
              title: "Revision Information",
              content: <RevisionInfo job={revisionJob} jobWithDoc={revisionJobWithDoc} onJobDetailsChange={handleJobDetailsChange} onJobWithDocDetailsChange={handleJobWithDocDetailsChange}/>,
            },
            {
              title: "Confirmation",
              content: <Confirmation job={revisionJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
          ]}
        />
        </div>
      );
}



const Breadcrumbs = () => (
    <BreadcrumbGroup items={revisionBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export class Revision extends React.Component{

    render () {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                    contentType="table"
                    content={
                      <RevisionWizard />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="#" />}
                />
            </body>
        )
    }
}