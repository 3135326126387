import React, {useEffect, useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {
    BreadcrumbGroup,
    Button,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    Link,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {addPackTypesBreadcrumbs} from "../common/breadcrumbs";
import {CREATE_PACK_TYPE, GET_PRODUCT_TYPE} from "src/config/urlConfig";
import {ProductTypeResponse} from "./ProductType";
import {createClient} from "src/client/AxiosClient";
import { useNavigate } from "react-router-dom";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={addPackTypesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

interface ProductTypeOption {
    label: string;
    value: string;
}

const AddPackTypeContent = (session: any) => {
    const [packTypeName, setPackTypeName] = useState("");

    const [tableItems, setTableItems] = useState<ProductTypeOption[]>([]);
    const [selectedOption, setSelectedOption] = useState(tableItems[0]);

    const client = createClient()

    const savePackType = async () => {
        const reqBody = {
            "name": packTypeName,
            "productTypeId": selectedOption.value,
        }
        const response = await client.post(CREATE_PACK_TYPE, reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        window.location.href = '/packtypes';
        return;
    }

    const fetchProductTypes = async () => {
        const response = await client.get(GET_PRODUCT_TYPE);
        if (response.data) {
            const response = await client.get(GET_PRODUCT_TYPE);
            const data: ProductTypeResponse[] = response.data.productTypes;
            const parsedData = data.map(item => ({
                label: item.name,
                value: item.id,
            }));

            setTableItems(parsedData);
      
          return response.data.suppliers;
        }
        else {
          console.log(response);
        }
        return [];
      }
  
    useEffect(() => {
    const fetchProductTypeList = async () => {
        await fetchProductTypes();
    };
    fetchProductTypeList()
    }, []);

    const navigate = useNavigate();
 
    return (
        <form onSubmit={event => event.preventDefault()}>
            <Form
                actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => navigate('/packtypes')}>Cancel</Button>
                    <Button variant="primary" onClick={() => savePackType()}>Save pack type</Button>
                </SpaceBetween>
                }
            >
                
                <Container
                header={<Header 
                            variant="h2" >
                            Pack type
                        </Header>}
                >
                    <SpaceBetween size="l">
                    <FormField 
                    label="Name" 
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }>
                        <Input onChange={({ detail }) => setPackTypeName(detail.value)} value={packTypeName}/>
                    </FormField>
                    <FormField 
                    label="Product type" 
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }>
                        <Select 
                            selectedOption={selectedOption}
                            onChange={({ detail }) => {
                                const option = {
                                    label: detail.selectedOption.label || "",
                                    value: detail.selectedOption.value || "",
                                };
                                setSelectedOption(option);
                                }
                            }
                            options={tableItems}
                        />
                    </FormField>
                    </SpaceBetween>
                </Container>
                
          </Form>
        </form>
    );
}

export class AddPackType extends React.Component {
    render() {
        const mainAppProps = this.props;
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                content={
                <ContentLayout
                    header={
                    <Header
                        variant="h1"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Add a pack type"
                    >
                        Add pack type
                    </Header>
                    }
                >
                    <AddPackTypeContent session={mainAppProps}/>
                </ContentLayout>
                }
                breadcrumbs={<Breadcrumbs />}
            />
            </body>
        );
    }
}