import { useEffect } from "react";

function useBroadcastChannel(channelName: string, onMessage: (message: MessageEvent) => void): void {
    useEffect(() => {
        const channel = new BroadcastChannel(channelName);

        channel.onmessage = onMessage;

        return () => {
            channel.close();
        };
    }, [channelName, onMessage]);
}

export default useBroadcastChannel;