import { Box, Button, Container, DatePicker, FileUpload, FormField, Header, Input, 
    Modal, Multiselect, RadioGroup, Select, SpaceBetween, Toggle
} from "@amzn/awsui-components-react"
import {useEffect, useState} from "react";
import {pdfjs} from "react-pdf";
import {GET_SUPPLIER, GET_SUPPLIER_LIST, MPN_CREATE_DOCUMENT_REQUEST, UPLOAD_ARTWORK_PACK_NAME, UPLOAD_TECH_DRAWING_PACK_NAME} from "src/config/urlConfig";
import {SupplierResponse} from "src/pages/pe/steps/BasicInput";
import ArtworkFile from 'src/assets/artwork.pdf';
import {createClient} from "src/client/AxiosClient";
import { PackNamesResponse } from "../PackNames";
import { SupplierDetail } from "src/pages/common/types";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ValueWithLabel = ({ label, children } : {label: string, children: any})  => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

interface PackNameFilesProps {
    packNameDetail: PackNamesResponse;
}

export const MasterFiles: React.FC<PackNameFilesProps> = ({packNameDetail}) => {
    const [techDrawingFileValue, setTechDrawingFileValue] = useState<File[]>([]);
    const [artworkFileValue, setArtworkFileValue] = useState<File[]>([]);

    const [requestTechDrawingChecked, setRequestTechDrawingChecked] = useState(false);
    const [techDrawingUploaded, setTechDrawingUploaded] = useState(false);

    const [tdDueDate, setTDDueDate] = useState("");
    const [techDrawingCompanyType, setTechDrawingCompanyType] = useState("supplier");
    const [tdPocs, setTDPocs] = useState<any>([]);

    const [techDrawingRequested, setTechDrawingRequested] = useState(false);
    const [techDrawingReminderSent, setTechDrawingReminderSent] = useState(false);

    const [techDrawingRejected, setTechDrawingRejected] = useState(false);
    const [techDrawingApproved, setTechDrawingApproved] = useState(false);

    const [requestArtworkChecked, setRequestArtworkChecked] = useState(false);
    const [artworkUploaded, setArtworkUploaded] = useState(false);

    const [artworkDueDate, setArtworkDueDate] = useState("");
    const [artworkCompanyType, setArtworkCompanyType] = useState("supplier");
    const [artworkPocs, setArtworkPocs] = useState<any>([]);

    const [artworkRequested, setArtworkRequested] = useState(false);
    const [artworkReminderSent, setArtworkReminderSent] = useState(false);

    const [artworkRejected, setArtworkRejected] = useState(false);
    const [artworkApproved, setArtworkApproved] = useState(false);

    const client = createClient();

    const [supplierItems, setSupplierItems] = useState<any[]>([]);
    const [
        supplierSelectedOption,
        setSupplierSelectedOption
      ] = useState(supplierItems[0]);

    const [agency, setAgency] = useState("");

    const [supplier, setSupplier] = useState<SupplierDetail>();

    const [supplierUsers, setSupplierUsers] = useState<any[]>([]);

    const fetchSupplierDetail = async (supplierId: string) => {
        const response = await client.get(GET_SUPPLIER.replace("{id}", supplierId));
        if (response.data) {
            console.log(response.data);
            setSupplier(response.data.supplier);
            const parsedDataSupplier = response.data.supplier.users.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setSupplierUsers(parsedDataSupplier);
        }
        else {
            console.log(response);
        }
    }
    
    const fetchSuppliers = async () => {
        const response = await client.get(GET_SUPPLIER_LIST);
        if (response.data) {
          const data: SupplierResponse[] = response.data.suppliers;
          const parsedData = data.map(item => ({
              label: item.name,
              value: item.id,
          }));
  
          setSupplierItems(parsedData);
          setSupplierSelectedOption(parsedData[0])
      
          return response.data.suppliers;
        }
        else {
          console.log(response);
        }
        return [];
      }
  
      useEffect(() => {
        const fetchSupplierList = async () => {
            await fetchSuppliers();
        };
        fetchSupplierList()
      }, []);

    const getContainerButtonText = () => {
        return "Request file";
    }

    const downloadArtwork = () => {
        const a = document.createElement('a');
        a.href = ArtworkFile;
        a.download = 'artwork.pdf'; // Set the desired file name
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const [approveArtworkModalVisible, setApproveArtworkModalVisible] = useState(false);

    const openApproveArtworkModal = () => {
        setApproveArtworkModalVisible(true);
    };

    const [rejectArtworkModalVisible, setRejectArtworkModalVisible] = useState(false);

    const openRejectArtworkModal = () => {
        setRejectArtworkModalVisible(true);
    };

    const [rejectArtworkButtonDisabled, setRejectArtworkButtonDisabled] = useState(false);
    const [approveArtworkButtonDisabled, setApproveArtworkButtonDisabled] = useState(false);

    const [rejectArtworkComment, setRejectArtworkComment] = useState("");
    const [artworkSuggestionFileValue, setArtworkSuggestionFileValue] = useState<File[]>([]);

    const uploadTechnicalDrawing = async() => {
        if (techDrawingFileValue && techDrawingFileValue[0]) {
            try {
                const formData = new FormData();
                formData.append('tdTest', techDrawingFileValue[0]);
        
                const response = await client.post(UPLOAD_TECH_DRAWING_PACK_NAME.replace("{id}", packNameDetail.id)+"/?fileName=" + techDrawingFileValue[0].name, formData);
        
                if (response.data) {
                    console.log(response.data);
                    setTechDrawingUploaded(true);
                } else {
                    console.error('Upload failed');
                }
            } catch (error) {
              console.error('Error uploading technical drawing:', error);
            }
        }
    }

    const uploadArtwork = async() => {
        if (artworkFileValue && artworkFileValue[0]) {
            try {
                const formData = new FormData();
                formData.append('artworkTest', artworkFileValue[0]);
        
                const response = await client.post(UPLOAD_ARTWORK_PACK_NAME.replace("{id}", packNameDetail.id)+"/?fileName=artwork.pdf", formData);
        
                if (response.data) {
                    console.log(response.data);
                    setArtworkUploaded(true);
                } else {
                    console.error('Upload failed');
                }
            } catch (error) {
                console.error('Error uploading artwork:', error);
            }
        }
    }

    const getUserIds = (users: any[]) => {
        let ids: string[] = [];
        for (const user of users) {
            ids.push(user.value);
        }
        return ids;
    }

    const sendArtworkRequest = async() => {
        const reqBody = {
                    "dueDate": Date.parse(artworkDueDate),
                    "externalCompanyType": "SUPPLIER",
                    "externalCompanyId": supplier?.id,
                    "invitedUserIds": getUserIds(artworkPocs),
                }
    
        const response = await client.post(MPN_CREATE_DOCUMENT_REQUEST.replace("{id}", packNameDetail.id).replace("{type}", "ARTWORK"), reqBody);
        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }
        return;
    }

    const sendTechDrawingRequest = async() => {
        const reqBody = {
                    "dueDate": Date.parse(tdDueDate),
                    "externalCompanyType": "SUPPLIER",
                    "externalCompanyId": supplier?.id,
                    "invitedUserIds": getUserIds(tdPocs),
                }
    
        const response = await client.post(MPN_CREATE_DOCUMENT_REQUEST.replace("{id}", packNameDetail.id).replace("{type}", "TECH_DRAWING"), reqBody);
        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }
        return;
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
                <Container
                                header={
                                    <Header
                                        variant="h2"
                                        description="Upload a file if you have one or request it"
                                        actions={
                                            <SpaceBetween direction="horizontal" size="s">
                                                {
                                                    !requestTechDrawingChecked &&
                                                    <Button disabled={techDrawingUploaded} onClick={uploadTechnicalDrawing}>Upload</Button>
                                                }
                                                {
                                                    requestTechDrawingChecked &&
                                                    <Button 
                                                    disabled={techDrawingRequested}
                                                    onClick={() => {
                                                        sendTechDrawingRequest();
                                                        setTechDrawingRequested(true);
                                                        }}>
                                                        {getContainerButtonText()}
                                                    </Button>
                                                }
                                            </SpaceBetween>
                                        }
                                    >
                                        Master technical drawing
                                    </Header>
                                }
                            >
                                <SpaceBetween size="m" direction="vertical">
                                    {!requestTechDrawingChecked &&
                                    <FileUpload
                                        onChange={({ detail }) => setTechDrawingFileValue(detail.value)}
                                        value={techDrawingFileValue}
                                        i18nStrings={{
                                        uploadButtonText: e =>
                                            e ? "Choose files" : "Choose file",
                                        dropzoneText: e =>
                                            e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                        removeFileAriaLabel: e =>
                                            `Remove file ${e + 1}`,
                                        limitShowFewer: "Show fewer files",
                                        limitShowMore: "Show more files",
                                        errorIconAriaLabel: "Error"
                                        }}
                                        showFileLastModified
                                        showFileSize
                                        showFileThumbnail
                                        tokenLimit={3}
                                    />}
                                    <Toggle disabled={techDrawingRequested} checked={requestTechDrawingChecked} onChange={({detail}) => {
                                        setRequestTechDrawingChecked(detail.checked)
                                    }}
                                    >
                                        Request technical drawing</Toggle>
                                    {requestTechDrawingChecked && 
                                    <div>
                                        {!techDrawingRequested && <FormField label="Due date">
                                            <DatePicker
                                                onChange={({ detail }) => setTDDueDate(detail.value)}
                                                value={tdDueDate}
                                                placeholder="YYYY/MM/DD"
                                            />
                                        </FormField>}
                                        {
                                            techDrawingRequested && <ValueWithLabel label="Due date">{tdDueDate}</ValueWithLabel>
                                        }
            
                                        {requestTechDrawingChecked && !techDrawingRequested && <FormField label="Company">
                                            <RadioGroup
                                                onChange={({ detail }) => setTechDrawingCompanyType(detail.value)}
                                                value={techDrawingCompanyType}
                                                items={[
                                                    { value: "supplier", label: "Supplier" },
                                                    { value: "agency", label: "Agency" },
                                                ]}
                                                />
                                        </FormField>
                                        }
                                    </div>
                                    }
                                    {
                                    requestTechDrawingChecked == true &&
                                        techDrawingRequested == false &&
                                        techDrawingCompanyType == "supplier" && 
                                        <SpaceBetween size="m" direction="vertical">
                                            <FormField label="Supplier">
                                                <Select
                                                    selectedOption={supplierSelectedOption}
                                                    onChange={({ detail }) =>
                                                        {
                                                            setSupplierSelectedOption(detail.selectedOption);
                                                            fetchSupplierDetail(detail.selectedOption.value || "");
                                                        }
                                                    }
                                                    options={supplierItems}
                                                />
                                            </FormField>
                                            <FormField label="Point of Contact">
                                            <Multiselect
                                            selectedOptions={tdPocs}
                                                onChange={({ detail }) =>
                                                    setTDPocs(detail.selectedOptions)
                                                }
                                                options={supplierUsers}
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    }
                                    {
                                        techDrawingRequested && 
                                        <ValueWithLabel label="Supplier">{supplierSelectedOption.value}</ValueWithLabel>
                                    }
                                    {
                                        techDrawingRequested &&
                                        <ValueWithLabel label="Reviewer">{tdPocs.map((item: { label: any; value: any; }) => item.label).join(', ')}</ValueWithLabel>
                                    }
                                    {
                                    requestTechDrawingChecked == true &&
                                        techDrawingRequested == false &&
                                        techDrawingCompanyType == "agency" && 
                                        <SpaceBetween size="m" direction="vertical">
                                            <FormField label="Agency">
                                                <Input onChange={({detail}) => setAgency(detail.value)} value={agency}/>
                                            </FormField>
                                        </SpaceBetween>
                                    }
                                </SpaceBetween>
                </Container>

                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Upload a file if you have one or request it"
                            actions={
                                <SpaceBetween direction="horizontal" size="s">
                                    {
                                        !requestArtworkChecked &&
                                        <Button disabled={artworkUploaded} onClick={uploadArtwork}>Upload</Button>
                                    }
                                    {
                                        requestArtworkChecked &&
                                        <Button 
                                        disabled={artworkRequested}
                                        onClick={() => {
                                            sendArtworkRequest();
                                            setArtworkRequested(true);
                                            }}>
                                            {getContainerButtonText()}
                                        </Button>
                                    }
                                </SpaceBetween>
                            }
                        >
                            Master artwork
                        </Header>
                    }
                >
                    <SpaceBetween size="m" direction="vertical">
                        {!requestArtworkChecked &&
                        <FileUpload
                            onChange={({ detail }) => setArtworkFileValue(detail.value)}
                            value={artworkFileValue}
                            i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                        />}
                        <Toggle disabled={artworkRequested} checked={requestArtworkChecked} onChange={({detail}) => {
                            setRequestArtworkChecked(detail.checked)
                        }}
                        >
                            Request artwork</Toggle>
                        {requestArtworkChecked && 
                        <div>
                            {!artworkRequested && <FormField label="Due date">
                                <DatePicker
                                    onChange={({ detail }) => setArtworkDueDate(detail.value)}
                                    value={artworkDueDate}
                                    placeholder="YYYY/MM/DD"
                                />
                            </FormField>}
                            {
                                artworkRequested && <ValueWithLabel label="Due date">{artworkDueDate}</ValueWithLabel>
                            }

                            {requestArtworkChecked && !artworkRequested && <FormField label="Company">
                                <RadioGroup
                                    onChange={({ detail }) => setArtworkCompanyType(detail.value)}
                                    value={artworkCompanyType}
                                    items={[
                                        { value: "supplier", label: "Supplier" },
                                        { value: "agency", label: "Agency" },
                                    ]}
                                    />
                            </FormField>
                            }
                        </div>
                        }
                        {
                        requestArtworkChecked == true &&
                            artworkRequested == false &&
                            artworkCompanyType == "supplier" && 
                            <SpaceBetween size="m" direction="vertical">
                                <FormField label="Supplier">
                                    <Select
                                        selectedOption={supplierSelectedOption}
                                        onChange={({ detail }) =>
                                            {
                                                setSupplierSelectedOption(detail.selectedOption);
                                                fetchSupplierDetail(detail.selectedOption.value || "");
                                            }
                                        }
                                        options={supplierItems}
                                    />
                                </FormField>
                                <FormField label="Point of Contact">
                                    <Multiselect
                                        selectedOptions={artworkPocs}
                                        onChange={({ detail }) =>
                                            setArtworkPocs(detail.selectedOptions)
                                        }
                                        options={supplierUsers}
                                        />

                                </FormField>
                            </SpaceBetween>
                        }
                        {
                            artworkRequested && 
                            <ValueWithLabel label="Supplier">{supplierSelectedOption.label}</ValueWithLabel>
                        }
                        {
                            artworkRequested &&
                            <ValueWithLabel label="Reviewer">{artworkPocs.map((item: { label: any; value: any; }) => item.label).join(', ')}</ValueWithLabel>
                        }
                        {
                        requestArtworkChecked == true &&
                            artworkRequested == false &&
                            artworkCompanyType == "agency" && 
                            <SpaceBetween size="m" direction="vertical">
                                <FormField label="Agency">
                                    <Input onChange={({detail}) => setAgency(detail.value)} value={agency}/>
                                </FormField>
                            </SpaceBetween>
                        }
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
            <Modal
                onDismiss={() => setApproveArtworkModalVisible(false)}
                visible={approveArtworkModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setApproveArtworkModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        setApproveArtworkModalVisible(false);
                        setRejectArtworkButtonDisabled(true);
                        setApproveArtworkButtonDisabled(true);
                    }}>Approve</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Approve artwork</Header>}
            >
                Are you sure that you want to approve the artwork?
                Once approved, you will need to create a revision job to adjust the artwork
            </Modal>
            {/* Reject TD Modal */}
            <Modal
                onDismiss={() => setRejectArtworkModalVisible(false)}
                visible={rejectArtworkModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setRejectArtworkModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        // make backend call to reject the TD
                        setRejectArtworkModalVisible(false);
                        setRejectArtworkButtonDisabled(true);
                        setApproveArtworkButtonDisabled(true);
                    }}>Reject</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Reject artwork</Header>}
            >
                <SpaceBetween size="l">
                    <FormField
                        label="Comment"
                        description="Explain what needs to be corrected"
                    >
                    <Input
                        onChange={({ detail }) => setRejectArtworkComment(detail.value)}
                        value={rejectArtworkComment}
                        placeholder="Enter comment"
                    />
                    </FormField>
                    <FormField
                        label="Upload file (optional)"
                        description="Upload a file if you need to provide additional information"
                        >
                        <FileUpload
                            onChange={({ detail }) => setArtworkSuggestionFileValue(detail.value)}
                            value={artworkSuggestionFileValue}
                            i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                            constraintText="Hint text for file requirements"
                        />
                        </FormField>
                </SpaceBetween>
            </Modal>
        </div>
    )
}