import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, Cards, Checkbox, Container, ContentLayout, Form, FormField, Header, Input, Link, SpaceBetween, Spinner, Tabs } from "@amzn/awsui-components-react";
import { subPackTypeDetailBreadcrumbs } from "../common/breadcrumbs";
import { useLocation, useParams } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { GET_SUB_PACK_TYPE } from "src/config/urlConfig";
import { SubPackTypesResponse } from "./SubPacktypes";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={subPackTypeDetailBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export interface SubPackTypeDetailResponse {
    id: string;
    name: string;
    flexible: boolean;
    packTypeId: string;
    specFields: any[];
    dcCodeFields: string[];

    packTypeName?: string;
    productTypeId?: string;
    productTypeName?: string;
}

const SubPackTypeDetailContent = (props: any) => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
  
    const elementDetails = location.state as SubPackTypesResponse | undefined;

    const [subPackTypeDetails, setSubPackTypeDetails] = useState<SubPackTypeDetailResponse>();
     
    const [activeTabId, setActiveTabId] = React.useState(
        "specsfields"
    );

    const client = createClient();

    const fetchSubPackType = async () => {
        const response = await client.get(GET_SUB_PACK_TYPE.replace('{id}', id || ""));
        if (response.data) {
            console.log(response.data.subPackType);
            setSubPackTypeDetails(response.data.subPackType);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchDetails = async () => {
            await fetchSubPackType();
        };
        fetchDetails();
    }, []);

    if (!subPackTypeDetails) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    return (
        <div>
                <SpaceBetween size="l" direction="vertical">
                <Container
                header={<Header 
                            variant="h2" 
                            description="General information about the sub pack type">
                            Sub pack type
                        </Header>}
                >
                    <SpaceBetween size="l" direction="vertical">
                    <ValueWithLabel label="Name">{elementDetails?.name}</ValueWithLabel>
                    <ValueWithLabel label="Pack type">{elementDetails?.packTypeName}</ValueWithLabel>
                    <ValueWithLabel label="Product type">{elementDetails?.productTypeName}</ValueWithLabel>
                    </SpaceBetween>
                </Container>
                <Tabs
                    onChange={({ detail }) =>
                        setActiveTabId(detail.activeTabId)
                    }
                    activeTabId={activeTabId}
                    tabs={[
                        {
                            label: "Spec fields",
                            id: "specsfields",
                            content: <SpecFieldsContent specs={subPackTypeDetails.specFields}/>,
                        },
                        {
                            label: "DC code information",
                            id: "dccodeinfo",
                            content: <DCCodeInfoContent dc={elementDetails?.dcCodeFields}/>,
                        }
                    ]}
                />
                </SpaceBetween>
        </div>
    );
}

const DCCodeInfoContent = (props: any) => {
    const fields = props.dc;
    console.log(fields);
    return (
        <div>
        <SpaceBetween direction="vertical" size="l">
        <Cards
        cardDefinition={{
                header: (item: any) => (
                    <Header variant="h3">{item.name}</Header>
                ),
                sections: [
                  {
                    id: "content",
                    content: item => item.content
                  },
                ]
              }}
        cardsPerRow={[
            { cards: 3},
        ]}
        items={[
            {
                name: "Dimensions and Weight",
                content: (
                <div>
                    <SpaceBetween direction="vertical" size="m">
                    {fields.map(attribute => {
                        const [name, unit] = attribute.split(/-(?=[^-]+$)/);
                        return (
                        <div key={name}>
                            <ValueWithLabel label={name}>{unit}</ValueWithLabel>
                        </div>
                        );
                    })}
                    </SpaceBetween>
                </div>
                )
            },
        ]}
    />
    </SpaceBetween>
    </div>
    )
}

const SpecFieldsContent = (props: any) => {
    const fields = props.specs;
    console.log(fields);
    return (
        <div>
        <SpaceBetween direction="vertical" size="l">
        <Cards
            cardDefinition={{
                    header: (item: any) => (
                        <Header variant="h3">{item.name}</Header>
                    ),
                    sections: [
                      {
                        id: "content",
                        content: item => item.content
                      },
                    ]
                  }}
            cardsPerRow={[
                { cards: 3},
            ]}
            items={[
                {
                    name: "Parameters",
                    content: (
                    <div>
                        <SpaceBetween direction="vertical" size="m">
                        {fields.map((obj, index) => {
                            return (
                            <div key={index}>
                                <ValueWithLabel label={obj.label}>{obj.unit}</ValueWithLabel>
                            </div>
                            );
                        })}
                        </SpaceBetween>
                    </div>
                    )
                },
            ]}
        />
        </SpaceBetween>
        </div>
    )
}

export class SubPackTypeDetail extends React.Component {
    render() {
        return(
                <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                content={
                <ContentLayout
                    header={
                    <Header
                        variant="h1"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Sub pack types define which spec fields must be filled for each packaging with this sub pack type"
                    >
                        Sub pack type details
                    </Header>
                    }
                >
                    <SubPackTypeDetailContent />
                </ContentLayout>
                }
                breadcrumbs={<Breadcrumbs />}
            />
            </body>
        )
    }
}
