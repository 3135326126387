import { Header } from "@amzn/awsui-components-react";
import { Component } from "react";

export class NotFound extends Component {
    render() {
        return (
            <body className="awsui-visual-refresh">
                <div>
                    <Header variant="awsui-h1-sticky">
                        Amazon Packaging Portal
                    </Header>
                    <div>
                        <Header variant="h2">
                            404 - Page not found
                        </Header>
                        The page you're looking for does not exist
                    </div>
                </div>
            </body>
        )
    }
}