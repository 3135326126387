import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, ContentLayout, FormField, Header, Link, Modal, Pagination, Select, SpaceBetween, Table, TextFilter } from "@amzn/awsui-components-react";
import { jobsOverviewBreadcrumbs } from "../common/breadcrumbs";
import { Navigation } from "../common/navigation";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGING_JOBS, GET_PACKAGING_JOB_DETAIL, GET_USER_LIST, REASSIGN_JOB } from "src/config/urlConfig";
import { ALL_JOBS_COLUMN_DEFINITION, ALL_JOBS_TABLE_COLUMN_DISPLAY } from "../common/table-config";
import { useNavigate } from "react-router-dom";
import { UserResponse } from "../admin/Users";
import { StringAble } from "@amzn/katal-metrics/lib/metricObject/KatalMetricString";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={jobsOverviewBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface JobsResponse {
    id: string;
    jobStatus: string;
    jobState: string;
    nextJobState: string;
    packNames: string;
    assignedToUserName: string;
    dateStart: number;
    supplierName: string;
}

const JobsOverviewContent = (props: any) => {
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    const [filteringText, setFilteringText] = React.useState("");

    const [rawJobsData, setRawJobsData] = useState<JobsResponse[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<JobsResponse[]>([]);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const [reassignModalVisible, setReassignModalVisible] = useState(false);

    const [peUsers, setPeUsers] = useState<any[]>([]);
    const [
        selectedOption,
        setSelectedOption
      ] = useState();

    const [proxyUser, setProxyUser] = useState<any>({});

    const client = createClient();

    const fetchAllJobs = async () => {
        const response = await client.get(GET_PACKAGING_JOBS);
        if (response.data) {
            const data: JobsResponse[] = response.data.packageJobs;
            const parsedData = data.map(item => ({
                id: item.id,
                jobStatus: item.jobStatus,
                jobState: item.jobState,
                nextJobState: item.nextJobState,
                packNames: item.packNames,
                assignedToUserName: item.assignedToUserName,
                dateStart: item.dateStart,
                supplierName: item.supplierName,
            }));

            setTableItems(parsedData);
            console.log(response.data);
        
            return response.data.packageJobs;
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchUsers = async() => {
        const response = await client.get(GET_USER_LIST);
        if (response.data) {
            const data: UserResponse[] = response.data.users;

            const currentPEUsers = data.filter((obj) => obj.roles.includes("PACKAGING_ENGINEER"));

            const parsedDataPE = currentPEUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setPeUsers(parsedDataPE);
        
            return response.data.users;
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        const fetchJobsList = async () => {
            setRawJobsData(await fetchAllJobs());
            setTableLoading(false);
        };
        const fetchUserList = async () => {
            await fetchUsers();
        };
        fetchJobsList();
        fetchUserList();
    }, []);

    const navigate = useNavigate();
    
    const handleDetailsClick = () => {
        const selectedId = selectedItems[0].id;
        const filteredArray = rawJobsData.filter(obj => obj.id === selectedId);
        navigate('/jobs/detail/'+selectedId);
    }

    const handleEditClick = () => {
        const nextJobState = "";
        if (selectedItems[0].nextJobState == "COMPLETE") {
            // TODO: Flash an alert saying you cannot edit this job as its already complete
        }
        if (selectedItems[0].nextJobState == "PACKAGING_DATABASE_FINAL_UPDATE") {
            navigate('/pe/newpackaging/6/'+selectedItems[0].id);
        }

        if (selectedItems[0].nextJobState == "REVIEW_FINAL_SPECS_APPROVED") {
            navigate('/pe/newpackaging/5/'+selectedItems[0].id);
        }

        if (selectedItems[0].nextJobState == "UNDER_REVIEW") {
            navigate('/pe/newpackaging/4/'+selectedItems[0].id);
        }

        if (selectedItems[0].nextJobState == "ARTWORK_REQUESTED") {
            navigate('/pe/newpackaging/3/'+selectedItems[0].id);
        }

        if (selectedItems[0].nextJobState == "TECHNICAL_DATA_REQUESTED") {
            navigate('/pe/newpackaging/2/'+selectedItems[0].id);
        }

        if (selectedItems[0].nextJobState == "BASIC_INPUT_PROVIDED") {
            navigate('/pe/newpackaging/1/'+selectedItems[0].id);
        }
    }

    const reassignJob = async() => {
        const reqBody = {
            "proxyUserId": proxyUser.value
        }
        const response = await client.post(REASSIGN_JOB.replace("{id}", selectedItems[0].id), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return;
    }

    const openReassignModal = () => {
        setReassignModalVisible(true);
    };

    return (
        <div>
            <Table
                onSelectionChange={({ detail }) =>
                    {
                        setSelectedItems(detail.selectedItems);
                        setActionButtonDisabled(false);
                    }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
                }}
                columnDefinitions={ALL_JOBS_COLUMN_DEFINITION}
                columnDisplay={ALL_JOBS_TABLE_COLUMN_DISPLAY}
                items = {tableItems}
                loading={tableLoading}
                loadingText="Loading resources"
                selectionType="single"
                trackBy="id"
                empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                    <b>No resources</b>
                    </SpaceBetween>
                </Box>
                }
                filter={
                <TextFilter
                    filteringText={filteringText}
                    filteringPlaceholder="Find jobs"
                    filteringAriaLabel="Filter jobs"
                    onChange={({ detail }) =>
                        setFilteringText(detail.filteringText)
                    }
                />
                }
                header={
                <Header
                    counter={"(" + tableItems.length + ")"}
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h1"
                    actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="xs"
                    >
                        <Button disabled={actionButtonDisabled} onClick={() => handleDetailsClick()}>View Details</Button>
                        <Button disabled={actionButtonDisabled} onClick={openReassignModal}>Reassign</Button>
                        <Button disabled={actionButtonDisabled} onClick={() => handleEditClick()}>Edit</Button>
                        <Button href="#" disabled={true}>Cancel</Button>
                        <Button variant="primary" href="/pe/newpackaging/0">
                        Create job
                        </Button>
                        <Button href="/revision">Create revision</Button>
                    </SpaceBetween>
                    }
                >
                    Job overview
                </Header>
                }
                pagination={
                <Pagination currentPageIndex={1} pagesCount={1} />
                }
            />
            
            {/* Reassign job modal */}
            <Modal
                onDismiss={() => setReassignModalVisible(false)}
                visible={reassignModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setReassignModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        reassignJob();
                        setReassignModalVisible(false);
                    }}>Reassign selected job</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Reassign job</Header>}
            >
                <SpaceBetween size="s">
                    <FormField
                        label="User"
                        description="Select a user from the list"
                    >
                        <Select
                            selectedOption={proxyUser}
                            onChange={({ detail }) =>
                                setProxyUser(detail.selectedOption)
                            }
                            options={peUsers}
                        />
                    </FormField>
                </SpaceBetween>
            </Modal>
        </div>
    );
}

export class JobsOverview extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                    contentType="table"
                    content={
                        <JobsOverviewContent />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/jobs" />}
                />
            </body>
        )
    }
}