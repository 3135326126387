import { Container, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react"
import { RevisionJobProps } from "../Revision"
import { ValueWithLabel } from "src/pages/common/types"
import { useEffect, useState } from "react";
import { GET_PACK_NAME } from "src/config/urlConfig";
import { createClient } from "src/client/AxiosClient";

const ConfContainer = ({index, job}: {index: number, job: any}) => {
    const [mpnName, setMPNName] = useState("");

    const client = createClient();

    const fetchMPN = async () => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', job.barcodes[index].masterPackNameId || ""));
        if (response.data) {
            console.log(response.data);
            setMPNName(response.data.masterPackName.name);
        }
        else {
            console.log(response);
        }
        return;
      }

    useEffect(() => {
        fetchMPN();
    }, []);
    
    if (mpnName == "") {
        return (
            <div>
                <Spinner/>
            </div>
        )
    }
      
    return (
        <Container
            header={
                <Header>
                    {job.barcodes[index].barcode + " (" + mpnName + ")"}
                </Header>
            }
        >

        </Container>
    )
}

export const Confirmation: React.FC<RevisionJobProps> = ({job, onJobDetailsChange})=> {
    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
            <Container
                header={
                    <Header>Supplier</Header>
                }
            >
                <ValueWithLabel label="Supplier name">{job.supplierName}</ValueWithLabel>
            </Container>

            <Header>Packaging</Header>

            <div>
                {
                    job.barcodes.map((packageItem, index) => (
                      <ConfContainer
                            index={index}
                            job={job}
                      />
                    ))
                  }
            </div>
            </SpaceBetween>
        </div>
    )
}