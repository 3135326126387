import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { BreadcrumbGroup, ButtonDropdown, ColumnLayout, Container, ContentLayout, Header, Link, Select, SpaceBetween, Spinner, StatusIndicator } from "@amzn/awsui-components-react";
import { Navigation } from "../common/navigation";
import { packageDetailBreadcrumbs } from "../common/breadcrumbs";
import { createClient } from "src/client/AxiosClient";
import { GET_DOCUMENT_FILE, GET_PACKAGE_ARTWORK, GET_PACKAGE_DETAIL, GET_PACKAGE_SPECS, GET_PACKAGE_TECHNICAL_DRAWING } from "src/config/urlConfig";
import { ValueWithLabel } from "../common/types";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={packageDetailBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

interface PackageDocumentModel {
    id: string;
    fileName: string;
    approvalStatus: string;
    packageJobId: string;
}

interface PackageSpecFieldModel {
    id: string;
    label: string;
    value: string;
    unit: string;
    category: string;
    minValue: number;
    maxValue: number;
}

interface PackageSpecFieldsModel {
    fields: PackageSpecFieldModel[];
    approvalStatus: string;
    referenceFileName: string;
    updatedByUserId: string;
    updatedByUserName: string;
    packageJobId: string;
}


export interface PackageVersionModel {
    draftVersion: string;
    approvedVersion: string;
    versions: number[];
}

export interface PackageManufacturingSiteModel {
    id: string;
    name: string;
    specFields: PackageVersionModel;
    artwork: PackageVersionModel;
}

export interface PackageDetail {
    id: string;
    masterPackNameId: string;
    masterPackName: string;
    subPackTypeId: string;
    subPackTypeName: string;
    packTypeId: string;
    packTypeName: string;
    productTypeId: string;
    productTypeName: string;
    supplierId: string;
    supplierName: string;
    barcode: string;
    valueCO2: number;

    manufacturingSites: PackageManufacturingSiteModel[];

    techDrawing: PackageVersionModel;
}

export const PackageDetailContent = () => {

    const [packageDetail, setPackageDetail] = useState<PackageDetail>();

    const [specs, setSpecs] = useState<PackageSpecFieldsModel>();

    const [techDrawing, setTechDrawing] = useState<PackageDocumentModel>();

    const [artwork, setArtwork] = useState<PackageDocumentModel>();

    const [artworkS3url, setArtworkS3Url] = useState<string | null>(null);
    const [tdS3url, setTDS3Url] = useState<string | null>(null);

    const [mSitesItems, setMSitesItems] = useState<any[]>([]);

    const [liveSpecSite, setLiveSpecSite] = useState<any>();
    const [liveTDSite, setLiveTDSite] = useState<any>();
    const [liveArtworkSite, setLiveArtworkSite] = useState<any>();

    const [specsVersions, setSpecsVersions] = useState<any[]>([]);
    const [tdVersions, setTDVersions] = useState<any[]>([]);
    const [artworkVersions, setArtworkVersions] = useState<any[]>([]);

    const [liveSpecsVersion, setLiveSpecsVersion] = useState<any>();
    const [liveTDVersion, setLiveTDVersion] = useState<any>();
    const [liveArtworkVersion, setLiveArtworkVersion] = useState<any>();

    const client = createClient();

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/packages\/detail\/([a-fA-F0-9-]+)/);
    
    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    const fetchSpecs = async(version: string, siteId: string) => {
        const response = await client.get(GET_PACKAGE_SPECS.replace("{id}", id).replace("{manufacturingSiteId}", siteId).replace("{versionNumber}", version));
        if (response.data) {
            console.log(response.data);
            setSpecs(response.data.specs);
        }
        else {
            console.log(response);
        }
    }

    const fetchTechnicalDrawing = async(version: string, siteId: string) => {
        const response = await client.get(GET_PACKAGE_TECHNICAL_DRAWING.replace("{id}", id).replace("{manufacturingSiteId}", siteId).replace("{versionNumber}",version));
        if (response.data) {
            console.log(response.data);
            setTechDrawing(response.data.techDrawing);
            fetchTechDrawingDocument(response.data.techDrawing.id);
        }
        else {
            console.log(response);
        }
    }

    const fetchArtwork = async(version: string, siteId: string) => {
        const response = await client.get(GET_PACKAGE_ARTWORK.replace("{id}", id).replace("{manufacturingSiteId}", siteId).replace("{versionNumber}",version));
        if (response.data) {
            console.log(response.data);
            setArtwork(response.data.artwork);
            fetchArtworkDocument(response.data.artwork.id);
        }
        else {
            console.log(response);
        }
    }

    const fetchArtworkDocument = async (id: string) => {
        const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", id))
        if (response.data) {
            setArtworkS3Url(response.data.url);
        }
        console.log(response);
    }

    const fetchTechDrawingDocument = async (id: string) => {
        const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", id))
        if (response.data) {
            setTDS3Url(response.data.url);
        }
        console.log(response);
    }

    const parseSiteItems = (sites: any[]) => {
        const parsedData = sites.map((site) => {
            return {
                text: site.name,
                id: site.id,
            };
        });
        setMSitesItems(parsedData);
        setLiveSpecSite(parsedData[0]);
        setLiveTDSite(parsedData[0]);
        setLiveArtworkSite(parsedData[0]);
    }

    const parseSpecVersions = (versions: number[]) => {
        const parsedData = versions.map((v) => {
            return {
                text: v,
                id: v,
            }
        })
        setSpecsVersions(parsedData);
        setLiveSpecsVersion(parsedData[0]);
    }

    const parseTDVersions = (versions: number[]) => {
        const parsedData = versions.map((v) => {
            return {
                text: v,
                id: v,
            }
        })
        setTDVersions(parsedData);
        setLiveTDVersion(parsedData[0]);
    }

    const parseArtworkVersions = (versions: number[]) => {
        const parsedData = versions.map((v) => {
            return {
                text: v,
                id: v,
            }
        })
        setArtworkVersions(parsedData);
        setLiveArtworkVersion(parsedData[0]);
    }


    const fetchPackageDetail = async () => {
        const response = await client.get(GET_PACKAGE_DETAIL.replace("{id}", id));
        if (response.data) {
            console.log(response.data);
            setPackageDetail(response.data.item);
            parseSiteItems(response.data.item.manufacturingSites);
            
            parseSpecVersions(response.data.item.manufacturingSites[0].specFields.versions);
            await fetchSpecs(response.data.item.manufacturingSites[0].specFields.approvedVersion || "0", response.data.item.manufacturingSites[0].id);

            parseTDVersions(response.data.item.techDrawing.versions);
            await fetchTechnicalDrawing(response.data.item.techDrawing.approvedVersion || "0", response.data.item.manufacturingSites[0].id);

            parseArtworkVersions(response.data.item.manufacturingSites[0].artwork.versions);
            await fetchArtwork(response.data.item.manufacturingSites[0].artwork.approvedVersion || "0", response.data.item.manufacturingSites[0].id);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
      const fetchPackage = async () => {
          await fetchPackageDetail();
      };
      fetchPackage();
    }, []);

    if (!packageDetail || (mSitesItems.length == 0) || specsVersions.length==0 || tdVersions.length==0 || artworkVersions.length==0) {
      return (
            <div>
                <Spinner size="big" />
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Container
                header={
                    <Header variant="h2">
                        {packageDetail.masterPackName + "(" + packageDetail.barcode + ")"}
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="m">
                    <ColumnLayout borders="vertical" columns={2}>
                        <div>
                            <ValueWithLabel label="Status">
                                <StatusIndicator type="success">Approved</StatusIndicator>
                            </ValueWithLabel>
                        </div>
                        <div>
                            <ValueWithLabel label="Product type">
                                {packageDetail.productTypeName}
                            </ValueWithLabel>
                        </div>
                        <div>
                            <ValueWithLabel label="Pack type">
                                {packageDetail.packTypeName}
                            </ValueWithLabel>
                        </div>
                        <div>
                            <ValueWithLabel label="Sub pack type">
                                {packageDetail.subPackTypeName}
                            </ValueWithLabel>
                        </div>
                        <div>
                            <ValueWithLabel label="Supplier">
                                {packageDetail.supplierName}
                            </ValueWithLabel>
                        </div>
                        <div>
                            <ValueWithLabel label="Manufacturing sites">
                                {packageDetail.manufacturingSites.map(site => site.name).join(', ')}
                            </ValueWithLabel>
                        </div>
                    </ColumnLayout>
                </SpaceBetween>
            
            </Container>

            <Container
                header={
                    <Header variant="h2"
                    actions={
                        <div>
                            <SpaceBetween direction="horizontal" size="m">
                                <ButtonDropdown
                                items={specsVersions}
                                onItemClick={(e) => {
                                    fetchSpecs(e.detail.id, liveSpecSite.id);
                                    setLiveSpecsVersion(e.detail.id);
                                }}
                                >
                                    {liveSpecsVersion.id}
                                </ButtonDropdown>

                                <ButtonDropdown
                                    items={mSitesItems}
                                    onItemClick={(e) => {
                                        console.log(e);
                                        setLiveSpecSite({
                                            id: e.detail.id,
                                            text: mSitesItems.filter((obj) => obj.id == e.detail.id)[0].name
                                        });
                                    }}
                                    variant="primary"
                                > 
                                {mSitesItems[0].text}
                                </ButtonDropdown>
                            </SpaceBetween>
                        </div>
                    }
                    >
                        Specs
                    </Header>
                }
            >
                <SpaceBetween size="l" direction="vertical">

                <ColumnLayout columns={3}>
                        {specs?.fields.map(field => (
                            <div key={field.id}>
                                    <ValueWithLabel label={field.label}>
                                        {field.value}
                                    </ValueWithLabel>
                            </div>
                        ))}
                </ColumnLayout>

                </SpaceBetween>
            </Container>

            <Container
                header={
                    <Header variant="h2"
                    actions={
                        <div>
                            <SpaceBetween direction="horizontal" size="m">
                                <ButtonDropdown
                                items={tdVersions}
                                onItemClick={(e) => {
                                    fetchTechnicalDrawing(e.detail.id, liveTDSite.id);
                                    setLiveTDVersion(e.detail.id);
                                }}
                                >
                                    {liveTDVersion.id}
                                </ButtonDropdown>

                                <ButtonDropdown
                                items={mSitesItems}
                                onItemClick={(e) => {
                                    console.log(e);
                                    setLiveTDSite({
                                        id: e.detail.id,
                                        text: mSitesItems.filter((obj) => obj.id == e.detail.id)[0].name
                                    });
                                }}
                                variant="primary"
                                > 
                                {mSitesItems[0].text}
                                </ButtonDropdown>
                            </SpaceBetween>
                        </div>
                    }
                    >
                        Technical drawing
                    </Header>
                }
            >
                {tdS3url && (
                    <div style={{height: "82rem"}}>
                        <iframe style={{width: "100%", height: "80rem", overflow: "auto"}} src={tdS3url}/>
                    </div>
                )}
            </Container>

            <Container
                header={
                    <Header variant="h2"
                    actions={
                        <div>
                            <SpaceBetween direction="horizontal" size="m">
                                <ButtonDropdown
                                items={artworkVersions}
                                onItemClick={(e) => {
                                    fetchArtwork(e.detail.id, liveArtworkSite.id);
                                    setLiveArtworkVersion(e.detail.id);
                                }}
                                >
                                    {liveArtworkVersion && liveArtworkVersion.id}
                                </ButtonDropdown>

                                <ButtonDropdown
                                items={mSitesItems}
                                onItemClick={(e) => {
                                    console.log(e);
                                    setLiveArtworkSite({
                                        id: e.detail.id,
                                        text: mSitesItems.filter((obj) => obj.id == e.detail.id)[0].name
                                    });
                                }}
                                variant="primary"
                                > 
                                {mSitesItems[0].text}
                                </ButtonDropdown>
                            </SpaceBetween>
                        </div>
                    }
                    >
                        Artwork
                    </Header>
                }
            >
                {artworkS3url && (
                    <div style={{height: "82rem"}}>
                        <iframe style={{width: "100%", height: "80rem", overflow: "auto"}} src={artworkS3url}/>
                    </div>
                )}
            </Container>
            </SpaceBetween>
        </div>
    )
}

export class PackageDetail extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
              <GlobalNav  />
            </div>
            <CustomAppLayout
            content={
              <ContentLayout
                header={
                  <Header
                    variant="h1"
                    info={
                      <Link id="main-info-link" variant="info">
                        Info
                      </Link>
                    }
                    description="View details of specific packaging"
                  >
                    Package details
                  </Header>

                }
              >
                <PackageDetailContent />
              </ContentLayout>
            }
            breadcrumbs={<Breadcrumbs />}
            headerSelector="#h"
            navigation={<Navigation activeHref="/producttypes" />}
            navigationOpen={false}
            navigationHide={true}
            />
            </body>  
        );
    }
}