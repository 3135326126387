import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import { AdminHome } from 'src/pages/admin/AdminHome';
import { Suppliers } from 'src/pages/admin/Suppliers';
import { EditSupplier } from 'src/pages/admin/EditSupplier';
import { SupplierDetail } from 'src/pages/admin/SupplierDetail';
import { Users } from 'src/pages/admin/Users';
import { AddUser } from 'src/pages/admin/AddUser';
import { EditUser } from 'src/pages/admin/EditUser';
import { ProductType } from 'src/pages/admin/ProductType';
import { AddProductType } from 'src/pages/admin/AddProductType';
import { ProductTypeDetail } from 'src/pages/admin/ProductTypeDetail';
import { EditProductType } from 'src/pages/admin/EditProductType';
import { AddSupplier } from 'src/pages/admin/AddSupplier';
import { NewPackaging } from 'src/pages/pe/NewPackaging';
import { TechnicalDrawingReview } from 'src/pages/pe/TechnicalDrawingReview';
import { ArtworkReview } from 'src/pages/pe/ArtworkReview';
import { FinalSpecsReview } from 'src/pages/pe/FinalSpecsReview';
import { PEHome } from 'src/pages/pe/PEHome';
import { PackType } from 'src/pages/admin/PackTypes';
import { AddPackType } from 'src/pages/admin/AddPackType';
import { SubPackType } from 'src/pages/admin/SubPacktypes';
import { EditSubPackType } from 'src/pages/admin/EditSubPackType';
import { SubPackTypeDetail } from 'src/pages/admin/SubPackTypeDetail';
import { PackNames } from 'src/pages/admin/PackNames';
import { PackNameDetail } from 'src/pages/admin/PackNameDetail';
import { AddPackName } from 'src/pages/admin/AddPackName';
import { AddSubPackType } from 'src/pages/admin/AddSubPackType';
import { ProvideData } from 'src/pages/supplier/ProvideData';
import { ProvideSpecs } from 'src/pages/supplier/ProvideSpecs';
import { ProvideSpecsEditor } from 'src/pages/supplier/ProvideSpecsEditor';
import { ProvideArtwork } from 'src/pages/supplier/ProvideArtwork';
import { JobsOverview } from 'src/pages/jobs/JobsOverview';
import { JobDetail } from 'src/pages/jobs/JobDetail';
import { getEnvironmentConfig } from 'src/config/envConfig';
import { getCognitoSession } from 'src/client/AxiosClient';
import { LandingPage } from 'src/pages/general/LandingPage';
import { Forbidden } from 'src/pages/general/Forbidden';
import { NotFound } from 'src/pages/general/NotFound';
import { SupplierHome } from 'src/pages/supplier/SupplierHome';
import { ProvideTechnicalDrawing } from 'src/pages/supplier/ProvideTechnicalDrawing';
import { SpecsReview } from 'src/pages/pe/SpecsReview';
import { PackagesOverview } from 'src/pages/packagingrepository/PackagesOverview';
import { PackageDetail } from 'src/pages/packagingrepository/PackageDetail';
import { Revision } from 'src/pages/pe/Revision';
import { ResubmitSpecsEditor } from 'src/pages/supplier/ResubmitSpecsEditor';
//import { Amplify,  Auth } from 'aws-amplify';
import FlashbarTest from 'src/components/Flashbar';
import { PackNameApproval } from 'src/pages/admin/PackNameApproval';
import { ProvideMasterArtwork } from 'src/pages/supplier/ProvideMasterArtwork';
import { ViewMasterTD } from 'src/pages/supplier/ViewMasterTD';

//Configuring Amplify
/* Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_XXiz6hlUR',
        userPoolWebClientId: '5nfqviha28p5hrlardhrtfl6q7',
        oauth: {
            domain: 'packaging-portal-alpha.auth.eu-west-1.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:4321/home',
            redirectSignOut: 'http://localhost:4321/home',
            responseType: 'code'
        }
    }
});

console.log("Amplify Auth config object: ", Auth.configure());

const AmplifySession = async (retryCount = 3) => {
    try {
        const sessionToken = await Auth.currentSession();
        const groupFromToken = sessionToken.getIdToken().payload['cognito:groups'][0];
        console.log("The group from amplify token is: ", groupFromToken);
        return groupFromToken;
    } catch (error) {
        console.error('Error getting the session: ', error);
        if (retryCount > 0) {
            console.log(`Retrying... Attempts remaining: ${retryCount - 1}`);
            await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
            return AmplifySession(retryCount - 1); // Recursive call with decreased retryCount
        }
        return null;
    }
};

AmplifySession().then(groupFromToken => {
    if (groupFromToken) {
        console.log("The group from amplify token is: ", groupFromToken);
    } else {
        console.log("Failed to retrieve the token after retries.");
    }
}); */




/* function removeQueryFromLocation() {
    console.log("window.history.length: ", window.history.length);
    if (window.history.length > 0) {
      const newHref = window.location.href.split('?code')[0];
      window.history.replaceState(undefined, 'MV Admin UI App', newHref);
    }
} */

interface AppState {
    userGroup: string | null;
    loading: boolean;
    showWarning: boolean;
    flashBarInfoShown: boolean;
    permission: boolean;
}
export class App extends React.Component<{}, AppState> {
    timeoutId: number | null = null;

    constructor(props) {
        super(props);

        const flashbarPreviouslyShown = localStorage.getItem('flashbarInfoShown') === 'true';

        this.state = {
            userGroup: null,
            loading: true,
            showWarning: false,
            flashBarInfoShown: flashbarPreviouslyShown,
            permission: true,
        };
    }

    componentDidMount() {
        // Check if the timestamp in localStorage is older than 24 hours
        const timestamp = localStorage.getItem('timestamp');
        const now = new Date().getTime();

        if (timestamp && now - parseInt(timestamp) > 1 * 60 * 60 * 1000) {                     //for 10 minutes: 10 * 60 * 1000, for 24hours: 24 * 60 * 60 * 1000
            // Clear localStorage and refresh the page
            localStorage.clear();
            localStorage.setItem('timestamp', now.toString());
            window.location.reload();
            return;
        }

        // Store the current timestamp if not already done
        if (!timestamp) {
            localStorage.setItem('timestamp', now.toString());
        }

        this.checkAndSetPermission();

        const flashbarInfoShown = localStorage.getItem('flashbarInfoShown') === 'true';
        this.setState({ flashBarInfoShown: flashbarInfoShown });
        this.timeoutId = window.setTimeout(() => {
            if (this.state.loading) {
                this.setState({ showWarning: true });
            }
        }, 4000);
        console.log('componentDidMount: Component is mounted');
        const envConfig = getEnvironmentConfig();
        const session = getCognitoSession(envConfig);

        const getUserGroupFromLocalStorage = () => {
            return localStorage.getItem('role');
        }

        const getUserGroupFromToken = () => {
            const userGroups = session.idToken.payload["cognito:groups"];
            if (userGroups.includes("APP_ADMIN")) {
                return "APP_ADMIN";
            }
            if (userGroups.includes("APP_PACKAGING_ENGINEER")) {
                return "APP_PACKAGING_ENGINEER";
            }
            if (userGroups.includes("APP_SUPPLIER")) {
                return "APP_SUPPLIER";
            }
            else return session.idToken.payload["cognito:groups"][0];
        }


        setTimeout(() => {
            let fetchedUserGroup = getUserGroupFromLocalStorage();
            if (!fetchedUserGroup) {
                fetchedUserGroup = getUserGroupFromToken();
                let role = JSON.stringify(fetchedUserGroup);
                let roleCut = role.slice(1, -1);
                localStorage.setItem('role', btoa(roleCut));
            }
            this.setState({
                userGroup: fetchedUserGroup,
                loading: false,
            }, () => {
                console.log('State updated: ', this.state);
            });
        }, 2000);

    }

    checkAndSetPermission = () => {
        const valEncoded = localStorage.getItem('role');
        if (valEncoded) {
            const permissionCheck = atob(valEncoded);

            if (permissionCheck.startsWith("eu-west-1")) {
                this.setState({ permission: false });
            }
        }
    };

    componentWillUnmount() {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }
    }

    dismissFlashbarWarning = () => {
        this.setState({ showWarning: false });
    }

    dismissFlashbarInfo = () => {
        localStorage.setItem('flashbarInfoShown', 'true');
        this.setState({ flashBarInfoShown: true });
    }

    dismissAccessDenied = () => {
        this.setState({ permission: true });
    }

    render() {
        const loading = this.state.loading;
        const encodedUserGroup = localStorage.getItem('role');
        const userGroup = encodedUserGroup ? atob(encodedUserGroup) : null;

        return (
            <Router>
                {!this.state.permission && (
                    <FlashbarTest
                        messageType='error'
                        message="You don't have an account created. Please contact Packaging Portal Administrator."
                        onDismiss={this.dismissAccessDenied}
                    />
                )}
                {this.state.showWarning && (
                    <FlashbarTest
                        messageType='warning'
                        message='In case of trouble logging in, please refresh the page.'
                        onDismiss={this.dismissFlashbarWarning}
                    />
                )}
                {!this.state.flashBarInfoShown && localStorage.getItem('flashbarInfoShown') === null && (
                    <FlashbarTest
                        messageType='info'
                        message='Welcome to the preview version of Amazon Packaging App. Please note that the application is in early stage of development, so expect limited functionality.'
                        onDismiss={this.dismissFlashbarInfo}
                    />
                )}
                <Routes>
                    <Route
                        path="/home"
                        element={
                            loading ? (
                                <LandingPage />
                            ) : userGroup === 'APP_ADMIN' ? (
                                <AdminHome />
                            ) : userGroup === 'APP_PACKAGING_ENGINEER' ? (
                                <PEHome />
                            ) : userGroup === 'APP_SUPPLIER' ? (
                                <SupplierHome />
                            ) : (
                                // Change this for other internal and external users
                                <LandingPage />
                            )
                        }
                    />

                    {/* Supplier management routes */}
                    <Route path='/suppliers' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<Suppliers />) : (<Forbidden />)
                    } />

                    <Route path='/suppliers/detail/:id' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<SupplierDetail />) : (<Forbidden />)
                    } />

                    <Route path='/suppliers/add' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<AddSupplier />) : (<Forbidden />)
                    } />
                    <Route path='/suppliers/edit/:id' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<EditSupplier />) : (<Forbidden />)
                    } />



                    {/* User management routes */}
                    <Route path='/users' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<Users />) : (<Forbidden />)
                    } />

                    <Route path='/users/add' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<AddUser />) : (<Forbidden />)
                    } />
                    <Route path='/users/edit/:id' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<EditUser />) : (<Forbidden />)
                    } />

                    {/* Product type management routes */}
                    <Route path='/producttypes' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<ProductType />) : (<Forbidden />)
                    } />

                    <Route path='/producttypes/detail/:id' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<ProductTypeDetail />) : (<Forbidden />)
                    } />

                    <Route path='/producttypes/add' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<AddProductType />) : (<Forbidden />)
                    } />
                    <Route path='/producttypes/edit/:id' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<EditProductType />) : (<Forbidden />)
                    } />

                    {/* Pack type management routes */}
                    <Route path='/packtypes' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<PackType />) : (<Forbidden />)
                    } />
                    <Route path='/packtypes/add' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<AddPackType />) : (<Forbidden />)
                    } />

                    {/* Sub pack type management routes */}
                    <Route path='/subpacktypes' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<SubPackType />) : (<Forbidden />)
                    } />
                    <Route path='/subpacktypes/detail/:id' element={
                        (userGroup == ('APP_ADMIN' || 'APP_PACKAGING_ENGINEER')) ?
                            (<SubPackTypeDetail />) : (<Forbidden />)
                    } />

                    <Route path='/subpacktypes/add' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<AddSubPackType />) : (<Forbidden />)
                    } />
                    <Route path='/subpacktypes/edit/:id' element={
                        (userGroup == 'APP_ADMIN') ?
                            (<EditSubPackType />) : (<Forbidden />)
                    } />

                    {/* Pack name management routes */}
                    <Route path='/packnames' element={<PackNames/>} />
                    <Route path='/packname/detail/:id' element={<PackNameDetail/>} />

                    <Route path='/packname/add' element={<AddPackName />} />

                    <Route path='/approve/task/:taskId/packname/:id' element={<PackNameApproval />} />

                    <Route path='/pe/newpackaging/:step' element={<NewPackaging/>} />
                    <Route path='/pe/newpackaging/:step/:jobId' element ={<NewPackaging />} />
                    {/* We can associate the below routes with job id when such calls are made to the backend */}
                    <Route path='/review/task/:taskId/technicaldrawing/:id/barcode/:bc' element={<TechnicalDrawingReview />} />
                    <Route path='/review/task/:taskId/specs/:id/barcode/:bc' element={<SpecsReview />} />
                    <Route path='/review/task/:taskId/artwork/:id/barcode/:bc' element={<ArtworkReview />} />
                    <Route path='/review/finalspecs' element={<FinalSpecsReview />} />

                    <Route path='/supplier/providedata/:id' element={<ProvideData />} />
                    <Route path='/supplier/providedata/task/:taskId/technicaldrawing/:id/barcode/:bc' element={<ProvideTechnicalDrawing />} />
                    <Route path='/supplier/providedata/task/:taskId/specs/:id/barcode/:bc' element={<ProvideSpecs />} />
                    <Route path='/supplier/providedata/task/:taskId/artwork/:id/barcode/:bc' element={<ProvideArtwork />} />

                    <Route path='/supplier/providedata/specs/:id/barcode/:bc' element={<ProvideSpecsEditor />} />
                    <Route path='/supplier/resubmit/task/:taskId/specs/:id/barcode/:bc' element={<ResubmitSpecsEditor />} />
                    <Route path='/supplier/providedata/artwork/:id/barcode/:bc' element={<ProvideArtwork />} />

                    <Route path='/supplier/providemasterartwork/task/:taskId/packname/:mpnId' element={<ProvideMasterArtwork />} />
                    <Route path='/supplier/view/technicaldrawing/:mpnId' element={<ViewMasterTD />} />

                    <Route path='/jobs' element={<JobsOverview />} />
                    <Route path='/jobs/detail/:id' element={<JobDetail />} />

                    <Route path='/packages' element={<PackagesOverview />} />
                    <Route path='/packages/detail/:id' element={<PackageDetail />} />

                    <Route path='/revision' element={<Revision />} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        );
    }
}

export default App;
