import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, Container, ContentLayout, Header, Link, SpaceBetween, Spinner, StatusIndicator } from "@amzn/awsui-components-react";
import { Navigation } from "../common/navigation";
import { jobDetailsBreadcrumbs } from "../common/breadcrumbs";
import { useParams } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGING_JOB_DETAIL } from "src/config/urlConfig";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { StringAble } from "@amzn/katal-metrics/lib/metricObject/KatalMetricString";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={jobDetailsBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

interface ManufacturingSite {
  id: string;
  name: string;
  specFields: PackageJobBarcodeSpecFieldsModel[];
}

interface PackageJobBarcodeSpecFieldsModel {
  id: string;
  approval: ApprovalStatus;
}

type ApprovalStatus = 'APPROVED' | 'PENDING' | 'NONE'; 

interface Barcode {
    barcode: string;
    masterPackName: string;
    masterPackNameId: string;
    dcCode: any;
    flute: string;
    manufacturingSites: any[];
    subPackTypeId: string;
    subPackTypeName: string;
    packTypeId: string;
    packTypeName: string;
    techDrawing?: any;
}

export interface JobDetails {
    id: string;
    dateStart: number;
    lastUpdatedOn: number;
    productTypeName: string;
    productTypeId?: string;
    description: string;
    jobState: string;
    createdByUserName: string;
    assignedToUserName: string;
    supplierName: string;
    supplierId: string;
    manufacturingSiteLevel: boolean;
    barcodes: Barcode[];
}

interface BarcodeProps {
    index: number;
    barcode: Barcode;
}

const BarcodeContent: React.FC<BarcodeProps> = ({index, barcode}) => {

    const getConsolidatedStatusSpecs = () => {
        if (barcode.manufacturingSites.length > 0) {
          let consolidatedStatus: ApprovalStatus = 'APPROVED';

          for (const manufacturingSite of barcode.manufacturingSites) {
              if (manufacturingSite.specFields.approval !== 'APPROVED') {
                consolidatedStatus = 'PENDING';
                break;
              }
            }
          
          return consolidatedStatus;
        }
        return "OPEN";
    }

    const getConsolidatedStatusTechDrawing = () => {
      if (barcode.techDrawing.approval == 'APPROVED') {
          return 'APPROVED';
      }
      return "PENDING";
    }

    const getConsolidatedStatusArtwork = () => {
      if (barcode.manufacturingSites.length > 0) {
        let consolidatedStatus: ApprovalStatus = 'APPROVED';

        for (const manufacturingSite of barcode.manufacturingSites) {
            if (manufacturingSite.artwork.approval !== 'APPROVED') {
              consolidatedStatus = 'PENDING';
              break;
            }
          }
        
        return consolidatedStatus;
      }
      return "OPEN";
    }

    return (
        <div>
            <Container
              header={
                <Header variant="h2">
                    {barcode.masterPackName + " (" + barcode.barcode + ")"}
                </Header>
              
              }>
                  <ColumnLayout columns={3} borders="vertical">
                      <div>
                            <ValueWithLabel label="Specs">
                                {
                                    getConsolidatedStatusSpecs() == 'OPEN' && 
                                    <StatusIndicator type="in-progress">Open</StatusIndicator>
                                }
                                {
                                    getConsolidatedStatusSpecs() == 'PENDING' &&
                                    <StatusIndicator type="pending">Pending</StatusIndicator>
                                }
                                {
                                    getConsolidatedStatusSpecs() == 'APPROVED' &&
                                    <StatusIndicator type="success">Approved</StatusIndicator>
                                }
                            </ValueWithLabel>
                      </div>
                      <div>
                            <ValueWithLabel label="Technical drawing">
                                {
                                    getConsolidatedStatusTechDrawing() == 'PENDING' &&
                                    <StatusIndicator type="pending">Pending</StatusIndicator>
                                }
                                {
                                    getConsolidatedStatusTechDrawing() == 'APPROVED' &&
                                    <StatusIndicator type="success">Approved</StatusIndicator>
                                }
                            </ValueWithLabel>
                      </div>
                      <div>
                          <ValueWithLabel label="Artwork">
                                {
                                    getConsolidatedStatusArtwork() == 'OPEN' && 
                                    <StatusIndicator type="in-progress">Open</StatusIndicator>
                                }
                                {
                                    getConsolidatedStatusArtwork() == 'PENDING' &&
                                    <StatusIndicator type="pending">Pending</StatusIndicator>
                                }
                                {
                                    getConsolidatedStatusArtwork() == 'APPROVED' &&
                                    <StatusIndicator type="success">Approved</StatusIndicator>
                                }
                          </ValueWithLabel>
                      </div>
                  </ColumnLayout>
            </Container>
        </div>
    )
}

const JobDetailContent = (props: any) => {
    const [jobDetail, setJobDetail] = useState<JobDetails>();

    const client = createClient();

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/jobs\/detail\/([a-fA-F0-9-]+)/);
    
    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setJobDetail(response.data.packageJob);
            console.log(response.data.packageJob);
        }
        else {
            console.log(response);
        }
        return [];
    }
    
    const parseDate = (dateText: number) => {
      const date = new Date(dateText*1000);
      const month = date.getMonth() + 1;
      return date.getDate() + "/" + month + "/" + date.getFullYear();
    }

    const getDataGranularity = (sitelevel: boolean) => {
        if (sitelevel == true) {
            return "Manufacturing site level"
        }
        return "Default";
    }

    useEffect(() => {
      const fetchJobsList = async () => {
          await fetchJobDetail();
      };
      fetchJobsList()
    }, []);

    if (!jobDetail) {
      return (
            <div>
                <Spinner size="big" />
            </div>
        )
    }

      return (
          <div>
          
              <SpaceBetween size="l" direction="vertical">
                  <Container
                      header={
                          <Header variant="h2"
                              description="Information to identify the job"
                          >
                              General Information
                          </Header>
                      }
                  >
                    <SpaceBetween size="l" direction="vertical">
                    <ColumnLayout borders="vertical" columns={3}>
                        <div>
                          <ValueWithLabel label="Job ID">{jobDetail.id}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Product type">{jobDetail.productTypeName || "-"}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Status">{jobDetail.jobState || "-"}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Created by">{jobDetail.createdByUserName || "-"}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Created at">{parseDate(jobDetail.dateStart)}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Assigned to">{jobDetail.assignedToUserName || "-"}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Supplier">{jobDetail.supplierName || "-"}</ValueWithLabel>
                        </div>
                        <div>
                          <ValueWithLabel label="Data granularity">{getDataGranularity(jobDetail.manufacturingSiteLevel)}</ValueWithLabel>
                        </div>
                    </ColumnLayout>
                    <div>
                        <ValueWithLabel label="Description">
                            {jobDetail.description || "-"}
                        </ValueWithLabel>
                    </div>
                    </SpaceBetween>
                  </Container>
                  <Header variant="h1" description="Status of the packaging data in this job">
                      Packaging
                  </Header>
                  {
                      jobDetail.barcodes.map((barcode, index) => (
                        <BarcodeContent key={index} index={index} barcode={barcode} />
                      ))
                  }  
              </SpaceBetween>
          </div>
      )
}

export class JobDetail extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
              <GlobalNav  />
            </div>
            <CustomAppLayout
            content={
              <ContentLayout
                header={
                  <Header
                    variant="h1"
                    info={
                      <Link id="main-info-link" variant="info">
                        Info
                      </Link>
                    }
                    description="View job details here"
                  >
                    Job details
                  </Header>

                }
              >
                <JobDetailContent />
              </ContentLayout>
            }
            breadcrumbs={<Breadcrumbs />}
            headerSelector="#h"
            navigation={<Navigation activeHref="/jobs" />}
            navigationOpen={false}
          />
          </body>
        );
    }
}