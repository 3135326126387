import { Box, Button, ColumnLayout, Container, Header, SpaceBetween, Spinner } from "@amzn/awsui-components-react"
import { PackagingJobProps } from "./BasicInput";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGING_JOB_DETAIL, PUT_JOB_STATUS } from "src/config/urlConfig";
import { useEffect, useState } from "react";
import { JobDetails } from "src/pages/jobs/JobDetail";
import { PackagingJob } from "../NewPackaging";
import { useParams } from "react-router-dom";

const ValueWithLabel = ({ label, children } : {label: string, children: any})  => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export const Confirmation: React.FC<PackagingJobProps> = ({job, onJobDetailsChange}) => {

    const [packagingJob, setPackagingJob] = useState<PackagingJob>();

    const { jobId } = useParams<{ jobId: string }>();
    
    const client = createClient();

    const closeJob = async() => {
        const response = await client.put(PUT_JOB_STATUS.replace("{id}", jobId || "").replace("{jobStatus}", "CLOSED"));
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", jobId || ""));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchJobDetail();
        };
        fetchData()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner/>
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Container header={
                <Header variant="h2"
                    actions={
                        <Button variant="primary" onClick={() => closeJob()}>Close job</Button>
                    }
                >
                    Supplier
                </Header>
            }>
                <ValueWithLabel label="Supplier name">{packagingJob.supplierName}</ValueWithLabel>
            </Container>
            {packagingJob.barcodes.map((barcode, index) => (
                <Container header={
                    <Header variant="h2">
                        {packagingJob.barcodes[index].masterPackName + "(" + barcode.barcode + ")"}
                    </Header>
                }>
                </Container>
            ))}
            
            </SpaceBetween>
        </div>
    )
}