import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Alert, Box, BreadcrumbGroup, Button, Checkbox, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Input, Link, Modal, SpaceBetween, Spinner, StatusIndicator, Tabs } from "@amzn/awsui-components-react";
import { newPackagingTechnicalDataBreadcrumbs } from "../common/breadcrumbs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import YourPDFFile from "src/assets/test.pdf";
import TechnicalDrawingFile from "src/assets/technicaldrawing.pdf";
import { Document, Page, pdfjs } from 'react-pdf';
import { JobDetails } from "../jobs/JobDetail";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGING_JOB_DETAIL, GET_PACK_NAME, GET_SUPPLIER, REVIEW_SPECS } from "src/config/urlConfig";
import { SupplierDetail } from "../admin/SupplierDetail";
import { SupplierResponse } from "../admin/Suppliers";
import { SpecFields } from "../supplier/ProvideSpecsEditor";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Breadcrumbs = () => (
    <BreadcrumbGroup items={newPackagingTechnicalDataBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export const SpecsReviewContent = () => {

    const [externalLengthChecked, setExternalLengthChecked] = useState(false);
    const [externalWidthChecked, setExternalWidthChecked] = useState(false);
    const [externalHeightChecked, setExternalHeightChecked] = useState(false);
    const [weightChecked, setWeightChecked] = useState(false);
    const [boardSurfaceChecked, setBoardSurfaceChecked] = useState(false);

    const [fieldCheckedStatus, setFieldCheckedStatus] = useState({});

    const updateFieldCheckedStatus = (fieldId, checked) => {
        setFieldCheckedStatus(prevStatus => ({
            ...prevStatus,
            [fieldId]: checked,
        }));
    };

    const getFieldCheckedState = (fieldId) => {
        return fieldCheckedStatus[fieldId] || false;
    };

    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    const [rejectSpecsButtonDisabled, setRejectSpecsButtonDisabled] = useState(false);
    const [approveSpecsButtonDisabled, setApproveSpecsButtonDisabled] = useState(false);

    const [rejectSpecsModalVisible, setRejectSpecsModalVisible] = useState(false);

    const openRejectSpecsModal = () => {
        setRejectSpecsModalVisible(true);
    };

    const [approveSpecsModalVisible, setApproveSpecsModalVisible] = useState(false);

    const openApproveSpecsModal = () => {
        setApproveSpecsModalVisible(true);
    };

    const downloadSpecs = () => {
        const a = document.createElement('a');
        a.href = YourPDFFile;
        a.download = 'test.pdf'; // Set the desired file name
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const [rejectSpecsComment, setRejectSpecsComment] = useState("");

    const [specsReviewStatus, setReviewSpecsStatus] = useState("");

    function getStatusText() {
        if (specsReviewStatus == "approved") {
            return "Approved";
        }
        if (specsReviewStatus == "rejected") {
            return "Rejected";
        }
        return "Approval pending";
    }

    function getStatusType() {
        if (specsReviewStatus == "approved") {
            return "success";
        }
        if (specsReviewStatus == "rejected") {
            return "error";
        }
        return "pending";
    }

    const client = createClient();

    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/home');
    }

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/review\/task\/[^/]+\/specs\/([^/]+)\/barcode\/[^/]+/);
    
    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    let barcode = "";
    const pattern: RegExp = /\/barcode\/([^/#?]+)/;
    const barcodeMatch: RegExpMatchArray | null = url.match(pattern);

    if (barcodeMatch) {
        barcode = barcodeMatch[1];
    }

    const [supplier, setSupplier] = useState<SupplierResponse>();
    const [mpnFields, setMpnFields] = useState<SpecFields>();

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data);
            await fetchSuppliers(response.data.packageJob.supplierId);
            await fetchPackName(response.data.packageJob.barcodes[0].masterPackNameId);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchSuppliers = async (supplierId: string) => {
        console.log("supplier: " + packagingJob?.supplierName + supplierId);
        const response = await client.get(GET_SUPPLIER.replace("{id}", supplierId));
        if (response.data) {
            const data: any = response.data.supplier;
            setSupplier(response.data.supplier);
            console.log(data);
            return;
        }
        else {
          console.log(response);
        }
        return [];
    }

    const fetchPackName = async (mpnId: string) => {
        const response = await client.get(GET_PACK_NAME.replace("{id}", mpnId));
        if (response.data) {
            setMpnFields(response.data.masterPackName.specFields);
        }
        console.log(response.data);
    }

    const approveSpecs = async() => {
        const taskIdMatch: RegExpMatchArray | null = url.match(/\/review\/task\/([^/]+)\/specs\/[^/]+\/barcode\/[^/]+/);
    
        let taskId = "";
        if (taskIdMatch) {
            taskId = taskIdMatch[1];
        }

        const pattern: RegExp = /#([a-fA-F0-9-]+)$/;
        const siteMatch: RegExpMatchArray | null = url.match(pattern);
        let siteId = ""
        if (siteMatch) {
            siteId = siteMatch[1];
        }

        const reqBody = {
            "openTaskId": taskId
        }
        const baseUrl = "/packageJobs/{id}/barcode/{barcode}".replace("{id}", id).replace("{barcode}", barcode);
        const constructedURL = baseUrl+"/specs/manufacturingSite/" + siteId + "/APPROVED";
        const response = await client.put(constructedURL, reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    const rejectSpecs = async() => {
        const taskIdMatch: RegExpMatchArray | null = url.match(/\/review\/task\/([^/]+)\/specs\/[^/]+\/barcode\/[^/]+/);
    
        let taskId = "";
        if (taskIdMatch) {
            taskId = taskIdMatch[1];
        }

        const pattern: RegExp = /#([a-fA-F0-9-]+)$/;
        const siteMatch: RegExpMatchArray | null = url.match(pattern);
        let siteId = ""
        if (siteMatch) {
            siteId = siteMatch[1];
        }

        const markedFields = Object.entries(fieldCheckedStatus)
        .filter(([fieldId, isChecked]) => isChecked)
        .map(([fieldId]) => fieldId);

        const reqBody = {
            "openTaskId": taskId,
            "markedFields": markedFields,
            "comment": rejectSpecsComment,
        }
        console.log(reqBody);
        const response = await client.put(REVIEW_SPECS.replace("{id}", id).replace("{barcode}", barcode).replace("{manufacturingSiteId}", siteId).replace("{approvalStatus}", "REJECTED"), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    const verifyValue = (id: string, value: string) => {
        if (mpnFields) {
            const mpnField = mpnFields.versions["1"].filter((obj) => obj.id == id)[0];
            const maxValue = mpnField.maxValue ?? 99999;
            if (!isNaN(parseFloat(value)) && parseFloat(value) > maxValue) {
                return ("Value is greater than maxvalue: " + mpnField?.maxValue);
            }

            const minValue = mpnField?.minValue ?? 0;
            if (!isNaN(parseFloat(value)) && parseFloat(value) < minValue) {
                return ("Value is lesser than minvalue: " + mpnField?.minValue);
            }
        }
        return "";
    }

    useEffect(() => {
        const fetchJobs = async () => {
            await fetchJobDetail();
        };
        fetchJobs()
    }, []);
  
    if (!packagingJob) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }

    return (
        <div>
            <div>
            <SpaceBetween direction="vertical" size="xl">
             {/* Supplier info container */}
             <Container
                header={
                    <Header variant="h2">
                        { packagingJob.barcodes[0].barcode }
                    </Header>
                }
            >
                <ColumnLayout columns={2} borders="vertical">
                    <ValueWithLabel label="Supplier name">{supplier?.name}</ValueWithLabel>
                    <ValueWithLabel label="Manufacturing sites">{supplier?.manufacturingSites.length}</ValueWithLabel>
                </ColumnLayout>
            </Container>

            <Alert statusIconAriaLabel="Info" header="Review specs">
                If there are any incorrect specs, mark them and reject the specs. Changes are marked in blue.
            </Alert>

            <Container header={
                <Header variant="h2"
                // TODO: Change status indicator text based on approval state
                    description={<StatusIndicator type={getStatusType()}>{getStatusText()}</StatusIndicator>}
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button disabled={rejectSpecsButtonDisabled} onClick={openRejectSpecsModal}>Reject</Button>
                            <Button disabled={approveSpecsButtonDisabled} variant="primary" onClick={openApproveSpecsModal}>Approve</Button>
                        </SpaceBetween>
                    }
                >
                    All manufacturing sites
                </Header>
            }>
                <div>
                    <SpaceBetween size="l" direction="vertical">
                    <ColumnLayout columns={3}>
                        {packagingJob?.barcodes?.[0]?.manufacturingSites?.[0]?.specFields?.fields?.map(field => (
                            <div key={field.id}>
                                <FormField
                                    errorText={verifyValue(field.id, field.value)}
                                >
                                <Checkbox
                                    onChange={({ detail }) => updateFieldCheckedStatus(field.id, detail.checked)}
                                    checked={getFieldCheckedState(field.id)}
                                >
                                    <ValueWithLabel label={field.label}>
                                        {field.value}
                                    </ValueWithLabel>
                                </Checkbox>
                                </FormField>
                            </div>
                        ))}
                    </ColumnLayout>
                    <Header variant="h3">File</Header>
                    <ValueWithLabel label="Additional information"><Button onClick={downloadSpecs}>Download file</Button></ValueWithLabel>
                    </SpaceBetween>
                </div>
            </Container>

            <div style={{width: '100%'}}>
                <div style={{float: 'right'}}><Button onClick={navigateToHome}>Close review</Button></div>
            </div>
            </SpaceBetween>
        </div>
            {/* Reject specs modal */}
            <Modal
                onDismiss={() => setRejectSpecsModalVisible(false)}
                visible={rejectSpecsModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setRejectSpecsModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        rejectSpecs();
                        setRejectSpecsModalVisible(false);
                        setRejectSpecsButtonDisabled(true);
                        setApproveSpecsButtonDisabled(true);
                        setReviewSpecsStatus("rejected");
                    }}>Reject</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Reject specs</Header>}
            >
                <SpaceBetween size="s">
                    <FormField
                        label="Comment"
                        description="Explain what needs to be corrected"
                    >
                    <Input
                        onChange={({ detail }) => setRejectSpecsComment(detail.value)}
                        value={rejectSpecsComment}
                        placeholder="Enter comment"
                    />
                    </FormField>
                </SpaceBetween>
            </Modal>
            {/* Approve specs modal */}
            <Modal
                onDismiss={() => setApproveSpecsModalVisible(false)}
                visible={approveSpecsModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setApproveSpecsModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        // make backend call to APPROVE specs
                        approveSpecs();
                        setApproveSpecsModalVisible(false);
                        setRejectSpecsButtonDisabled(true);
                        setApproveSpecsButtonDisabled(true);
                        setReviewSpecsStatus("approved");
                    }}>Approve</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Approve specs</Header>}
            >
                Are you sure that you want to approve the specs?
                Once approved, you will need to create a revision job to adjust the specs
            </Modal>
        </div>
    )
}

export class SpecsReview extends React.Component {

    render () {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            variant="h1"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                        >
                            Review specs
                        </Header>
                        }
                    >
                        <SpecsReviewContent/>
                    </ContentLayout>
                    }
                    breadcrumbs={<Breadcrumbs />}
                />
            </body>
        )
    }
}