import React, {useEffect, useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {
    Alert,
    Box,
    BreadcrumbGroup,
    Button,
    FormField,
    Header,
    Input,
    Link,
    Modal,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";
import {usersBreadcrumbs} from "../common/breadcrumbs";
import {USER_TABLE_COLUMN_DEFINITION, USER_TABLE_COLUMN_DISPLAY} from "src/pages/common/table-config";
import {CustomAppLayout} from "../common/common-components";
import {Navigation} from "../common/navigation";
import {DEACTIVATE_USER, GET_USER_LIST} from "src/config/urlConfig";
import {useNavigate} from "react-router-dom";
import {createClient} from "src/client/AxiosClient";
import { Session } from "./AdminHome";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={usersBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface UserResponse {
  activeStatus: string;
  email: string;
  id: string;
  name: string;
  roles: string[];
  userCompany: string;
  externalCompanyId?: string;
  externalCompanyType?: string;
}

interface UserRow {
  activeStatus: string,
  email: string;
  id: string;
  name: string;
  roles: string;
  userCompany: string;
}

const UserTable = (session: any) => {
    const [selectedItems, setSelectedItems] = useState<UserRow[]>([]);
    const [filteringText, setFilteringText] = useState("");
    const [rawUsersData, setRawUsersData] = useState<UserResponse[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<UserRow[]>([]);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const client = createClient();

    const fetchUsers = async () => {
      const response = await client.get(GET_USER_LIST);
      if (response.data) {
        const data: UserResponse[] = response.data.users;
        const parsedData = data.map((item) => ({
            activeStatus: item.activeStatus,
            email: item.email,
            id: item.id,
            name: item.name,
            roles: item.roles.join(', '),
            userCompany: item.userCompany,
            externalCompanyId: item.externalCompanyId,
            externalCompanyType: item.externalCompanyType,
        }));

        setTableItems(parsedData);
    
        return response.data.users;
      }
      else {
        console.log(response);
      }
      return [];
    }

    useEffect(() => {
      const fetchUserList = async () => {
          setRawUsersData(await fetchUsers());
          setTableLoading(false);
      };
      fetchUserList()
    }, []);

    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState("");

    const openModal = () => {
      setVisible(true);
    };

    const closeModal = () => {
      setVisible(false);
    }

    const deactivateUser = async () => {
      const reqBody = {
        "id": selectedItems[0].id,
        "activeStatus": "INACTIVE",
        "userCompany": selectedItems[0].userCompany
      }
      const response = await client.put(DEACTIVATE_USER
        .replace('{id}', selectedItems[0].id)
        .replace('{userCompany}', selectedItems[0].userCompany), 
        reqBody)
      if (response.data) {
        console.log(response.data);
      }
      else {
        console.log(response);
      }
      return;
    }

    const navigate= useNavigate();
    
    const handleEditUser = () => {
      const selectedId = selectedItems[0].id;
      const filteredArray = rawUsersData.filter(obj => obj.id === selectedId);
      navigate('/users/edit/'+selectedId, {state: filteredArray[0]})
    }
    
    return (
      <div>
      <Table
        variant="full-page"
        onSelectionChange={({ detail }) =>
          {
            setSelectedItems(detail.selectedItems);
            setActionButtonDisabled(false);
          }
        }
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item) =>
            item.name
        }}
        columnDefinitions={USER_TABLE_COLUMN_DEFINITION}
        columnDisplay={USER_TABLE_COLUMN_DISPLAY}
        items = {tableItems}
        loading={tableLoading}
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
            </SpaceBetween>
          </Box>
        }
        // TODO: Filtering is a very complex functionality. Take time to understand what works best for APP
        filter={
          <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Find user"
            filteringAriaLabel="Filter user"
            onChange={({ detail }) =>
                setFilteringText(detail.filteringText)
            }
          />
        }
        header={
          <Header
            counter={"(" + tableItems.length + ")"}
            info={
                <Link id="main-info-link" variant="info">
                  Info
                </Link>
            }
            variant="h1"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <Button disabled={actionButtonDisabled} onClick={handleEditUser}>Edit</Button>
                <Button onClick={openModal} disabled={actionButtonDisabled}>Deactivate</Button>
                <Button variant="primary" href="/users/add">
                  Add user
                </Button>
              </SpaceBetween>
            }
          >
            Users
          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={1} />
        }
      />
      <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                  <Button variant="primary" onClick={() => {
                    deactivateUser();
                    setVisible(false);
                  }}>
                  Deactivate</Button>
                </SpaceBetween>
              </Box>
            }
            header={
              <Header
                      variant="h1"
                      description="Do you really want to deactivate this user?"
                    >
                      Deactivate User
                    </Header>
            }
          >
            <SpaceBetween size="s">
                <Alert statusIconAriaLabel="Warning" type="warning">
                    The user will not be able to login anymore. Please reassign any open tasks for this user.
                </Alert>
                To avoid accidental deactivation, we ask you to provide additional written consent.
                <FormField
                    label="Type confirm to agree"
                >
                <Input
                    onChange={({ detail }) => setValue(detail.value)}
                    value={value}
                    placeholder="confirm"
                />
                </FormField>
            </SpaceBetween>
        </Modal>
      </div>
    );
}

export class Users extends React.Component  {
    render() {
        const mainAppProps = this.props;
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout 
                    contentType="table"
                    content={
                      <UserTable session={mainAppProps}/>
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/users" />}
                />
            </body>
        );
    }
}