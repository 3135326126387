import { ColumnLayout, Container, FormField, Header, Input, Select, SpaceBetween, Spinner } from "@amzn/awsui-components-react"
import { useEffect, useState } from "react";
import { PackNamesResponse } from "../PackNames";
import { SpecFields } from "../AddPackName";
import { createClient } from "src/client/AxiosClient";
import { GET_SUB_PACK_TYPE } from "src/config/urlConfig";
import { SubPackTypesResponse } from "../SubPacktypes";
import { Field } from "../EditSubPackType";

interface PackNameSpecsProps {
    packNameDetail: PackNamesResponse;
    onPackNameSpecsChange: (updatedSpecs: SpecFields[]) => void;
}

export const MasterSpecs: React.FC<PackNameSpecsProps> = ({packNameDetail, onPackNameSpecsChange}) => {

    const [masterFields, setMasterFields] = useState<Field[]>([]);

    const [specFields, setSpecFields] = useState<SpecFields[]>([]);

    const client = createClient();

    const fetchSubPackType = async () => {
        const response = await client.get(GET_SUB_PACK_TYPE.replace("{id}", packNameDetail.subPackTypeId));
        if (response.data) {
            console.log(response.data.subPackType);
            setMasterFields(response.data.subPackType.specFields);
        }
        else {
          console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchPackTypesList = async () => {
            await fetchSubPackType();
        };
        fetchPackTypesList()
    }, []);

    if (!masterFields) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
                <Container
                    header={
                        <Header variant="h2" 
                        description="Choose a reference pack name, which has similar characteristics, to make the creation faster"
                        >
                            Reference (optional)
                        </Header>
                    }
                >
                    <FormField label="Pack name">
                        <Input value="" placeholder="Select pack name"/>
                    </FormField>
                </Container>

                <RenderFields masterFields={masterFields} onPackNameSpecsChange={onPackNameSpecsChange}/>

            </SpaceBetween>
        </div>
    )
}

const RenderFields = ({ masterFields, onPackNameSpecsChange }: { masterFields: any[]; onPackNameSpecsChange: (updatedSpecs: SpecFields[]) => void }) => {
    // const categories: string[] = Array.from(new Set(masterFields.map(field => field.category)));

    const [specFields, setSpecFields] = useState<SpecFields[]>([]);
    console.log(masterFields);

    const handleFieldUpdate = (specfields: SpecFields[]) => {
        setSpecFields(specfields);
        onPackNameSpecsChange(specfields);
    }

    if (masterFields.length == 0) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }
      
        return (
            <Container>
              <SpaceBetween direction="vertical" size="m">
              {masterFields.map(field => (
                <FieldsInput index={field.id} field={field} specFields={specFields} handleFieldUpdate={handleFieldUpdate}
                />
                ))}
              </SpaceBetween>
            </Container>
          );
};

interface FieldInputProps {
    index: string;
    field: Field;
    specFields: SpecFields[];
    handleFieldUpdate: (updatedField: SpecFields[]) => void;
}

const FieldsInput: React.FC<FieldInputProps> = ({index, field, specFields, handleFieldUpdate}) => {
    const [value, setValue] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");

    const getConstraintText = (creationOfMPN: boolean) => {
        if (creationOfMPN == true) {
            return "This field cannot be empty"
        }
        return "";
    }

    const handleInputChange = (
        id: string,
        value: string,
        valueType: string,
    ) => {
        const existingIndex = specFields.findIndex((field) => field.id === id);

        if (existingIndex !== -1) {
            const updatedSpecFields = [...specFields];
            const existingField = updatedSpecFields[existingIndex];

            if (valueType === "value") {
                existingField.value = value;
            } else if (valueType === "minValue") {
                existingField.minValue = parseFloat(value);
            } else if (valueType === "maxValue") {
                existingField.maxValue = parseFloat(value);
            }

            handleFieldUpdate(updatedSpecFields);
        } else {
            const newSpecField: SpecFields = {
                id,
                value: valueType === "value" ? value : "",
                minValue: valueType === "minValue" ? parseFloat(value) : undefined,
                maxValue: valueType === "maxValue" ? parseFloat(value) : undefined,
            };

            handleFieldUpdate([...specFields, newSpecField]);
        }
    };

    console.log(field);

    return (
    <ColumnLayout columns={3} borders="vertical">
        <FormField key={field.id} label={field.label} description={field.unit}
            constraintText={getConstraintText(field.creationOfMPN || false)}
        >
        <Input
            onChange={({ detail }) => {
                setValue(detail.value);
                handleInputChange(field.id, detail.value, "value");
            }}
            value={value}
        />
        </FormField>

        <FormField key={field.id} label="Min value" description={field.unit}
            constraintText={getConstraintText(field.creationOfMPN || false)}
        >
        <Input
            onChange={({ detail }) => {
                setMinValue(detail.value);
                handleInputChange(field.id, detail.value, "minValue")
            }}
            value={minValue}
        />
        </FormField>

        <FormField key={field.id} label="Max value" description={field.unit}
            constraintText={getConstraintText(field.creationOfMPN || false)}
        >
        <Input
            onChange={({ detail }) => {
                setMaxValue(detail.value);
                handleInputChange(field.id, detail.value, "maxValue")
            }}
            value={maxValue}
        />
        </FormField>

    </ColumnLayout>
    
  )
}

