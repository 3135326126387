import React, {useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {
    BreadcrumbGroup,
    Button,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    Link,
    RadioGroup,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {addUsersBreadcrumbs} from "../common/breadcrumbs";
import {INTERNAL_USER_ROLE_OPTIONS, USER_COMPANY_OPTIONS, USER_COMPANY_OPTIONS_DISABLED} from "./config";
import {useLocation, useParams} from "react-router-dom";
import {UPDATE_USER} from "src/config/urlConfig";
import {createClient} from "src/client/AxiosClient";

interface UserDetailProps {
  activeStatus: string;
  email: string;
  id: string;
  name: string;
  roles: string[];
  userCompany: string;
  externalCompanyId: string;
  externalCompanyType: string;
}

const Breadcrumbs = () => (
    <BreadcrumbGroup items={addUsersBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const Content = (session: any) => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();

    const elementDetails = location.state as UserDetailProps;

    const selectedRole = INTERNAL_USER_ROLE_OPTIONS.filter(obj => obj.value ===  elementDetails.roles.join(', '))

    const [userName, setUserName] = useState(elementDetails.name);
    const [userEmail, setUserEmail] = useState(elementDetails.email);
    const [userCompany, setUserCompany] = useState(elementDetails.userCompany);
    const [userRole, setUserRole] = useState<string | null>(null);

    const client = createClient();

    const editUser = async () => {
      let roles: any[] = [];
      if (userCompany == 'EXTERNAL') {
          roles.push('SUPPLIER');

          const reqBody = {
            "name": userName,
            "userCompany": userCompany,
            "email": userEmail,
            "roles": roles,
            "id": elementDetails.id,
            "externalCompanyType": "SUPPLIER",
            "externalCompanyId": elementDetails.externalCompanyId,
        }
        const response = await client.put(UPDATE_USER.replace('{userCompany}', userCompany).replace('{id}', elementDetails.id), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        window.location.href = '/users';
        return;
      }

      
      if (userRole== 'ADMIN') {
        roles.push('ADMIN');
      } else if (userRole == 'ADMIN, PACKAGING_ENGINEER') {
        roles.push('ADMIN');
        roles.push('PACKAGING_ENGINEER');
      } else if (userRole == 'PACKAGING_ENGINEER') {
        roles.push('PACKAGING_ENGINEER');
      } else {
        roles.push('PROCUREMENT');
      }

      const reqBody = {
          "name": userName,
          "userCompany": userCompany,
          "email": userEmail,
          "roles": roles,
          "id": elementDetails.id,

      }
      const response = await client.put(UPDATE_USER.replace('{userCompany}', userCompany).replace('{id}', elementDetails.id), reqBody);
      if (response.data) {
          console.log(response.data);
      }
      else {
          console.log(response);
      }
      window.location.href = '/users';
      return;
    }

      return (
          <form onSubmit={event => event.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" href="/users">Cancel</Button>
                  <Button variant="primary" onClick={() => editUser()}>Save user</Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="l">
              <Container
                header={<Header 
                          variant="h2" 
                          description="Insert the information about the user">
                          User information
                      </Header>}
              >
                <SpaceBetween size="l">
                    <FormField 
                        label="Name" 
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }>
                      <Input onChange={({ detail }) => setUserName(detail.value)} value={userName}/>
                    </FormField>
                    <FormField 
                        label="E-mail address"
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                    >
                        <Input onChange={({ detail }) => setUserEmail(detail.value)} value={userEmail}/>
                    </FormField>
                    <FormField label="Company" stretch={true}
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                        >
                        <RadioGroup
                            items={USER_COMPANY_OPTIONS_DISABLED}
                            value={userCompany}
                        />
                    </FormField>
                </SpaceBetween>
              </Container>
              {(userCompany== "INTERNAL") && <Container
                header={<Header 
                  variant="h2" 
                  description="The permissions define what the user has access to">
                  Permissions
                  </Header>}
              >
                <FormField label="Role" stretch={true}
                  info={
                    <Link id="main-info-link" variant="info">
                      Info
                    </Link>
                  }
                  description="Select a role to set the permission"
                >
                    <RadioGroup
                            items={INTERNAL_USER_ROLE_OPTIONS}
                            value={userRole}
                            onChange={event => setUserRole(event.detail.value)}
                    />
                </FormField>
              </Container>}
              </SpaceBetween>
            </Form>
          </form>
        );
};

export class EditUser extends React.Component {
    render() {
        const mainAppProps = this.props;
        return (
            <body className='awsui-visual-refresh'>
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
              <GlobalNav  />
            </div>
            <CustomAppLayout
            headerSelector="#h"
            navigationOpen={false}
            content={
              <ContentLayout
                header={
                  <Header
                    data-custom-color="black"
                    variant="h1"
                    info={
                      <Link id="main-info-link" variant="info">
                        Info
                      </Link>
                    }
                    description="Adjust the information or permission for this user"
                  >
                    Edit user
                  </Header>
                }
              >
                <Content session={mainAppProps}/>
              </ContentLayout>
            }
            breadcrumbs={<Breadcrumbs />}
          />
          </body>
          );
        }
}
