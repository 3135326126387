import { Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, Form, FormField, Header, Input, Link, RadioGroup, SpaceBetween, Spinner, Textarea } from "@amzn/awsui-components-react";
import { editSupplierBreadcrumbs, supplierDetailsBreadcrumbs } from "src/pages/common/breadcrumbs";
import React, { createRef, useEffect, useState } from "react";
import { CustomAppLayout } from "src/pages/common/common-components";
import { SUPPLIER_POC_OPTIONS } from "./config";
import GlobalNav from "src/components/navigation/GlobalNav";
import { Navigation } from "../common/navigation";
import { useLocation, useParams } from "react-router-dom";
import { GET_SUPPLIER } from "src/config/urlConfig";
import { createClient } from "src/client/AxiosClient";
import { SupplierResponse } from "./Suppliers";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={supplierDetailsBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

interface SupplierDetailProps {
  id: string;
  name: string;
}

const Content = (props: any) => {

    const [supplierDetail, setSupplierDetail] = useState<SupplierResponse>();

    const url = window.location.href;

    let supplierId = "";
    const regex = /\/suppliers\/detail\/([\w-]+)/;
    const match = url.match(regex);
    if (match) {
      supplierId = match[1];
    }

    const client = createClient();

    const fetchSupplier = async () => {
      const response = await client.get(GET_SUPPLIER.replace('{id}', supplierId || ""));
      if (response.data) {
          console.log(response.data.supplier);
          setSupplierDetail(response.data.supplier);
      }
      else {
          console.log(response);
      }
      return;
    }

    useEffect(() => {
        const fetchSupplierDetail = async () => {
            await fetchSupplier();
        };
        fetchSupplierDetail();
    }, []);

    if (!supplierDetail) {
        return (
          <div>
            <Spinner />
          </div>
        )
    }
  
    return (
            <SpaceBetween size="l">
            <Container
              header={<Header 
                        variant="h2" 
                        description="General information about the supplier">
                        Supplier information
                    </Header>}
            >
              <SpaceBetween size="l">
                <ValueWithLabel label="Name">{supplierDetail?.name}</ValueWithLabel>
              </SpaceBetween>
            </Container>
            <Container
              header={<Header 
                variant="h2" 
                description="These users are associated with this supplier">
                Supplier POCs
                </Header>}
            >
              <SpaceBetween size="l">
                <ValueWithLabel label="Primary point of contact">{supplierDetail?.pocs?.[0] || "-"}</ValueWithLabel>
                <ValueWithLabel label="Other users">
                    {
                      supplierDetail.users.length == 0 && <div>-</div>
                    }
                    {supplierDetail.users.map((user, index) => (
                        <div key={index} className="user-details">
                          {user.name}
                        </div>
                      ))}
                </ValueWithLabel>
              </SpaceBetween>
            </Container>
            <Container
                header={<Header 
                  counter={"("+supplierDetail?.manufacturingSites.length+")"}
                  variant="h2" 
                  description="Manufacturing sites data is maintained by the supplier">
                Manufacturing Sites
                </Header>}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    {
                      supplierDetail.manufacturingSites.map((site, index) => (
                        <ValueWithLabel label={site.country}>{site.name}</ValueWithLabel>
                      ))
                    }
                </ColumnLayout>
            </Container>
            </SpaceBetween>
      );
};

export class SupplierDetail extends React.Component {
    render() {
      return (
        <body className='awsui-visual-refresh'>
        <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <GlobalNav  />
        </div>
        <CustomAppLayout
        content={
          <ContentLayout
            header={
              <Header
                variant="h1"
                info={
                  <Link id="main-info-link" variant="info">
                    Info
                  </Link>
                }
                description="Suppliers are listed as companies for the user"
              >
                Supplier Details
              </Header>
            }
          >
            <Content />
          </ContentLayout>
        }
        breadcrumbs={<Breadcrumbs />}
        headerSelector="#h"
        navigation={<Navigation activeHref="#/suppliers" />}
        navigationOpen={false}
      />
      </body>
      );
    }
}