import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, Button, Container, ColumnLayout, ContentLayout, Header, Link, SpaceBetween, StatusIndicator, Table } from "@amzn/awsui-components-react";
import { OPEN_TASKS_SUPPLIER_COLUMN_DEFINITION, OPEN_TASKS_SUPPLIER_COLUMN_DISPLAY } from "../common/table-config";
import { createClient } from "src/client/AxiosClient";
import { GET_OPEN_TASKS } from "src/config/urlConfig";
import { OpenTasks, TaskJobData } from "../common/types";
import { useNavigate } from "react-router-dom";
import { getBarcode, getDataType, getJobId, getJobType } from "../common/utils";
import packaging_repository from 'src/images/packaging_repository.png';


const SupplierHomeContent = (props: any) => {
    const [
        selectedTaskItems,
        setSelectedTaskItems
      ] = useState<any>();

    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<TaskJobData[]>([]);

    const client = createClient();

    const navigate = useNavigate();

    function handleEdit() {
        if (selectedTaskItems[0].dataType == "SPECS") {
            if (selectedTaskItems[0].requiredAction == "RESUBMIT_SPECS") {
                navigate('/supplier/resubmit/task/'+selectedTaskItems[0].id+'/specs/'+selectedTaskItems[0].jobId+'/barcode/'+selectedTaskItems[0].barcode)
                return;
            }
            navigate('/supplier/providedata/task/'+selectedTaskItems[0].id+'/specs/'+selectedTaskItems[0].jobId+'/barcode/'+selectedTaskItems[0].barcode);
            return;
        }
        if (selectedTaskItems[0].dataType == "TECH_DRAWING") {
            navigate('/supplier/providedata/task/'+selectedTaskItems[0].id+'/technicaldrawing/'+selectedTaskItems[0].jobId+'/barcode/'+selectedTaskItems[0].barcode);
        }
        if (selectedTaskItems[0].dataType == "ARTWORK") {
            navigate('/supplier/providedata/task/'+selectedTaskItems[0].id+'/artwork/'+selectedTaskItems[0].jobId+'/barcode/'+selectedTaskItems[0].barcode);
        }
    }

    function handleReassign() {

    }

    const fetchAllTasks = async () => {
        const response = await client.get(GET_OPEN_TASKS);
        if (response.data) {
            console.log(response);
            const data: OpenTasks[] = response.data.openTasks;

            const parsedData = data.map(item => ({
                id: item.id,
                jobType: getJobType(item.reference),
                dataType: getDataType(item.reference),
                jobId: getJobId(item.referenceId),
                barcode: getBarcode(item.referenceId),
                requiredAction: item.requiredAction,
            }));

            setTableItems(parsedData);
        
            return response.data.openTasks;
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchTaskList = async () => {
            await fetchAllTasks();
            setTableLoading(false);
        };
        fetchTaskList()
    }, []);
      
    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
            <Table
                onSelectionChange={({ detail }) =>
                setSelectedTaskItems(detail.selectedItems)
                }
                selectedItems={selectedTaskItems}
                ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
                }}
                columnDefinitions={OPEN_TASKS_SUPPLIER_COLUMN_DEFINITION}
                columnDisplay={OPEN_TASKS_SUPPLIER_COLUMN_DISPLAY}
                items = {tableItems}
                loading={tableLoading}
                loadingText="Loading resources"
                selectionType="single"
                trackBy="id"
                header={
                <Header
                    counter={"("+tableItems.length+")"}
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h2"
                    actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="xs"
                    >
                        <Button onClick={handleEdit}>Edit</Button>
                        {/* <Button onClick={handleReassign}>Reassign</Button> */}
                    </SpaceBetween>
                    }
                    description="Always complete your open tasks first"
                >
                    Open tasks
                </Header>
                }
            />
            <div>
            <ColumnLayout columns={3}>
                <div>
                <Container
                    media={{
                        content: (
                        <img
                            src={packaging_repository}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packages">Packaging Repository</Link></Box>
                        <Box color="text-body-secondary">View all final packaging data including barcodes</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
            </div>
            </ColumnLayout>
            </div>
            </SpaceBetween>
        </div>
    )
}

export class SupplierHome extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationHide={true}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            data-custom-color="black"
                            variant="awsui-h1-sticky"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                            description="Tool to help you manage your packaging jobs and data"
                        >
                            Amazon Packaging Portal
                        </Header>
                        }
                    >
                        <SupplierHomeContent />
                    </ContentLayout>
                    }
                />
            </body>
        )
    }
}