import { Container, Header, Button, Select, SpaceBetween, FormField } from "@amzn/awsui-components-react";
import { useState, useEffect } from "react";
import { createClient } from "src/client/AxiosClient";
import { GET_SUPPLIER_BARCODES, GET_SUPPLIER_LIST } from "src/config/urlConfig";
import { SupplierResponse } from "../steps/BasicInput";
import { RevisionJob, RevisionJobProps, RevisionPackage } from "../Revision";



const AddPackageContainer = ({ supplier, onPackageChange, onRemove, index }: { supplier: string, onPackageChange: (revisionPackage: RevisionPackage, index: number) => void, onRemove: (index: number) => void, index: number }) => {

    const client = createClient();

    const [barcodeOptions, setBarcodeOptions] = useState<any[]>([]);

    const [selectedOption, setSelectedOption] = useState<any>({});

    const [selectedBarcode, setSelectedBarcode] = useState<string>();
    const [selectedMPN, setSelectedMPN] = useState<string>();
    const [selectedMPNId, setSelectedMPNId] = useState<string>();

    const fetchSupplierBarcodes = async() => {
        const response = await client.get(GET_SUPPLIER_BARCODES.replace("{supplierId}", supplier));
        if (response.data) {
          console.log(response.data);
          const parsedData = response.data.barcodes.map(item => ({
            label: item.barcode,
            value: item.barcode,
            tags: [item.masterPackName],
            description: item.masterPackNameId,
          }));
          setBarcodeOptions(parsedData);
        }
        else {
          console.log(response);
        }
    }

    useEffect(() => {
      const fetchBarcodes = async () => {
          await fetchSupplierBarcodes();
      };
      fetchBarcodes()
    }, []);

    const handleRevisionDataChange = () => {
      const revisionPackage: RevisionPackage = {
          barcode: selectedBarcode || "",
          masterPackName: selectedMPN || "",
          masterPackNameId: selectedMPNId || "",
      };
      onPackageChange(revisionPackage, index);
    };

    return (
        <Container
          header={
            <Header actions={
              <Button disabled={(index === 0)} onClick={() => onRemove(index)}>
                  Remove
              </Button>
            }>
              Barcode
            </Header>
          }
        >
            <Select 
              selectedOption={selectedOption}
              onChange={({ detail }) =>
                {
                  setSelectedOption(detail.selectedOption);
                  setSelectedBarcode(detail.selectedOption.value);
                  setSelectedMPN(detail.selectedOption.tags? detail.selectedOption.tags[0] : "");
                  setSelectedMPNId(detail.selectedOption.description);
                }
              }
              options={barcodeOptions}
              onBlur={handleRevisionDataChange}
            />
        </Container>
    )
}

interface Option {
  label: string;
  value: string;
}

export const RevisionItems: React.FC<RevisionJobProps> = ({job, onJobDetailsChange})=> {

    const client = createClient();

    const [supplierItems, setSupplierItems] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>();

    const [packages, setPackages] = useState<RevisionPackage[]>([]);

    const fetchSuppliers = async () => {
      const response = await client.get(GET_SUPPLIER_LIST);
      if (response.data) {
        const data: SupplierResponse[] = response.data.suppliers;
        const parsedData = data.map(item => ({
            label: item.name,
            value: item.id,
        }));

        setSupplierItems(parsedData);
        setSelectedOption(parsedData[0]);
    
        return response.data.suppliers;
      }
      else {
        console.log(response);
      }
      return [];
    }

    useEffect(() => {
      const fetchSupplierList = async () => {
          await fetchSuppliers();
      };
      fetchSupplierList()
    }, []);

    const handlePackageChange = (revisionPackage: RevisionPackage, index: number) => {
        const updatedPackages = [...packages];
        updatedPackages[index] = revisionPackage;
        setPackages(updatedPackages);
        handleJobDetailsChange({
            barcodes: updatedPackages
        })
    };

    const handleAddPackaging = () => {
      setPackages([...packages, { barcode: '', masterPackNameId: '', masterPackName: ''}]);
    };

    const handleRemovePackage = (indexToRemove: number) => {
      const updatedPackages = packages.filter((_, index) => index !== indexToRemove);
      setPackages(updatedPackages);
    };

    const revisionPackageContainers = packages.map((revisionPackage, index) => (
      <AddPackageContainer key={index} supplier={selectedOption?.value} onPackageChange={handlePackageChange} onRemove={handleRemovePackage} index={index} />
    ));

    const handleJobDetailsChange = (updatedDetails: Partial<RevisionJob>) => {
      onJobDetailsChange(updatedDetails);
    }

    return (
      <div>
        <SpaceBetween direction="vertical" size="xl">
          <Container
            header={
              <Header>
                Supplier
              </Header>
            }
          >
              <FormField label="Supplier">
                <SpaceBetween direction="vertical" size="s">
                    <Select
                        selectedOption={selectedOption}
                        onChange={({ detail }) =>
                            {
                                setSelectedOption(detail.selectedOption);
                                handleJobDetailsChange({
                                  supplierId: detail.selectedOption.value,
                                  supplierName: detail.selectedOption.label,
                                });
                            }
                        }
                        options={supplierItems}
                    />
                </SpaceBetween>
                </FormField>
          </Container>

          <Header
            variant="h2"
            actions={
                <Button onClick={handleAddPackaging}>Add Packaging</Button>
            }
            description="Select the packaging from repository that needs revision"
            >
            Packaging
          </Header>
          
          <div>
            <SpaceBetween size="l" direction="vertical">
            {revisionPackageContainers}
            </SpaceBetween>
          </div>
          </SpaceBetween>
      </div>
    )
}