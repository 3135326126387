import { Box } from "@amzn/awsui-components-react";

export interface OpenTasks {
    id: string;
    reference: string;
    referenceId: string;
    requiredAction: string;
}

export interface TaskJobData {
    id: string;
    jobType: string;
    dataType: string;
    jobId: string;
    barcode: string;
    requiredAction: string;
}

export interface PackageShort {
    id: string;
    barcode: string;
    masterPackName: string;
    packType: string;
    subPackType: string;
    supplierName: string;
    siteCount: number;
}

export interface SupplierDetail {
    id: string;
    name: string;
    activeStatus: string;
    users: any[];
    pocs: any[];
    manufacturingSites: any[];
}

export const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);