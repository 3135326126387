import { Header } from "@amzn/awsui-components-react";
import LargeSpinner from "src/components/LargeSpinner";
import React from "react";

export class LandingPage extends React.Component {
    render() {
        return (
            <body className="awsui-visual-refresh">
            <div>
                <Header variant="awsui-h1-sticky">
                    Amazon Packaging Portal
                </Header>
                <LargeSpinner />
                loading, please wait
            </div>
            </body>
        )
    }
}