export const SUPPLIER_POC_OPTIONS = [
    { label: 'Super Mega Long User Name', value: '0' },
    { label: 'Super Mega Long User Name', value: '1' },
    { label: 'Super Mega Long User Name', value: '2' },
    { label: 'Super Mega Long User Name', value: '3' },
    { label: 'Super Mega Long User Name', value: '4' },
];

export const INTERNAL_USER_ROLE_OPTIONS = [
    { label: 'Packaging Engineer', value: 'PACKAGING_ENGINEER'},
    { label: 'Admin & Packaging Engineer', value: 'ADMIN, PACKAGING_ENGINEER' },
    { label: 'Admin', value: 'ADMIN' },
    //{ label: 'Procurement', value: 'PROCUREMENT' },               //Procurement not needed now
];

export const EXTERNAL_USER_ROLE_OPTIONS = [
    { label: 'Supplier', value: 'SUPPLIER'},
    { label: 'Agency', value: 'AGENCY' },
];

export const USER_COMPANY_OPTIONS = [
    { label: 'Internal', value: 'INTERNAL', description: 'Select for any internal user'},
    { 
        label: 'External', 
        value: 'EXTERNAL', 
        description: 'Select for external supplier or agency',
    },
];

export const USER_COMPANY_OPTIONS_DISABLED = [
    { label: 'Internal', value: '0', description: 'Select for any internal user', disabled: true },
    { label: 'External', value: '1', description: 'Select for external supplier or agency', disabled: true },
];

