import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Alert, Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Input, Link, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { supplierProvideTechnicalDataBreadcrumbs } from "../common/breadcrumbs";
import { JobDetails } from "../jobs/JobDetail";
import { PackagingBarcode } from "../pe/NewPackaging";
import { GET_PACKAGING_JOB_DETAIL, GET_PACK_NAME, GET_SUPPLIER, POST_PACKAGING_SPECS, POST_PACKAGING_SPECS_FILE, SUBMIT_SPECS } from "src/config/urlConfig";
import { createClient } from "src/client/AxiosClient";
import { SupplierResponse } from "../admin/Suppliers";
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={supplierProvideTechnicalDataBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export interface ManufacturingSite {
    id: string;
    name: string;
    country: string;
}

interface SelectedMS {
    label: string;
    value: string;
    description: string;
}

interface Parameters {
    id: string;
    value: string;
    minValue?: number;
    maxValue?: number;

    label?: string;
    unit?: string;
    category?: string;
    creationOfMPN?: boolean;
    filledBySupplier?: boolean;
    supplierUI?: boolean;
}

interface FieldVersion {
    1: Parameters[];
}

interface SpecFields {
    approvedVersion: string;
    draftVersion: string;
    versions: FieldVersion;
}

export const ProvideSpecsContent = () => {

    const [siteDetails, setSiteDetails] = useState<SelectedMS[]>([]);

    const location = useLocation();
  
    const searchParams = new URLSearchParams(location.search);
    const serializedState = searchParams.get('state');

    const [specInputValues, setSpecInputValues] = useState({});
    const [inputErrors, setInputErrors] = useState({});

    const [fileValue, setFileValue] = React.useState<File[]>([]);

    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    const [currentBarcode, setCurrentBarcode] = useState<PackagingBarcode>();
    const [mansites, setManSites] = useState<ManufacturingSite>();

    const [additionalFileUploaded, setAdditionalFileUploaded] = useState(false);

    const [mpnFields, setMpnFields] = useState<SpecFields>();

    const [markedFields, setMarkedFields] = useState<string[]>([]);

    const [rejectComment, setRejectComment] = useState("");

    const client = createClient();

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/supplier\/resubmit\/task\/[^/]+\/specs\/([^/]+)\/barcode\/[^/]+/);
    
    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    let barcode = "";
    const barcodepattern: RegExp = /\/barcode\/([^/#?]+)/;
    const barcodeMatch: RegExpMatchArray | null = url.match(barcodepattern);

    if (barcodeMatch) {
        barcode = barcodeMatch[1];
    }

    const sitepattern: RegExp = /#([a-fA-F0-9-]+)$/;
        const siteMatch: RegExpMatchArray | null = url.match(sitepattern);
        let siteId = ""
        if (siteMatch) {
            siteId = siteMatch[1];
        }

    const extractJobBarcode = () => {
        if (packagingJob) {
            for (const bc of packagingJob?.barcodes) {
                if (bc.barcode == barcode) {
                    setCurrentBarcode(bc);
                }
            }
        }
    }

    const handleInputChange = (fieldId, value) => {
        const error = validateInput(fieldId, value);
        setInputErrors(prevErrors => ({
            ...prevErrors,
            [fieldId]: error,
        }));
        setSpecInputValues(prevValues => ({
            ...prevValues,
            [fieldId]: value,
        }));
    };

    const fetchPackName = async (mpnId: string) => {
        const response = await client.get(GET_PACK_NAME.replace("{id}", mpnId));
        if (response.data) {
            setMpnFields(response.data.masterPackName.specFields);

        }
        console.log(response.data);
    }

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data.packageJob);
            await fetchPackName(response.data.packageJob.barcodes[0].masterPackNameId);
            const siteinfo = response.data.packageJob.barcodes[0].manufacturingSites.filter((obj) => obj.id == siteId);
            console.log(siteinfo);
            setMarkedFields(siteinfo[0].specFields.markedFields);
            setRejectComment(siteinfo[0].specFields.comments[0]);
            
            const fields = siteinfo[0].specFields.fields;
            const updatedSpecInputValues = { ...specInputValues };

            fields.forEach(field => {
                updatedSpecInputValues[field.id] = field.value;
            });

            setSpecInputValues(updatedSpecInputValues);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchSuppliers = async () => {
        const response = await client.get(GET_SUPPLIER);
        if (response.data) {
            const data: any = response.data.supplier;
            console.log(data);
            setManSites(data.manufacturingSites);
            return;
        }
        else {
          console.log(response);
        }
        return [];
    }

    const navigate = useNavigate();

    const saveSpecs = async() => {

        const reqBody = {
            specFields: Object.entries(specInputValues).map(([id, value]) => ({
                id,
                value,
            })),
        };
        
        const response = await client.post(POST_PACKAGING_SPECS.replace("{id}", id).replace("{barcode}", barcode).replace("{manufacturingSiteId}", siteId), reqBody)

        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }

        const taskIdMatch: RegExpMatchArray | null = url.match(/\/supplier\/resubmit\/task\/([^/]+)\/specs\/[^/]+\/barcode\/[^/]+/);

        let taskId = "";
        if (taskIdMatch) {
            taskId = taskIdMatch[1];
        }

        const submitreqBody = {
            "openTaskId": taskId
        }
        console.log(submitreqBody);

        const submitresponse = await client.put(SUBMIT_SPECS.replace("{id}", id), submitreqBody);

        if (submitresponse.data) {
            console.log(submitresponse.data);
        }
        else {
            console.log(submitresponse);
        }
        
        navigate('/home');
    }

    const [showFileSuccessAlert, setShowFileSuccessAlert] = useState(false);

    const uploadFile = async() => {
        let allFilesUploadedSuccessfully = true;
        if (fileValue && fileValue[0]) {
            try {
                const formData = new FormData();
                formData.append('additionalFile', fileValue[0]);
        
                for (const site of siteDetails) {
                    const response = await client.post((POST_PACKAGING_SPECS_FILE.replace("{id}", id).replace("{barcode}", barcode).replace("{manufacturingSiteId}", site.value))+"/?fileName=specFile.pdf", formData);
            
                    if (!(response.status == 204)) {
                      console.log(`Error uploading file for site ID: ${site.value}`);
                      console.log(response);
                      allFilesUploadedSuccessfully = false; // Update flag if any upload fails
                    }
                  }
            
                  if (allFilesUploadedSuccessfully) {
                    setShowFileSuccessAlert(true); // Trigger success alert if all uploads succeeded
                    setAdditionalFileUploaded(true);
                  }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    }

    const validateInput = (specId: string, specValue: string) => {
        const filteredObjects = mpnFields?.versions["1"].filter(obj => obj.id === specId);
        if (filteredObjects && filteredObjects.length > 0) {
            const maxValue = filteredObjects[0]?.maxValue ?? 99999;
            if (!isNaN(parseFloat(specValue)) && parseFloat(specValue) > maxValue) {
                return ("Value is greater than maxvalue: " + filteredObjects[0]?.maxValue);
            }

            const minValue = filteredObjects[0]?.minValue ?? 0;
            if (!isNaN(parseFloat(specValue)) && parseFloat(specValue) < minValue) {
                return ("Value is lesser than minvalue: " + filteredObjects[0]?.minValue);
            }
        }
        return "";
    }

    useEffect(() => {
        const fetchJobs = async () => {
            await fetchJobDetail();
            extractJobBarcode();
        };
        fetchJobs();
           
    }, []);

    if (!packagingJob || !specInputValues) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }

    return (
        <div>
            <SpaceBetween size="l" direction="vertical">
            <Container
                header={
                    <Header variant="h2">
                        {packagingJob.barcodes[0].masterPackName}
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel label="Barcode">
                        {packagingJob.barcodes[0].barcode}
                    </ValueWithLabel>
                    <ValueWithLabel label="Manufacturing sites">
                        {siteDetails.map(site => site.label).join(', ')}
                    </ValueWithLabel>
                    <ValueWithLabel label="Comment from PE">
                        {rejectComment}
                    </ValueWithLabel>
                </SpaceBetween>
            </Container>

            <Container
                header={
                    <Header variant="h2">
                        Technical parameters
                    </Header>
                }
            >
                <ColumnLayout columns={3} borders="vertical">
                        {mpnFields?.versions["1"].map(field => (
                        <div key={field.id}>
                            <FormField
                                label={field.label ?? field.id}
                                description={
                                    <div>
                                    <div>{field.unit ?? ""}</div>
                                    {markedFields.includes(field.id) && 
                                    <div>
                                        The submitted value for this field was rejected by PE
                                    </div>}
                                    </div>
                                }
                                errorText={inputErrors[field.id] ?? ""}
                            >
                                <Input
                                    onChange={({ detail }) => handleInputChange(field.id, detail.value)}
                                    value={specInputValues[field.id] ?? ""}
                                />
                            </FormField>
                        </div>
                    ))}
                </ColumnLayout>
            </Container>

            <Container
                header={
                    <Header variant="h2"
                        actions={
                            <Button onClick={uploadFile} disabled={additionalFileUploaded}>Upload</Button>
                        }
                    >
                        File upload (optional)
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="m">
                <FormField
                    label="Additional information"
                    description="Attach a file with additional information, if needed"
                    >
                    <FileUpload
                        onChange={({ detail }) => setFileValue(detail.value as File[])}
                        value={fileValue}
                        i18nStrings={{
                        uploadButtonText: e =>
                            e ? "Choose files" : "Choose file",
                        dropzoneText: e =>
                            e
                            ? "Drop files to upload"
                            : "Drop file to upload",
                        removeFileAriaLabel: e =>
                            `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error"
                        }}
                        showFileLastModified
                        showFileSize
                        showFileThumbnail
                        tokenLimit={3}
                        constraintText="Upload a PDF file"
                    />
                </FormField>
                {showFileSuccessAlert && <Alert 
                    dismissible
                    statusIconAriaLabel="Success"
                    type="success">
                    File uploaded successfully!
                </Alert>}
                </SpaceBetween>
            </Container>
            
            <div style={{width: '100%'}}>
                    <div style={{float: 'right'}}>
                        <SpaceBetween size="s" direction="horizontal" >
                            <Button>Cancel</Button>
                            <Button variant="primary" onClick={saveSpecs}>Save specs</Button>
                        </SpaceBetween>
                    </div>
            </div>
            </SpaceBetween>
        </div>
    )
}
  
export class ResubmitSpecsEditor extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                navigationHide={true}
                content={
                <ContentLayout
                    header={
                    <Header
                        data-custom-color="black"
                        variant="awsui-h1-sticky"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Fill out the needed information for the job"
                    >
                        Specs
                    </Header>
                    }
                >
                    <ProvideSpecsContent />
                </ContentLayout>
                }
            />
            </body>
        )
    }
}