import React, { useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Navigation } from "../common/navigation";
import { BreadcrumbGroup, Wizard } from "@amzn/awsui-components-react";
import { addPackNameBreadcrumbs } from "../common/breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { BasicInformation } from "./packNameSteps/BasicInformation";
import { MasterSpecs } from "./packNameSteps/MasterSpecs";
import { MasterFiles } from "./packNameSteps/MasterFiles";
import { Approval } from "./packNameSteps/Approval";
import { createClient} from "src/client/AxiosClient";
import { CREATE_APPROVAL_REQUEST, CREATE_PACK_NAME, PUT_PACK_NAME_SPECS } from "src/config/urlConfig";
import { PackNamesResponse } from "./PackNames";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={addPackNameBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface SpecFields {
    id: string;
    value: string;
    minValue?: number;
    maxValue?: number;
    label?: string;
    unit?: string;
    category?: string;
    creationOfMPN?: boolean;
    filledBySupplier?: boolean;
    supplierUI?: boolean;
}

export const PackNameWizard = (props: any) => {
    const {step} = useParams();

    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);

    const getActiveStepIndex = () => {
        return activeStepIndex;
    }

    const [packNameDetail, setPackNameDetail] = useState<PackNamesResponse>({
        approvalStatus: "",
        id: "",
        name: "",
        packTypeId: "",
        packTypeName: "",
        productTypeId: "",
        productTypeName: "",
        subPackTypeId: "",
        subPackTypeName: ""
    });

    const [packNameSpecs, setPackNameSpecs] = useState<SpecFields[]>();

    const [packNameApprovalRequestId, setPackNameApprovalRequestId] = useState<string>();

    const handlePackNameDetailChange = (updatedDetails: Partial<PackNamesResponse>) => {
        setPackNameDetail({
            approvalStatus: updatedDetails.approvalStatus || packNameDetail.approvalStatus,
            id: updatedDetails.id || packNameDetail.id,
            name: updatedDetails.name || packNameDetail.name,
            packTypeId: updatedDetails.packTypeId || packNameDetail.packTypeId,
            packTypeName: updatedDetails.packTypeName || packNameDetail.packTypeName,
            productTypeId: updatedDetails.productTypeId || packNameDetail.productTypeId,
            productTypeName: updatedDetails.productTypeName || packNameDetail.productTypeName,
            subPackTypeId: updatedDetails.subPackTypeId || packNameDetail.subPackTypeId,
            subPackTypeName: updatedDetails.subPackTypeName || packNameDetail.subPackTypeName
        });
    }

    const handlePackNameSpecsChange = (updatedSpecs: SpecFields[]) => {
        setPackNameSpecs(updatedSpecs);
    }

    const handlePackNameApprovalRequestIdChange = (updatedId: string) => {
        setPackNameApprovalRequestId(updatedId);
    }

    const navigate = useNavigate();
    const client = createClient();

    const saveInitialPackNameData = async () => {
        
        const reqBody = {
            "name": packNameDetail.name,
            "subPackTypeId": packNameDetail.subPackTypeId,
        }
        const response = await client.post(CREATE_PACK_NAME, reqBody);
        if (response.data) {
            console.log(response.data);
            setPackNameDetail(response.data.masterPackName);
        }
        else {
            console.log(response);
        }
        return;
    }

    const savePackNameSpecs = async () => {
        const reqBody = {
          "specFields": packNameSpecs,
        }
        const response = await client.put(PUT_PACK_NAME_SPECS.replace('{id}', packNameDetail.id), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    const sendApprovalRequest = async() => {
        const reqBody = {
            "invitedUserId": packNameApprovalRequestId,
        }

        const response = await client.post(CREATE_APPROVAL_REQUEST.replace("{id}", packNameDetail.id), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

      return (
        <div>
        <Wizard
          i18nStrings={{
            stepNumberLabel: stepNumber =>
              `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            navigationAriaLabel: "Steps",
            cancelButton: "Cancel",
            previousButton: "Previous",
            nextButton: "Next",
            submitButton: "Request approval",
            optional: "optional"
          }}
          onNavigate={async ({ detail }) =>
              {
                  if (activeStepIndex == 0 && detail.requestedStepIndex == 1) {
                      await saveInitialPackNameData();
                      setActiveStepIndex(detail.requestedStepIndex);
                      return;
                  }
                  if (activeStepIndex == 1 && detail.requestedStepIndex == 2) {
                      await savePackNameSpecs();
                      setActiveStepIndex(detail.requestedStepIndex);
                      return;
                  }
                  setActiveStepIndex(detail.requestedStepIndex);
              }
          }
          onSubmit={() => {
            sendApprovalRequest();
            navigate('/packnames');
          }}
          onCancel={() => {
            navigate('/packnames');
          }}
          activeStepIndex={getActiveStepIndex()}
          steps={[
            {
              title: "Basic Information",
              content: <BasicInformation packNameDetail={packNameDetail} onPackNameDetailChange={handlePackNameDetailChange} />,
            },
            {
              title: "Master Specs",
              content: <MasterSpecs packNameDetail={packNameDetail} onPackNameSpecsChange={handlePackNameSpecsChange}/>
            },
            {
              title: "Master Files",
              content: <MasterFiles packNameDetail={packNameDetail}/>,
            },
            {
              title: "Approval",
              content: <Approval packNameDetail={packNameDetail} onPackNameApprovalChange={handlePackNameApprovalRequestIdChange}/>,
            }
          ]}
        />
        </div>
      );
}

export class AddPackName extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                    contentType="table"
                    content={
                      <PackNameWizard />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigationOpen={false}
                    navigation={<Navigation activeHref="/packnames" />}
                />
            </body>
        )
    }
}