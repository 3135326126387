import {
    Box,
    BreadcrumbGroup,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    Link,
    RadioGroup,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import {editSupplierBreadcrumbs} from "src/pages/common/breadcrumbs";
import React, {useEffect, useState} from "react";
import {CustomAppLayout} from "src/pages/common/common-components";
import {SUPPLIER_POC_OPTIONS} from "./config";
import GlobalNav from "src/components/navigation/GlobalNav";
import {GET_SUPPLIER, UPDATE_SUPPLIER} from "src/config/urlConfig";
import {useLocation, useParams} from "react-router-dom";
import {createClient} from "src/client/AxiosClient";
import { SupplierResponse } from "./Suppliers";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={editSupplierBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

interface SupplierDetailProps {
  id: string;
  name: string;
}

const Content = (session: any) => {

  const [supplierName, setSupplierName] = useState("");

  const [supplierDetail, setSupplierDetail] = useState<SupplierResponse>();

    const url = window.location.href;

  const client = createClient();

  let supplierId = "";
    const regex = /\/suppliers\/edit\/([\w-]+)/;
    const match = url.match(regex);
    if (match) {
      supplierId = match[1];
    }

    const updateSupplier = async () => {
        const reqBody = {
          "id": supplierId,
          "name": supplierName
        }
        const response = await client.put(UPDATE_SUPPLIER.replace('{id}', supplierId), reqBody);
        if (response.data) {
          console.log(response.data);
        }
        else {
          console.log(response);
        }
        window.location.href = '/suppliers';
        return;
    }

    const fetchSupplier = async () => {
      const response = await client.get(GET_SUPPLIER.replace('{id}', supplierId || ""));
      if (response.data) {
          console.log(response.data.supplier);
          setSupplierName(response.data.supplier.name);
          setSupplierDetail(response.data.supplier);
      }
      else {
          console.log(response);
      }
      return;
    }

    useEffect(() => {
        const fetchSupplierDetail = async () => {
            await fetchSupplier();
        };
        fetchSupplierDetail();
    }, []);

    if (!supplierDetail) {
        return (
          <div>
            <Spinner />
          </div>
        )
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" href="/suppliers">Cancel</Button>
                <Button variant="primary" onClick={() => updateSupplier()}>Save supplier</Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween size="l">
            <Container
              header={<Header
                        variant="h2"
                        description="Insert the general information about the supplier">
                        Supplier information
                    </Header>}
            >
              <SpaceBetween size="l">
                <FormField
                  label="Name"
                  info={
                    <Link id="main-info-link" variant="info">
                      Info
                    </Link>
                  }>
                    <Input onChange={({ detail }) => setSupplierName(detail.value)} value={supplierName}/>
                </FormField>
              </SpaceBetween>
            </Container>
            <Container
              header={<Header
                variant="h2"
                description="These users are associated with this supplier">
                Supplier POCs
                </Header>}
            >
              <SpaceBetween size="l">
                <ValueWithLabel label="Primary point of contact">{supplierDetail?.pocs?.[0] || "-"}</ValueWithLabel>
                <ValueWithLabel label="Other users">
                    {
                      supplierDetail.users.length == 0 && <div>-</div>
                    }
                    {supplierDetail.users.map((user, index) => (
                        <div key={index} className="user-details">
                          {user.name}
                        </div>
                      ))}
                </ValueWithLabel>
              </SpaceBetween>
            </Container>
            <Container
                header={<Header 
                  counter={"("+supplierDetail?.manufacturingSites.length+")"}
                  variant="h2" 
                  description="Manufacturing sites data is maintained by the supplier">
                Manufacturing Sites
                </Header>}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    {
                      supplierDetail.manufacturingSites.map((site, index) => (
                        <ValueWithLabel label={site.country}>{site.name}</ValueWithLabel>
                      ))
                    }
                </ColumnLayout>
            </Container>
            </SpaceBetween>
          </Form>
        </form>
      );
};

export class EditSupplier extends React.Component {
    render() {
      const mainAppProps = this.props;
      return (
        <body className='awsui-visual-refresh'>
        <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <GlobalNav  />
        </div>
        <CustomAppLayout
        headerSelector="#h"
        navigationOpen={false}
        content={
          <ContentLayout
            header={
              <Header
              data-custom-color="black"
                variant="h1"
                info={
                  <Link id="main-info-link" variant="info">
                    Info
                  </Link>
                }
                description="Suppliers are listed as companies for the user"
              >
                Edit Supplier
              </Header>
            }
          >
            <Content session={mainAppProps}/>
          </ContentLayout>
        }
        breadcrumbs={<Breadcrumbs />}
      />
      </body>
      );
    }
}