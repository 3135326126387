import React, {useEffect, useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {Navigation} from "../common/navigation";
import {
    Box,
    BreadcrumbGroup,
    Button,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";
import {producttypesBreadcrumbs} from "../common/breadcrumbs";
import {PRODUCT_TYPE_TABLE_COLUMN_DEFINITION, PRODUCT_TYPE_TABLE_COLUMN_DISPLAY} from "../common/table-config";
import {GET_PRODUCT_TYPE} from "src/config/urlConfig";
import {useNavigate} from 'react-router-dom';
import {createClient} from "src/client/AxiosClient";


const Breadcrumbs = () => (
    <BreadcrumbGroup items={producttypesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface ProductTypeResponse {
    id: string;
    name: string;
    jobSteps: any[];
}

interface ProductTypeRow {
    id: string;
    name: string;
    steps: number;
}

const ProductTypeTable = (session: any) => {
    const [
      selectedItems,
      setSelectedItems
    ] = React.useState<any[]>([]);
    const [
        filteringText,
        setFilteringText
      ] = React.useState("");

    const [rawProductTypesData, setRawProductTypesData] = React.useState<ProductTypeResponse[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<ProductTypeRow[]>([]);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const client = createClient();

    const fetchProductTypes = async () => {
        const response = await client.get(GET_PRODUCT_TYPE);
        if (response.data) {
            console.log(response.data.productTypes);
            const data: ProductTypeResponse[] = response.data.productTypes;
            const parsedData = data.map(item => ({
                id: item.id,
                name: item.name,
                steps: item.jobSteps.length,
            }));

            setTableItems(parsedData);
        
            return response.data.productTypes;
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchProductTypesList = async () => {
            setRawProductTypesData(await fetchProductTypes());
            setTableLoading(false);
        };
        fetchProductTypesList()
    }, []);

    const navigate= useNavigate();

    const handleDetailsClick = () => {
      const selectedId = selectedItems[0].id;
      const filteredArray = rawProductTypesData.filter(obj => obj.id === selectedId);
      navigate('/producttypes/detail/'+selectedId, {state: filteredArray[0]})
    }

    const handleEditClick = () => {
      const selectedId = selectedItems[0].id;
      const filteredArray = rawProductTypesData.filter(obj => obj.id === selectedId);
      navigate('/producttypes/edit/'+selectedId, {state: filteredArray[0]})
    }
    
    return (
      <div>
      <Table
        onSelectionChange={({ detail }) =>
          {
            setSelectedItems(detail.selectedItems);
            setActionButtonDisabled(false);
          }
        }
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item) =>
            item.name
        }}
        columnDefinitions={PRODUCT_TYPE_TABLE_COLUMN_DEFINITION}
        columnDisplay={PRODUCT_TYPE_TABLE_COLUMN_DISPLAY}
        items = {tableItems}
        loading={tableLoading}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
            </SpaceBetween>
          </Box>
        }
        // TODO: Filtering is a very complex functionality. Take time to understand what works best for APP
        filter={
          <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Find product type"
            filteringAriaLabel="Filter product type"
            onChange={({ detail }) =>
                setFilteringText(detail.filteringText)
            }
          />
        }
        header={
          <Header
            counter={"(" + tableItems.length + ")"}
            info={
                <Link id="main-info-link" variant="info">
                  Info
                </Link>
            }
            variant="h1"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <Button onClick={() => handleDetailsClick()} disabled={actionButtonDisabled}>View Details</Button>
                <Button onClick={() => handleEditClick()} disabled={actionButtonDisabled}>Edit</Button>
                <Button href="#" disabled={true}>Deactivate</Button>
                <Button variant="primary" href="/producttypes/add">
                  Add product type
                </Button>
              </SpaceBetween>
            }
          >
            Product types
          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={1} />
        }
      />
      </div>
    );
}

export class ProductType extends React.Component {
    render() {
      const mainAppProps = this.props;
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout 
                    contentType="table"
                    content={
                      <ProductTypeTable session={mainAppProps}/>
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/producttypes" />}
                />
            </body>
        );
    }
}