import React, { createContext, useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Navigation } from "../common/navigation";
import { Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FormField, Header, Input, Link, SpaceBetween} from "@amzn/awsui-components-react";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import { newPackagingBreadcrumbs } from "../common/breadcrumbs";
import { JobDetailsContent } from "./steps/JobDetails";
import { BasicInput } from "./steps/BasicInput";
import { TechnicalData } from "./steps/TechnicalData";
import { useNavigate, useParams } from "react-router-dom";
import { Artwork } from "./steps/Artwork";
import { CO2 } from "./steps/CO2";
import { ReviewPackaging } from "./steps/ReviewPackaging";
import { Confirmation } from "./steps/Confirmation";
import { createClient } from "src/client/AxiosClient";
import { CREATE_PACKAGING_JOB, POST_JOB_BASIC, PUT_JOB_STATUS } from "src/config/urlConfig";

export interface PackagingDetails {
    packName: string;
    barcode: string;
    flute?: string;
    dccode: string;
    poc: string;
    dueDate: string;
    specs?: {
        specsPoc: string[];
        specsReviewer: string[];
    };
    technicalDrawing?: {
        technicalDrawingPoc: string[];
        technicalDrawingReviewer: string[];
    };
}

export interface PackagingBarcode {
    masterPackNameId: string;
    masterPackName: string;
    barcode: string;
    flute: string;
    dcCode: string;
    subPackTypeId?: string;
    subPackTypeName?: string;
    packTypeId?: string;
    packTypeName?: string;
    manufacturingSites: any[];
}

export interface PackagingJob {
    id: string;
    productTypeId: string;
    description: string;
    supplierId: string;
    supplierName: string;
    manufacturingSiteLevel: boolean;
    barcodes: PackagingBarcode[];
}

export const PackagingWizard = (props: any) => {
    const {step} = useParams();

    const [
        activeStepIndex,
        setActiveStepIndex
      ] = React.useState<number>(parseInt(step? step : "0"));

    const [packagingJob, setPackagingJob] = useState<PackagingJob>({
        id: "",
        productTypeId: "",
        description: "",
        supplierId: "",
        supplierName: "",
        manufacturingSiteLevel: true,
        barcodes: []
    });

    const handleJobDetailsChange = (updatedDetails: Partial<PackagingJob>) => {
        setPackagingJob({
            id: updatedDetails.id || packagingJob.id,
            productTypeId: updatedDetails.productTypeId || packagingJob.productTypeId,
            description: updatedDetails.description || packagingJob.description,
            supplierId: updatedDetails.supplierId || packagingJob.supplierId,
            supplierName: updatedDetails.supplierName || packagingJob.supplierName,
            manufacturingSiteLevel: updatedDetails.manufacturingSiteLevel || packagingJob.manufacturingSiteLevel,
            barcodes: updatedDetails.barcodes || packagingJob.barcodes,
        });
    }

    const client = createClient();

    const saveJob = async () => {

      const reqBody = {
          "productTypeId": packagingJob.productTypeId,
          "description": packagingJob.description,
      }
      const response = await client.post(CREATE_PACKAGING_JOB, reqBody);
      if (response.data) {
          console.log(response.data);
          setPackagingJob({
              id: response.data.packageJob.id,
              productTypeId: response.data.packageJob.productTypeId,
              description: response.data.packageJob.description,
              supplierId: "",
              supplierName: "",
              manufacturingSiteLevel: true,
              barcodes: [],
          })
          window.location.href = ('/pe/newpackaging/1/'+response.data.packageJob.id);
      }
      else {
          console.log(response);
      }
      return;
    }

    // const saveJobBasic = async () => {
    //     const validBarcodeList = packagingJob.barcodes.filter((item) => item !== undefined);
    //     packagingJob.barcodes = validBarcodeList;
        
    //     const reqBody = {
    //         "supplierId": packagingJob.supplierId,
    //         "manufacturingSiteLevel": packagingJob.manufacturingSiteLevel,
    //         "barcodes": packagingJob.barcodes,
    //     }
    //     console.log(reqBody);
    //     const response = await client.post(POST_JOB_BASIC.replace("{id}", packagingJob.id), reqBody);
    //     if (response.data) {
    //         console.log(response.data);
    //         setPackagingJob({
    //           id: response.data.packageJob.id,
    //           productTypeId: response.data.packageJob.productTypeId,
    //           description: response.data.packageJob.description,
    //           supplierId: response.data.supplierId,
    //           supplierName: response.data.supplierName,
    //           manufacturingSiteLevel: response.data.manufacturingSiteLevel,
    //           barcodes: response.data.barcodes,
    //         })
    //     }
    //     else {
    //         console.log(response);
    //     }
    //     return;
    // }

    const getNextButtonLabel = () => {
        return "Next";
    }

    const getActiveStepIndex = () => {
        return activeStepIndex;
    }

    const closeJob = async() => {
      const response = await client.put(PUT_JOB_STATUS.replace("{id}", packagingJob.id).replace("{jobStatus}", "CLOSED"));
      if (response.data) {
          console.log(response.data);
      }
      else {
          console.log(response);
      }
    }

    const navigate = useNavigate();

      return (
        <div>
        <Wizard
          i18nStrings={{
            stepNumberLabel: stepNumber =>
              `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            navigationAriaLabel: "Steps",
            cancelButton: "Cancel",
            previousButton: "Previous",
            nextButton: getNextButtonLabel(),
            submitButton: "Go to home",
            optional: "optional"
          }}
          onNavigate={async ({ detail }) =>
            {
              if (activeStepIndex == 0 && detail.requestedStepIndex == 1) {
                  await saveJob();
                  setActiveStepIndex(detail.requestedStepIndex);
                  return;
              }
              if (activeStepIndex == 1 && detail.requestedStepIndex == 2) {
                  // await saveJobBasic();
                  setActiveStepIndex(detail.requestedStepIndex);
                  return;
              }
              if (activeStepIndex > detail.requestedStepIndex) {
                  // nice to have: show alert that you cannot go back
                  return;
              }
              setActiveStepIndex(detail.requestedStepIndex);
            }
          }
          onSubmit={() => {
            // closeJob();
            navigate('/home');
          }}
          onCancel={() => {
            navigate('/home');
          }}
          activeStepIndex={getActiveStepIndex()}
          steps={[
            {
              title: "Job Details",
              content: <JobDetailsContent job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
            {
              title: "Basic Input",
              content: <BasicInput job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>
            },
            {
              title: "Technical Data",
              content: <TechnicalData job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
            {
              title: "Artwork",
              content: <Artwork job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
            {
              title: "Review",
              content: <ReviewPackaging job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
            {
              title: "CO2 Calculation",
              content: <CO2 job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
            {
              title: "Confirmation",
              content: <Confirmation job={packagingJob} onJobDetailsChange={handleJobDetailsChange}/>,
            },
          ]}
        />
        </div>
      );
}

const Breadcrumbs = () => (
    <BreadcrumbGroup items={newPackagingBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export class NewPackaging extends React.Component{

    render () {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                    contentType="table"
                    content={
                      <PackagingWizard />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="#" />}
                />
            </body>
        )
    }
}