import {Environment, getEnvironmentConfig} from "src/config/envConfig";
import axios from "axios";
import {CognitoAuth} from 'amazon-cognito-auth-js';


const envConfig = getEnvironmentConfig();
const session = getCognitoSession(envConfig)

export function createClient() {
    const headers = {
        'Authorization': session.idToken.jwtToken
    }

    return axios.create({
        baseURL: envConfig.apiUrl,
        headers,
    });
}

export function getCognitoSession(config: Environment)  {
    const auth = new CognitoAuth({
        AppWebDomain: config.cognito.domain, // The domain name in Cognito "App integration > Domain name", without https://
        ClientId: config.cognito.clientId, // The client ID from Cognito "General settings > App clients" page
        RedirectUriSignIn: config.cognito.redirectUriSignIn,  // Exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
        RedirectUriSignOut: config.cognito.redirectUriSignOut, // Exactly same as the sign out URLS in Cognito "App integration > App client settings" page
        TokenScopesArray: ['openid'],
        UserPoolId: config.cognito.userPoolId,  // The user pool from Cognito "General settings" page
    });

    console.log('Cognito authentication object: ' , auth);
    auth.useCodeGrantFlow();

    auth.userhandler = {
        onFailure: (err) => {
            console.log("Cognito Authentication has been failed: ", err)
            // removeQueryFromLocation();
        },
        onSuccess: (result) => {
            console.log("Authentication has been done successfully using AWS Cognito: ", result)
            // removeQueryFromLocation();
        },
    }

    const href = window.location.href;
    let session = auth.getSignInUserSession();
    if (session.isValid()) {
        console.log("Cognito session is valid. Using current JWT token for user authentication: ", session);
    } else if (href.indexOf('?code') > 0) {
        console.log("Parse the cognito session response");
        auth.parseCognitoWebResponse(href);
    } else {
        console.log("Cognito session is not valid. Generating new session for the user");
        auth.clearCachedTokensScopes();
        auth.getSession();
    }

    // removeQueryFromLocation();

    console.log("Token:")
    console.log(session.getIdToken().getJwtToken())
    return session;
}
