import React, { useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Alert, Box, BreadcrumbGroup, Button, Checkbox, ColumnLayout, Container, ContentLayout, FormField, Header, Input, Link, Modal, SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react";
import { Document, Page, pdfjs } from 'react-pdf';
import { newPackagingFinalSpecsBreadcrumbs } from "../common/breadcrumbs";
import YourPDFFile from "src/assets/test.pdf";
import { useNavigate } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Breadcrumbs = () => (
    <BreadcrumbGroup items={newPackagingFinalSpecsBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

const SupplierInfoContent = (props: any) => {
    return (
        <Container
            header={
                <Header variant="h2">
                    M3 (SLO)
                </Header>
            }
        >
            <ColumnLayout columns={2} borders="vertical">
                <ValueWithLabel label="Supplier name">Supplier 3</ValueWithLabel>
                <ValueWithLabel label="Manufacturing sites">1</ValueWithLabel>
            </ColumnLayout>
        </Container>
    )
}

const FinalSpecsReviewContent = () => {
    const [externalLengthChecked, setExternalLengthChecked] = useState(false);
    const [externalWidthChecked, setExternalWidthChecked] = useState(false);
    const [externalHeightChecked, setExternalHeightChecked] = useState(false);
    const [weightChecked, setWeightChecked] = useState(false);
    const [boardSurfaceChecked, setBoardSurfaceChecked] = useState(false);
    
    const [boardCompositionChecked, setBoardCompositionChecked] = useState(false);
    const [thicknessChecked, setThicknessChecked] = useState(false);
    const [recycledChecked, setRecycledChecked] = useState(false);
    const [edgeResistanceChecked, setEdgeResistanceChecked] = useState(false);
    const [punctureResistanceChecked, setPunctureResistanceChecked] = useState(false);
    const [burstingResistanceChecked, setBurstingResistanceChecked] = useState(false);
    const [waterAbsorptionChecked, setWaterAbsorptionChecked] = useState(false);

    const [coldGlueChecked, setColdGlueChecked] = useState(false);
    const [inkCoverageChecked, setInkCoverageChecked] = useState(false);
    const [printColorChecked, setPrintColorChecked] = useState(false);
    const [printAreaChecked, setPrintAreaChecked] = useState(false);

    const [rejectSpecsButtonDisabled, setRejectSpecsButtonDisabled] = useState(false);
    const [approveSpecsButtonDisabled, setApproveSpecsButtonDisabled] = useState(false);

    const [rejectSpecsModalVisible, setRejectSpecsModalVisible] = useState(false);

    const openRejectSpecsModal = () => {
        setRejectSpecsModalVisible(true);
    };

    const [approveSpecsModalVisible, setApproveSpecsModalVisible] = useState(false);

    const openApproveSpecsModal = () => {
        setApproveSpecsModalVisible(true);
    };

    const downloadSpecs = () => {
        const a = document.createElement('a');
        a.href = YourPDFFile;
        a.download = 'test.pdf'; // Set the desired file name
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const [rejectSpecsComment, setRejectSpecsComment] = useState("");

    const navigate = useNavigate();

    const navigateToCarbonWizard = () => {
        // save to backend the current state
        navigate('/pe/newpackaging/5');
    }

    const [reviewStatus, setReviewStatus] = useState("");

    function getStatusText() {
        if (reviewStatus == "approved") {
            return "Approved";
        }
        if (reviewStatus == "rejected") {
            return "Rejected";
        }
        return "Approval pending";
    }

    function getStatusType() {
        if (reviewStatus == "approved") {
            return "success";
        }
        if (reviewStatus == "rejected") {
            return "error";
        }
        return "pending";
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <div>
            <SpaceBetween direction="vertical" size="xl">
            <Alert statusIconAriaLabel="Info" header="Review specs">
                If there are any incorrect specs, mark them and reject the specs. Changes are marked in blue.
            </Alert>
            <Container header={
                <Header variant="h2"
                // TODO: Change status indicator text based on approval state
                    description={<StatusIndicator type={getStatusType()}>{getStatusText()}</StatusIndicator>}
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button disabled={rejectSpecsButtonDisabled} onClick={openRejectSpecsModal}>Reject</Button>
                            <Button disabled={approveSpecsButtonDisabled} variant="primary" onClick={openApproveSpecsModal}>Approve</Button>
                        </SpaceBetween>
                    }
                >
                    Super Long Manufacturing Site Name
                </Header>
            }>
                <div>
                    <SpaceBetween size="l" direction="vertical">
                    <Header variant="h3">Dimensions and weight</Header>
                    <ColumnLayout columns={3}>
                        <div><Checkbox disabled checked>
                            <ValueWithLabel label="Internal length">27.7cm</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setExternalLengthChecked(detail.checked)} checked={externalLengthChecked}>
                            <ValueWithLabel label="External length">28.1cm</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setWeightChecked(detail.checked)} checked={weightChecked}>
                            <ValueWithLabel label="Weight">86g</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox disabled checked>
                            <ValueWithLabel label="Internal width">32.4cm</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setExternalWidthChecked(detail.checked)} checked={externalWidthChecked}>
                            <ValueWithLabel label="External width">32.8cm</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setBoardSurfaceChecked(detail.checked)} checked={boardSurfaceChecked}>
                            <ValueWithLabel label="Board surface">0.23m sq.</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox disabled checked>
                            <ValueWithLabel label="Internal height">7.0cm</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setExternalHeightChecked(detail.checked)} checked={externalHeightChecked}>
                            <ValueWithLabel label="External height">7.4cm</ValueWithLabel>
                        </Checkbox></div>
                    </ColumnLayout>

                    <Header variant="h3">Material characteristics</Header>
                    <ColumnLayout columns={3}>
                        <div><Checkbox onChange={({ detail }) => setBoardCompositionChecked(detail.checked)} checked={boardCompositionChecked}>
                            <ValueWithLabel label="Board composition">LcWWs/324/E</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setEdgeResistanceChecked(detail.checked)} checked={edgeResistanceChecked}>
                            <ValueWithLabel label="Edge crush resistance">4 kN/m</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setWaterAbsorptionChecked(detail.checked)} checked={waterAbsorptionChecked}>
                            <ValueWithLabel label="Water absorption - Cobb60">35</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setThicknessChecked(detail.checked)} checked={thicknessChecked}>
                            <ValueWithLabel label="Thickness">1.48mm</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setPunctureResistanceChecked(detail.checked)} checked={punctureResistanceChecked}>
                            <ValueWithLabel label="Puncture resistance">1.8 J</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setRecycledChecked(detail.checked)} checked={recycledChecked}>
                            <ValueWithLabel label="Overall recycled">100%</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setBurstingResistanceChecked(detail.checked)} checked={burstingResistanceChecked}>
                            <ValueWithLabel label="Bursting resistance">565 kpa</ValueWithLabel>
                        </Checkbox></div>
                    </ColumnLayout>

                    <Header variant="h3">Specifications and other features</Header>
                    <ColumnLayout columns={3}>
                        <div><Checkbox onChange={({ detail }) => setColdGlueChecked(detail.checked)} checked={coldGlueChecked}>
                            <ValueWithLabel label="Cold glue reference">Flexicoll CR</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setPrintColorChecked(detail.checked)} checked={printColorChecked}>
                            <ValueWithLabel label="Number of print color">1</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setPrintAreaChecked(detail.checked)} checked={printAreaChecked}>
                            <ValueWithLabel label="Print area">0.011 m sq.</ValueWithLabel>
                        </Checkbox></div>
                        <div><Checkbox onChange={({ detail }) => setInkCoverageChecked(detail.checked)} checked={inkCoverageChecked}>
                            <ValueWithLabel label="Ink coverage">5 %</ValueWithLabel>
                        </Checkbox></div>
                    </ColumnLayout>
                    <Header variant="h3">File</Header>
                    <ValueWithLabel label="Additional information"><Button onClick={downloadSpecs}>Download file</Button></ValueWithLabel>
                    </SpaceBetween>
                </div>
            </Container>
            </SpaceBetween>
        </div>
        <div style={{width: '100%'}}>
            <div style={{float: 'right'}}><Button iconAlign="right" onClick={navigateToCarbonWizard}>Close review</Button></div>
        </div>
        </SpaceBetween>
            {/* Reject specs modal */}
            <Modal
                onDismiss={() => setRejectSpecsModalVisible(false)}
                visible={rejectSpecsModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setRejectSpecsModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        // make backend call to reject the selected specs
                        setRejectSpecsModalVisible(false);
                        setRejectSpecsButtonDisabled(true);
                        setApproveSpecsButtonDisabled(true);
                        setReviewStatus("rejected");
                    }}>Reject</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Reject specs</Header>}
            >
                <SpaceBetween size="s">
                    <FormField
                        label="Comment"
                        description="Explain what needs to be corrected"
                    >
                    <Input
                        onChange={({ detail }) => setRejectSpecsComment(detail.value)}
                        value={rejectSpecsComment}
                        placeholder="Enter comment"
                    />
                    </FormField>
                </SpaceBetween>
            </Modal>
            {/* Approve specs modal */}
            <Modal
                onDismiss={() => setApproveSpecsModalVisible(false)}
                visible={approveSpecsModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setApproveSpecsModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        // make backend call to APPROVE specs
                        setApproveSpecsModalVisible(false);
                        setRejectSpecsButtonDisabled(true);
                        setApproveSpecsButtonDisabled(true);
                        setReviewStatus("approved");
                    }}>Approve</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Approve specs</Header>}
            >
                Are you sure that you want to approve the specs?
                Once approved, you will need to create a revision job to adjust the specs
            </Modal>
        </div>
    )
}

export class FinalSpecsReview extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            variant="h1"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                        >
                            Review final specs
                        </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="xl">
                        <SupplierInfoContent/>
                        <FinalSpecsReviewContent />
                        </SpaceBetween>
                    </ContentLayout>
                    }
                    breadcrumbs={<Breadcrumbs />}
                />
            </body>
        )
    }
}