import { domReady } from './dom-ready';

interface MonsWeblabHeader extends Element {
  name: 'a2z:mons_weblab_treatment';
  content?: string;
}

// IDs come from 'src/styles/_weblabs.scss
// TODO: Update IDs as weblabs become available.
// TODO: When experimentation is complete, remove this export and its references.
export const WEBLAB_IDS = {
  group0: 'WEBLAB_PLACEHOLDER_NAME_GROUP_0',
  group1: 'SP_SERVICES_706592_T1',
  group2: 'SP_SERVICES_764183_T1',
  group3: 'SP_SERVICES_765948_T1',
  group4: 'SP_SERVICES_KATAL_783808_T1',
  group5: 'SP_SERVICES_783812_T1',
  group6: 'SP_SERVICES_KATAL_783813_T1',
};

export const VISUAL_REFRESH_CLASSNAME = 'katal-visual-refresh-2024';

// Being good global namespace citizens
window['KATAL_CONVERT_WEBLAB_META_ELEMENT_EXECUTED'] = false;

/**
 * Mons Apps can receive Weblab IDs + Treatments in the `a2z:mons_weblab_treatment` <meta> element.
 * This can also be caused via the Mons Trim App as well.
 * This function is called every time a component is registered, but only executes once, when the DOM is ready.
 *
 * https://issues.amazon.com/SCPLAT-13839 would replace this technique.
 *
 * ref: https://w.amazon.com/bin/view/Mons/Weblab/Configuration/
 */
const convertWeblabMetaElement = () => {
  const weblabMetaTag = document.head?.querySelector<MonsWeblabHeader>(
    'meta[name="a2z:mons_weblab_treatment"]'
  );

  const weblabs = weblabMetaTag?.content?.split(',') || [];
  if (weblabs.includes('SP_SERVICES_KATAL_810944=T1')) {
    Object.values(WEBLAB_IDS).forEach(weblab => {
      // https://t.corp.amazon.com/V947788581
      document.body?.classList.add(weblab);
    });
  } else {
    weblabs.forEach(weblab => {
      // '=' is not a valid CSS character, so convert to '_'
      const parsedWeblabName = weblab.replace('=', '_');

      // https://t.corp.amazon.com/V947788581
      document.body?.classList.add(parsedWeblabName);
    });
  }
};

/**
 * Light wrapper that ensures convertWeblabMetaElement is only called once, when the DOM is ready.
 */
export const convertWeblabMetaElementOnceWhenDomReady = () => {
  if (window['KATAL_CONVERT_WEBLAB_META_ELEMENT_EXECUTED']) {
    return;
  }

  window['KATAL_CONVERT_WEBLAB_META_ELEMENT_EXECUTED'] = true;

  domReady(convertWeblabMetaElement);
};

// #region Weblab Observers
/**
 * Changes to a <body>'s CSS class won't trigger a re-render of a child component by default.
 *
 * In order to trigger a re-render when a weblab class is added or removed
 * (e.g. when a new icon on a component is under experimentation),
 * attach 'onBodyWeblabClassChange' and 'offBodyWeblabClassChange' to a component's
 * connectedCallback and disconnectedCallback, respectively, passing along a
 * callback to trigger the component's rerender.
 */

type RenderCallback = () => void;

const weblabClassChangeCallbacks = new Set<RenderCallback>();

const includesWeblabClass = (classList: string[] | null) => {
  if (!classList) {
    return false;
  }

  return (
    Object.values(WEBLAB_IDS).some(weblabClass =>
      classList.includes(weblabClass)
    ) || classList.includes(VISUAL_REFRESH_CLASSNAME)
  );
};

const notifyBodyWeblabClassChange = (mutations: MutationRecord[]) => {
  mutations.forEach(mutation => {
    const currBodyClassList = Array.from(
      (mutation.target as HTMLElement).classList
    );
    const prevBodyClassList = mutation.oldValue?.split(' ');

    const weblabClassAddedOrRemoved =
      includesWeblabClass(prevBodyClassList) ||
      includesWeblabClass(currBodyClassList);
    if (weblabClassAddedOrRemoved) {
      weblabClassChangeCallbacks.forEach(cb => cb());
    }
  });
};

const observer = new MutationObserver(notifyBodyWeblabClassChange);

let observing = false;
export const onBodyWeblabClassChange = (cb: RenderCallback) => {
  weblabClassChangeCallbacks.add(cb);
  if (!observing) {
    observing = true;

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
      attributeOldValue: true,
    });
  }
};

export const offBodyWeblabClassChange = (cb: RenderCallback) => {
  weblabClassChangeCallbacks.delete(cb);
  if (observing) {
    observing = false;
    observer.disconnect();
  }
};

// #endregion
