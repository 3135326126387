import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Navigation } from "../common/navigation";
import { Box, Button, ColumnLayout, Container, ContentLayout, Header, Link, SpaceBetween, StatusIndicator, Table } from "@amzn/awsui-components-react";
import { OPEN_TASKS_ADMIN_COLUMN_DEFINITION, OPEN_TASKS_ADMIN_COLUMN_DISPLAY, OPEN_TASKS_PE_COLUMN_DEFINITION } from "../common/table-config";
import packaging_repo_thumbnail from 'src/images/packaging_repo_thumbnail.png';
import product_type_thumbnail from 'src/images/product_type_thumbnail.png';
import supplier_thumbnail from 'src/images/supplier_thumbnail.png';
import job_thumbnail from 'src/images/job_thumbnail.png';
import pack_type_thumbnail from 'src/images/pack_type_thumbnail.png';
import sub_pack_thumbnail from 'src/images/pack_type_thumbnail.png';
import pack_name_thumbnail from 'src/images/pack_name_thumbnail.png';
import { useNavigate } from "react-router-dom";
import packaging_repository from 'src/images/packaging_repository.png';
import { OpenTasks, TaskJobData } from "../common/types";
import { GET_OPEN_TASKS } from "src/config/urlConfig";
import { createClient } from "src/client/AxiosClient";
import { getJobType, getDataType, getJobId, getBarcode } from "../common/utils";

const ValueWithLabel = ({ label, children } : {label: string, children: string})  => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div><Box color="text-status-info" fontSize="display-l" fontWeight="bold">{children}</Box></div>
    </div>
);

const PEHomeContent = (props: any) => {
    const [
        selectedItems,
        setSelectedItems
    ] = useState<TaskJobData[]>([]);

    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<TaskJobData[]>([]);

    const client = createClient();

    const fetchAllTasks = async () => {
        const response = await client.get(GET_OPEN_TASKS);
        if (response.data) {
            console.log(response);
            const data: OpenTasks[] = response.data.openTasks;

            const parsedData = data.map(item => ({
                id: item.id,
                jobType: getJobType(item.reference),
                dataType: getDataType(item.reference),
                jobId: getJobId(item.referenceId),
                barcode: getBarcode(item.referenceId),
                requiredAction: item.requiredAction,
            }));

            setTableItems(parsedData);
        
            return response.data.openTasks;
        }
        else {
            console.log(response);
        }
        return [];
    }

    const navigate = useNavigate();

    const handleEditClick = () => {
        if (selectedItems[0].dataType == "SPECS") {
            navigate('/review/task/'+selectedItems[0].id+'/specs/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
        }
        if (selectedItems[0].dataType == "TECH_DRAWING") {
            navigate('/review/task/'+selectedItems[0].id+'/technicaldrawing/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
        }
        if (selectedItems[0].dataType == "ARTWORK") {
            navigate('/review/task/'+selectedItems[0].id+'/artwork/'+selectedItems[0].jobId+'/barcode/'+selectedItems[0].barcode);
        }
    }

    useEffect(() => {
        const fetchTaskList = async () => {
            await fetchAllTasks();
            setTableLoading(false);
        };
        fetchTaskList()
    }, []);
    
    return (
        <SpaceBetween size="xl">
            <Table
                onSelectionChange={({ detail }) =>
                setSelectedItems(detail.selectedItems)
                }
                selectedItems={selectedItems}
                ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
                }}
                columnDefinitions={OPEN_TASKS_ADMIN_COLUMN_DEFINITION}
                columnDisplay={OPEN_TASKS_ADMIN_COLUMN_DISPLAY}
                items={tableItems}
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="name"
                header={
                <Header
                    counter="(1)"
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h2"
                    actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="xs"
                    >
                        <Button onClick={handleEditClick}>Edit</Button>
                        {/* <Button>Reassign</Button> */}
                    </SpaceBetween>
                    }
                    description="Always complete your open tasks first"
                >
                    Open tasks
                </Header>
                }
            />
            <Box>
                <Header
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                        variant="h2"
                        description="For each creation and revision a job must be created"
                        actions={
                            <Button variant="primary" onClick={() => navigate('/pe/newpackaging/0')}>Create job</Button>
                        }
                    >
                        Job overview
                </Header>
            </Box>
            <Container
                header={
                    <Header variant="h2"
                        description="Overview of the overall current job status. The average is calculated yearly"
                    >
                        Current job stats
                    </Header>
                }
            >
                <ColumnLayout columns={4}>
                    <ValueWithLabel label="Open creation jobs" children={"2"}></ValueWithLabel>
                    <ValueWithLabel label="Average creation job duration" children={"3 weeks"}></ValueWithLabel>
                    <ValueWithLabel label="Open revision jobs" children={"1"}></ValueWithLabel>
                    <ValueWithLabel label="Average revision job duration" children={"1 week"}></ValueWithLabel>
                </ColumnLayout>
            </Container>
            <Box>
                <Header
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h2"
                    description="Every packaging created will be stored in the master repository. "
                >
                    Data
                </Header>
            </Box>
            <ColumnLayout columns={3}>
                <Container
                    media={{
                        content: (
                        <img
                            src={packaging_repo_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packages">Packaging Repository</Link></Box>
                        <Box color="text-body-secondary">View all final packaging data including barcodes</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={supplier_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/suppliers">Suppliers</Link></Box>
                        <Box color="text-body-secondary">View Supplier information and linked users</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={job_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/jobs">Job overview</Link></Box>
                        <Box color="text-body-secondary">View all current and past jobs</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={product_type_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/producttypes">Product Types</Link></Box>
                        <Box color="text-body-secondary">View all product types and required creation steps</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={pack_type_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packtypes">Pack Types</Link></Box>
                        <Box color="text-body-secondary">View the pack types and the required fields</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={sub_pack_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/subpacktypes">Sub Pack Types</Link></Box>
                        <Box color="text-body-secondary">View sub pack types</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={pack_name_thumbnail}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packnames">Pack Name</Link></Box>
                        <Box color="text-body-secondary">View the master data of the pack names</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
                <Container
                    media={{
                        content: (
                        <img
                            src={packaging_repository}
                            alt="placeholder"
                        />
                        ),
                        height: 160,
                        width: 300,
                        position: "top"
                    }}
                    >
                    <SpaceBetween direction="vertical" size="s">
                        <SpaceBetween direction="vertical" size="xxs">
                        <Box variant="h1"><Link href="/packages">Packaging Repository</Link></Box>
                        <Box color="text-body-secondary">View all final packaging data including barcodes</Box>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
            </ColumnLayout>
        </SpaceBetween>
    )
}

export class PEHome extends React.Component  {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                    <CustomAppLayout
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/home" />}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            data-custom-color="black"
                            variant="awsui-h1-sticky"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                            description="Administer the tool to help Amazon manage packaging jobs and data"
                        >
                            Amazon Packaging Portal
                        </Header>
                        }
                    >
                        <PEHomeContent />
                    </ContentLayout>
                    } />
            </body>
        );
    }
}