import {Container, FormField, Header, Input, Select, SpaceBetween} from "@amzn/awsui-components-react"
import {useEffect, useState} from "react";
import {GET_PRODUCT_TYPE} from "src/config/urlConfig";
import {createClient} from "src/client/AxiosClient";
import { PackagingJob } from "../NewPackaging";

interface ProductTypeResponse {
    id: string;
    name: string;
    jobSteps: any[];
}

interface PackagingDetails {
    packName: string;
    barcode: string;
    flute?: string;
    dccode: string;
    poc: string;
    dueDate: string;
    specs?: any;
    technicalDrawing?: any;
}

interface PackagingJobProps {
    job: PackagingJob;
    onJobDetailsChange: (updatedDetails: Partial<PackagingJob>) => void;
}

export const JobDetailsContent: React.FC<PackagingJobProps> = ({job, onJobDetailsChange}) => {
    const [description, setDescription] = useState("");
    const [tableItems, setTableItems] = useState<any[]>([]);
    const [
        selectedOption,
        setSelectedOption
      ] = useState<any>();


    const handleJobDetailsChange = (updatedDetails: Partial<PackagingJob>) => {
        onJobDetailsChange(updatedDetails);
    }

    const client = createClient()

    const fetchProductTypes = async () => {
        const response = await client.get(GET_PRODUCT_TYPE);
        if (response.data) {
            console.log(response.data.productTypes);
            const data: ProductTypeResponse[] = response.data.productTypes;
            const parsedData = data.map(item => ({
                label: item.name,
                value: item.id,
            }));
            setTableItems(parsedData);
        
            return response.data.productTypes;
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchProductTypesList = async () => {
            await fetchProductTypes();
        };
        fetchProductTypesList()
    }, []);
    
    return (
        <Container
            header={
                    <Header variant="h2">
                        Insert the job details
                    </Header>
                    }
                >
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Product type">
                    <Select
                        selectedOption={selectedOption}
                        onChange={({ detail }) =>
                            {
                                setSelectedOption(detail.selectedOption);
                                handleJobDetailsChange({productTypeId: detail.selectedOption.value})
                            }
                        }
                        options={tableItems}
                    />
                </FormField>
                <FormField label="Description">
                    <Input value={description} onChange={({ detail }) => {
                        setDescription(detail.value);
                        handleJobDetailsChange({description: detail.value});
                    }} 
                        placeholder="Describe the job briefly"
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    )
}