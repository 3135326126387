export const getJobType = (reference: string) => {
    const matchResult = reference.match(/^(.*?)#/);
    if (matchResult && matchResult.length > 1) {
        return matchResult[1];
    }
    return "";
}

export const getDataType = (reference: string) => {
    const matchResult = reference.match(/#([^#]*)$/);
    if (matchResult && matchResult.length > 1) {
        return matchResult[1];
    }
    return "";
}

export const getBarcode = (reference: string) => {
    const matchResult = reference.match(/#(.*)$/);
    if (matchResult && matchResult.length > 1) {
        return matchResult[1];
    }
    return "";
}

export const getJobId = (reference: string) => {
    const matchResult = reference.match(/^([^#]*)#/);
    if (matchResult && matchResult.length > 1) {
        return matchResult[1];
    }
    return "";
}