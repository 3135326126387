import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, ContentLayout, FormField, Header, Link, Modal, Pagination, Select, SpaceBetween, Table, TextFilter } from "@amzn/awsui-components-react";
import { jobsOverviewBreadcrumbs, packagesOverviewBreadcrumbs } from "../common/breadcrumbs";
import { Navigation } from "../common/navigation";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGES, GET_PACKAGING_JOBS, GET_PACKAGING_JOB_DETAIL, GET_USER_LIST, REASSIGN_JOB } from "src/config/urlConfig";
import { ALL_JOBS_COLUMN_DEFINITION, ALL_JOBS_TABLE_COLUMN_DISPLAY, ALL_PACKAGES_COLUMN_DEFINITION, ALL_PACKAGES_TABLE_COLUMN_DISPLAY } from "../common/table-config";
import { useNavigate } from "react-router-dom";
import { UserResponse } from "../admin/Users";
import { PackageShort } from "../common/types";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={packagesOverviewBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const PackagesOverviewContent = (props: any) => {
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    const [filteringText, setFilteringText] = React.useState("");

    const [rawPackagesData, setRawPackagesData] = useState<PackageShort[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<PackageShort[]>([]);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const client = createClient();

    const fetchAllPackages = async () => {
        const response = await client.get(GET_PACKAGES);
        if (response.data) {
            console.log(response.data);
            const data: PackageShort[] = response.data.packages;
            const parsedData = data.map(item => ({
                id: item.id,
                barcode: item.barcode,
                masterPackName: item.masterPackName,
                packType: item.packType,
                subPackType: item.subPackType,
                supplierName: item.supplierName,
                siteCount: item.siteCount,
            }));

            setTableItems(parsedData);
        
            return response.data.packageJobs;
        }
        else {
            console.log(response);
        }
        return [];
    }


    useEffect(() => {
        const fetchJobsList = async () => {
            setRawPackagesData(await fetchAllPackages());
            setTableLoading(false);
        };
        fetchJobsList();
    }, []);

    const navigate = useNavigate();
    
    const handleDetailsClick = () => {
        const selectedId = selectedItems[0].id;
        navigate('/packages/detail/'+selectedId)
    }

    return (
        <div>
            <Table
                onSelectionChange={({ detail }) =>
                    {
                        setSelectedItems(detail.selectedItems);
                        setActionButtonDisabled(false);
                    }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                    selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
                }}
                columnDefinitions={ALL_PACKAGES_COLUMN_DEFINITION}
                columnDisplay={ALL_PACKAGES_TABLE_COLUMN_DISPLAY}
                items = {tableItems}
                loading={tableLoading}
                loadingText="Loading resources"
                selectionType="single"
                trackBy="id"
                empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                    <b>No resources</b>
                    </SpaceBetween>
                </Box>
                }
                filter={
                <TextFilter
                    filteringText={filteringText}
                    filteringPlaceholder="Find jobs"
                    filteringAriaLabel="Filter jobs"
                    onChange={({ detail }) =>
                        setFilteringText(detail.filteringText)
                    }
                />
                }
                header={
                <Header
                    counter={"(" + tableItems.length + ")"}
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }
                    variant="h1"
                    actions={
                    <SpaceBetween
                        direction="horizontal"
                        size="xs"
                    >
                        <Button disabled={actionButtonDisabled} onClick={() => handleDetailsClick()}>View Details</Button>
                    </SpaceBetween>
                    }
                >
                    Packaging repository
                </Header>
                }
                pagination={
                <Pagination currentPageIndex={1} pagesCount={1} />
                }
            />
        </div>
    );
}

export class PackagesOverview extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                    contentType="table"
                    content={
                        <PackagesOverviewContent />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigationOpen={false}
                    navigationHide={true}
                    navigation={<Navigation activeHref="/repository" />}
                />
            </body>
        )
    }
}