import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, Header, Link, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { supplierProvideDataBreadcrumbs } from "../common/breadcrumbs";
import { JobDetails } from "../jobs/JobDetail";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGING_JOB_DETAIL} from "src/config/urlConfig";
import { useNavigate } from "react-router-dom";

// const Breadcrumbs = () => (
//     <BreadcrumbGroup items={supplierProvideDataBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
// );

// const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
//     <div>
//       <Box variant="awsui-key-label">{label}</Box>
//       <div>{children}</div>
//     </div>
// );

// const RequestedPackage = ({index, job}: {index: any, job: JobDetails}) => {

//     const navigate = useNavigate();

//     const handleEditClick = () => {
//         navigate("/supplier/providedata/technicaldata/"+job.id+"/barcode/"+job.barcodes[index].barcode);
//     }

//     return (
//         <div>
//             <Container
//                 header={
//                     <Header
//                         actions={
//                             <Button onClick={handleEditClick}>
//                                 Edit
//                             </Button>
//                         }
//                     >
//                         <ColumnLayout columns={2}>
//                             <div><ValueWithLabel label="Barcode">{job.barcodes[index].barcode}</ValueWithLabel></div>
//                             <div><ValueWithLabel label="Manufacturing sites">{job.barcodes[index].manufacturingSites.length}</ValueWithLabel></div>
//                             <div><ValueWithLabel label="Specs">-</ValueWithLabel></div>
//                             <div><ValueWithLabel label="Technical drawing">-</ValueWithLabel></div>
//                         </ColumnLayout>
//                     </Header>
//                 }
//             >

//             </Container>
//         </div>
//     )
// }

// const ProvideDataContent = () => {
//     const [packagingJob, setPackagingJob] = useState<JobDetails>();

//     const client = createClient();

//     const url: string = window.location.href;
//     const idMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/([a-fA-F0-9-]+)/);
    
//     let id = "";
//     if (idMatch) {
//         id = idMatch[1];
//     }

//     const submitData = async() => {
//         const reqBody = {
//             "openTaskIds": []
//         }
//         const response = await client.put(SUBMIT_TECH_DATA.replace("{id}", id), reqBody);
//         if (response.data) {
//             console.log(response.data);
//         }
//         else {
//             console.log(response);
//         }
//     }

//     const fetchJobDetail = async () => {
//         const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
//         if (response.data) {
//             setPackagingJob(response.data.packageJob);
//             console.log(response.data.packageJob);
//         }
//         else {
//             console.log(response);
//         }
//         return [];
//     }

//     useEffect(() => {
//         const fetchJobs = async () => {
//             await fetchJobDetail();
//         };
//         fetchJobs()
//     }, []);
  
//     if (!packagingJob) {
//         return (
//               <div>
//                   <Spinner size="big" />
//               </div>
//           )
//     }

//     return (
//         <div>
//             <SpaceBetween direction="vertical" size="l">
//             <Container
//                 header={
//                     <Header variant="h2"
//                         description="Use the job ID when referring to this job in any communication"
//                         >
//                         Job Details
//                     </Header>
//                 }
//             >
//                 <ColumnLayout borders="vertical" columns={2}>
//                     <div><ValueWithLabel label="Job ID">{packagingJob.id}</ValueWithLabel></div>
//                     <div><ValueWithLabel label="Data granularity">Manufacturing site</ValueWithLabel></div>
//                 </ColumnLayout>
//             </Container>

//             <Header variant="h2"
//                 description="Insert the data per packaging"
//                 counter={"(" + packagingJob.barcodes.length + ")"}
//             >
//                 Requested Packaging
//             </Header>

//             <RequestedPackage index={0} job={packagingJob}/>
            
//             <div>
//                 <SpaceBetween direction="horizontal" size="m">
//                     <Button>
//                         Cancel
//                     </Button>
//                     <Button variant="primary" onClick={submitData}>
//                         Submit data
//                     </Button>
//                 </SpaceBetween>
//             </div>
//             </SpaceBetween>
//         </div>
//     )
// }

export class ProvideData extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                navigationHide={true}
                content={
                <ContentLayout
                    header={
                    <Header
                        data-custom-color="black"
                        variant="awsui-h1-sticky"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Fill out the needed information for the job"
                        actions={
                            <Button>
                                Reassign
                            </Button>
                        }
                    >
                        Provide information
                    </Header>
                    }
                >
                    {/* <ProvideDataContent /> */}
                </ContentLayout>
                }
                // breadcrumbs={<Breadcrumbs />}
            />
            </body>
        )
    }
}