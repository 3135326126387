import * as React from "react";
import { useState } from "react";
import packaging_logo from 'src/images/package_logo.png';
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { createClient, getCognitoSession } from "src/client/AxiosClient";
import { Environment, getEnvironmentConfig } from "src/config/envConfig";

export default () => {

  const envConfig = getEnvironmentConfig();
  const session = getCognitoSession(envConfig);

  const getUserName = () => {
    return session.idToken.payload.given_name;
  }

  const getUserEmail = () => {
    return session.idToken.payload.email;
  }

  //function to retrieve user role from localStorage
  const getUserRole = () => {
    const roleFromLocalStoreEncoded = localStorage.getItem('role');
    const roleFromLocalStore = roleFromLocalStoreEncoded ? atob(roleFromLocalStoreEncoded) : null;
    type RoleType = 'Admin' | 'Packaging Engineer' | 'Supplier' | 'Unknown';


    let finalRole: RoleType;
    switch (roleFromLocalStore) {
      case "APP_ADMIN":
        finalRole = "Admin";
        break
      case "APP_PACKAGING_ENGINEER":
        finalRole = "Packaging Engineer";
        break
      case "APP_SUPPLIER":
        finalRole = "Supplier";
        break
      default:
        finalRole = "Unknown";
        break
    }

    return finalRole;
  }
 
  //State for user role
  const [userRole, setUserRole] = useState(getUserRole());

  const determineToggle = () => {
    
    //const accessFromLocalStore = localStorage.getItem('accessType');
    const roleFromStoreEncoded = localStorage.getItem('role');
    const roleFromStore = roleFromStoreEncoded ? atob(roleFromStoreEncoded) : null;

    type ToggleType = '' | 'Switch to Packaging Engineer' | 'Switch to Admin' | 'Not authorized to switch roles';

    let finalToggle: ToggleType;

/*     if (accessFromLocalStore === "full") {
      if (getUserRole() === "Admin") {
        finalToggle = 'Switch to Packaging Engineer';
      } else {
        finalToggle = 'Switch to Admin';
      }
    } else {
      finalToggle = 'Cannot switch role';
    } */
    finalToggle = '';
    if (roleFromStore === "APP_ADMIN") {
      finalToggle = 'Switch to Packaging Engineer';
      return finalToggle;
    }

    if (roleFromStore === "APP_PACKAGING_ENGINEER") {
      finalToggle = 'Switch to Admin';
      return finalToggle;
    }

    if (roleFromStore === "APP_SUPPLIER") {
      finalToggle = 'Not authorized to switch roles';
      return finalToggle;
    }

    return finalToggle;
  }

  const handleClickEvent = (event) => {
    const toggleVal = determineToggle();
    if (toggleVal === "Switch to Packaging Engineer") {
      localStorage.setItem('role', btoa("APP_PACKAGING_ENGINEER"));
    }

    if (toggleVal === "Switch to Admin") {
      localStorage.setItem('role', btoa("APP_ADMIN"));
    }

    if (toggleVal === "Not authorized to switch roles") {
      return;
    }
    setUserRole(getUserRole());
    window.location.href = '/home';
  }

  const handleSignOut = (event) => {
    if (event.detail.id === "signout") {
      localStorage.clear();
    window.location.href = "https://amazon.com";
    }
  }


  return (
    <TopNavigation
      identity={{
        href: "/home",
        logo: {
          src: packaging_logo ,alt: "Service"
        },
        title: "Amazon Packaging Portal TEST"
      }}
      utilities={[
        /* {
          type: "button",
          iconName: "notification",
          title: "Notifications",
          ariaLabel: "Notifications (unread)",
          badge: true,
          disableUtilityCollapse: false
        }, */
        {
          type: "menu-dropdown",
          text: "Role: " + userRole,
          items: [
            { id: "switch", text: determineToggle() },
          ],
          onItemClick: handleClickEvent
        },
        {
          type: "menu-dropdown",
          text: getUserName(),
          description: getUserEmail(),
          iconName: "user-profile",
          items: [
            /* { id: "profile", text: "Profile" },
            { id: "preferences", text: "Preferences" },
            { id: "security", text: "Security" }, */
            {
              id: "support-group",
              text: "Support",
              items: [
                /* {
                  id: "documentation",
                  text: "Documentation",
                  href: "#",
                  external: true,
                  externalIconAriaLabel:
                    " (opens in new tab)"
                },
                { id: "support", text: "Support" }, */
                {
                  id: "feedback",
                  text: "Feedback",
                  href: "#",
                  external: true,
                  externalIconAriaLabel:
                    " (opens in new tab)"
                }
              ]
            },
            { id: "signout", text: "Sign out" },
          ],
          onItemClick: handleSignOut
        }
      ]}
    />
  );
}
