import {
    Button,
    Checkbox,
    ColumnLayout,
    Container,
    DatePicker,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react"
import {useEffect, useState} from "react";
import {GET_PACKAGING_JOB_DETAIL, GET_PACK_NAMES, GET_SUPPLIER_LIST, POST_BARCODE, POST_JOB_BASIC} from "src/config/urlConfig";
import {createClient} from "src/client/AxiosClient";
import { PackagingBarcode, PackagingJob } from "../NewPackaging";
import { PackNamesResponse } from "src/pages/admin/PackNames";
import { JobDetails } from "src/pages/jobs/JobDetail";
import { useParams } from "react-router-dom";
import React from "react";

export interface SupplierResponse {
    activeStatus: string;
    id: string;
    name: string;
    manufacturingSites: string[];
    pocs: string[];
}

interface PackagingBasicInput {
    packName: string;
    barcode: string;
    flute: string;
    dccode: string;
    poc: string;
    dueDate: string;
}

interface PackagingChildProps {
    index: number;
    job: JobDetails;
    isDisabled: boolean;
    onRemove: (index: number) => void;
    packagingInfo: PackagingBarcode;
    onPackagingDetailsChange: (index: number, updatedDetails: Partial<PackagingBarcode>) => void;
}

interface PackagingDetails {
    packName: string;
    barcode: string;
    flute?: string;
    dccode: string;
    poc: string;
    dueDate: string;
    specs?: any;
    technicalDrawing?: any;
}

export interface PackagingJobProps {
    job: PackagingJob;
    onJobDetailsChange: (updatedDetails: Partial<PackagingJob>) => void;
}

const PackagingDetailsContainer: React.FC<PackagingChildProps> = ({index, job, isDisabled, onRemove, packagingInfo, onPackagingDetailsChange}) => {
    const [packName, setPackName] = useState<any>({ value: "" });
    const [flute, setFlute] = useState<any>({value: ""});

    const [barcode, setBarcode] = useState("");
    const [dcCode, setDcCode] = useState("");

    const [autoBarcodeChecked, setAutoBarcodeChecked] = useState(false);

    const handleRemove = () => {
        onRemove(index);
    }

    const handlePackagingDetailsChange = (updatedDetails: Partial<PackagingBarcode>) => {
        onPackagingDetailsChange(index, updatedDetails);
    };

    const [packNameTableItems, setPackNameTableItems] = useState<any[]>([]);

    const client = createClient();

    const fetchPackNames = async () => {
        const response = await client.get(GET_PACK_NAMES);
        if (response.data) {
            console.log(response.data.masterPackNames);
            const data: PackNamesResponse[] = response.data.masterPackNames;
            let filteredData = data.filter(obj => obj.productTypeId == job.productTypeId);
            console.log(filteredData);
            const parsedData = filteredData.map(item => ({
                value: item.id,
                label: item.name,
            }));

            setPackNameTableItems(parsedData);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchPackNameList = async () => {
            fetchPackNames();
            
        };
        fetchPackNameList()
    }, []);
    
    const saveBarcode = async () => {
        const reqBody = {
            "barcode": barcode,
            "supplierId": job.supplierId,
            "masterPackNameId": packName.value,
            "packageJobId": job.id
        }
        const response = await client.post(POST_BARCODE, reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return;
    }

    const generateBarcode = async () => {
        const reqBody = {
            "supplierId": job.supplierId,
            "masterPackNameId": packName.value,
            "packageJobId": job.id
        }
        const response = await client.post(POST_BARCODE, reqBody);
        if (response.data) {
            console.log(response.data);
            setBarcode(response.data.barcode.barcode);
            handlePackagingDetailsChange({barcode: response.data.barcode.barcode});
        }
        else {
            console.log(response);
        }
        return;
    }

    

    return (
        <Container 
            header={
                <Header variant="h2"
                    actions={
                        <Button disabled={isDisabled} onClick={handleRemove}>Remove</Button>
                    }
                >
                    Packaging details
                </Header>
            }
            footer={
                <div>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="DC Code" description="Input the DC code">
                            <Input value={dcCode} 
                                onChange={({ detail }) => {
                                    setDcCode(detail.value);
                                    handlePackagingDetailsChange({dcCode: detail.value})
                                }} />
                        </FormField>
                    </SpaceBetween>
                </div>
            }
            >
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Pack name">
                    <Select
                        selectedOption={packName}
                        onChange={({ detail }) =>
                            {
                                setPackName(detail.selectedOption);
                                handlePackagingDetailsChange({masterPackNameId: detail.selectedOption.value});
                            }
                        }
                        options={packNameTableItems}
                    />
                </FormField>
                <SpaceBetween direction="vertical" size="m">
                    <FormField label="Barcode">
                        <SpaceBetween  direction="vertical" size="m">
                        <Checkbox
                            onChange={({ detail }) =>
                                setAutoBarcodeChecked(detail.checked)
                            }
                            checked={autoBarcodeChecked}
                            description="Applies to all packaging in this job"
                            >
                            Automatic barcode assignment
                        </Checkbox>
                        
                        <Input value={barcode} onChange={({ detail }) => {
                            setBarcode(detail.value);
                            handlePackagingDetailsChange({barcode: detail.value});
                        }} />
                        </SpaceBetween>          
                    </FormField>
                    <div>
                        <SpaceBetween direction="horizontal" size="m">
                            <Button onClick={saveBarcode} disabled={autoBarcodeChecked}>
                                Verify Barcode
                            </Button>
                            <Button onClick={generateBarcode} disabled={!autoBarcodeChecked}>
                                Generate Barcode
                            </Button>
                        </SpaceBetween>
                    </div>
                </SpaceBetween>
                <FormField label="Flute">
                    <Select
                        selectedOption={flute}
                        onChange={({ detail }) =>
                            {
                                setFlute(detail.selectedOption);
                                handlePackagingDetailsChange({flute: detail.selectedOption.value});
                            }
                        }
                        options={[
                            // TODO: GetFluteTypes call to the backend for the below
                            {value: "B"},
                            {value: "C"},
                            {value: "E"},
                            {value: "BC"},
                        ]}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    )
}

export const BasicInput: React.FC<PackagingJobProps> = ({job, onJobDetailsChange})=> {
    const [tableItems, setTableItems] = useState<any[]>([]);
    const [
        selectedOption,
        setSelectedOption
      ] = useState(tableItems[0]);
    
    const [manufacturingSiteChecked, setManufacturingSiteChecked] = useState(true);

    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    // for state preservation
    const { jobId } = useParams<{ jobId: string }>();

    const client = createClient();

    const fetchSuppliers = async () => {
        const response = await client.get(GET_SUPPLIER_LIST);
        if (response.data) {
          const data: SupplierResponse[] = response.data.suppliers;
          const parsedData = data.map(item => ({
              label: item.name,
              value: item.id,
          }));
  
          setTableItems(parsedData);
          setSelectedOption(parsedData[0])
      
          return response.data.suppliers;
        }
        else {
          console.log(response);
        }
        return [];
      }
  
    useEffect(() => {
    const fetchSupplierList = async () => {
        await fetchSuppliers();
    };
    fetchSupplierList()
    }, []);

    const MAX_CONTAINERS = 10;

    const [containerCount, setContainerCount] = useState(1); // Start with 1 for the default container
    const [generalBarcodeDetails, setGeneralBarcodeDetails] = useState<PackagingBarcode[]>([]);

    const handleAddPackaging = () => {
        if (containerCount < MAX_CONTAINERS) {
            setContainerCount(prevCount => prevCount + 1);
            setGeneralBarcodeDetails(prevDetails => {
                const updatedDetails = [...prevDetails];
                updatedDetails.push({
                    masterPackNameId: '',
                    masterPackName: '',
                    barcode: '',
                    flute: '', //
                    dcCode: '',
                    manufacturingSites: [],
                });
                return updatedDetails;
            });
        }
    };

    const handleRemove = (index: number) => {
        if (containerCount > 1) {
            setContainerCount(prevCount => prevCount - 1);
            setGeneralBarcodeDetails(prevDetails => {
                const updatedDetails = [...prevDetails];
                updatedDetails.splice(index, 1);
                return updatedDetails;
            });
        }
    };

    const handlePackagingDetailsChange = (index: number, updatedDetails: Partial<PackagingBasicInput>) => {
        // Update the packaging details for the specified index with the new values
        setGeneralBarcodeDetails((prevDetails) => {
          const updatedDetailsArray = [...prevDetails];
          updatedDetailsArray[index] = { ...updatedDetailsArray[index], ...updatedDetails };
          return updatedDetailsArray;
        });
    };

    // const handleJobDetailsChange = (updatedDetails: Partial<PackagingJob>) => {
    //     onJobDetailsChange(updatedDetails);
    // }

    // useEffect(() => {
    //     job.barcodes = generalBarcodeDetails;
    //     onJobDetailsChange(job);
    // }, [generalBarcodeDetails])

    const [supplierId, setSupplierId] = useState("");
    const [mslevel, setMSLevel] = useState(true);

    const saveJobBasic = async () => {
        if (!packagingJob) {
            return;
        }
        const validBarcodeList = packagingJob.barcodes.filter((item) => item !== undefined);
        packagingJob.barcodes = validBarcodeList;
        
        const reqBody = {
            "supplierId": supplierId,
            "manufacturingSiteLevel": mslevel,
            "barcodes": generalBarcodeDetails,
        }
        console.log(reqBody);
        console.log(packagingJob);
        const response = await client.post(POST_JOB_BASIC.replace("{id}", packagingJob.id), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return;
    }

    const fetchJobDetail = async () => {
        if (jobId != "") {
            const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", jobId || ""));
            if (response.data) {
                setPackagingJob(response.data.packageJob);
                console.log(response.data);
            }
            else {
                console.log(response);
            }
            return [];
        }
        return [];
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchJobDetail();
        };
        fetchData()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner/>
            </div>
        )
    }
    
    return (
        <div>
        <SpaceBetween direction="vertical" size="xl">
        <Container
            header={
                    <Header variant="h2" description="The selection applies to all packaging below"
                    actions={
                        <Button variant="primary" onClick={() => saveJobBasic()}>Save job</Button>
                    }
                    >
                        General
                    </Header>
                    }
                >
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Supplier">
                <SpaceBetween direction="vertical" size="s">
                    <Select
                        selectedOption={selectedOption}
                        onChange={({ detail }) =>
                            {
                                setSelectedOption(detail.selectedOption);
                                setSupplierId(detail.selectedOption.value || "");
                                packagingJob.supplierId = detail.selectedOption.value || "";
                                // handleJobDetailsChange({supplierId: detail.selectedOption.value});
                            }
                        }
                        options={tableItems}
                    />
                    <Checkbox
                        onChange={({ detail }) =>
                            {
                                setManufacturingSiteChecked(detail.checked);
                                setMSLevel(detail.checked);
                                packagingJob.manufacturingSiteLevel = detail.checked;
                                // handleJobDetailsChange({manufacturingSiteLevel: detail.checked});
                            }
                        }
                        checked={manufacturingSiteChecked}
                        description="Select to request data on a manufacturing site level from a supplier"
                        >
                        Manufacturing site level
                    </Checkbox>
                    
                    </SpaceBetween>
                </FormField>
            </SpaceBetween>
        </Container>
        <Header
            variant="h2"
            actions={
                <Button onClick={handleAddPackaging}>Add Packaging</Button>
            }
            description="You can create different packaging for the selected supplier in one job"
            >
            Packaging
        </Header>
            {Array.from({ length: containerCount }).map((_, index) => (
                <React.Fragment key={index}>
                    {index === 0 ? ( // Render the default container separately
                        <PackagingDetailsContainer
                            index={index}
                            job={packagingJob}
                            isDisabled={false} // Adjust the disabled state as needed
                            onRemove={() => {}} // No removal for the default container
                            packagingInfo={generalBarcodeDetails[index] || {}}
                            onPackagingDetailsChange={handlePackagingDetailsChange}
                        />
                    ) : (
                        <PackagingDetailsContainer
                            index={index}
                            job={packagingJob}
                            isDisabled={index == 0}
                            onRemove={() => handleRemove(index)}
                            packagingInfo={generalBarcodeDetails[index] || {}}
                            onPackagingDetailsChange={handlePackagingDetailsChange}
                        />
                    )}
                </React.Fragment>
            ))}

        </SpaceBetween>
        </div>
    )
}