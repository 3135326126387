import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Alert, Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Link, Multiselect, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { supplierProvideTechnicalDataBreadcrumbs } from "../common/breadcrumbs";
import { JobDetails } from "../jobs/JobDetail";
import { createClient } from "src/client/AxiosClient";
import { GET_DOCUMENT_FILE, GET_PACKAGING_JOB_DETAIL, GET_PACK_NAME, GET_SUPPLIER, POST_PACKAGING_TECH_DRAWING_FILE } from "src/config/urlConfig";
import { PackagingBarcode } from "../pe/NewPackaging";
import { useNavigate } from "react-router-dom";
import { ManufacturingSite } from "./ProvideSpecsEditor";
import { MPNDetail } from "../admin/PackNameDetail";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={supplierProvideTechnicalDataBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export const ProvideTechnicalDataContent = () => {

    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    const [currentBarcode, setCurrentBarcode] = useState<PackagingBarcode>();

    const [
        selectedOptions,
        setSelectedOptions
      ] = React.useState<any>([]);

    const [fileValue, setFileValue] = React.useState<File[]>([]);

    const client = createClient();

    const [mpnDetails, setMPNDetails] = useState<MPNDetail>();

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/[^/]+\/technicaldrawing\/([^/]+)\/barcode\/[^/]+/);
    
    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    const barcodeMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/[^/]+\/technicaldrawing\/[^/]+\/barcode\/([^/]+)/);

    let barcode = "";
    if (barcodeMatch) {
        barcode = barcodeMatch[1];
    }

    const taskIdMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/([^/]+)\/technicaldrawing\/[^/]+\/barcode\/[^/]+/);
    
    let taskId = "";
    if (taskIdMatch) {
        taskId = taskIdMatch[1];
    }

    const fetchSuppliers = async (supplierId: string) => {
        const response = await client.get(GET_SUPPLIER.replace("{id}", supplierId));
        if (response.data) {
            const data: any = response.data.supplier;
            console.log(data);
            setManSites(data.manufacturingSites);
            return;
        }
        else {
          console.log(response);
        }
        return [];
    }

    const [mansites, setManSites] = useState<ManufacturingSite[]>([]);

    const getSitesOptions = () => {
        const mappedOptions = mansites?.map((site) => ({
            label: site.name,
            value: site.id,
            description: site.country,
        }));

        return mappedOptions;
    }

    const navigate = useNavigate();

    const downloadMasterTechnicalDrawing = async() => {  

        const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", mpnDetails?.techDrawing.versions[1].id));
        window.open(response.data.url, '_blank');
          
    }

    const fetchPackName = async (mpnId: string) => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', mpnId));
        if (response.data) {
            console.log(response.data.masterPackName);
            setMPNDetails(response.data.masterPackName);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data.packageJob);
            await fetchSuppliers(response.data.packageJob.supplierId);
            const barcodeObject = response.data.packageJob.barcodes.filter((obj) => obj.barcode == barcode);
            await fetchPackName(barcodeObject[0].masterPackNameId);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const [showFileSuccessAlert, setShowFileSuccessAlert] = useState(false);

    const uploadFile = async() => {
        if (fileValue && fileValue[0]) {
            try {
                const formData = new FormData();
                formData.append('additionalFile', fileValue[0]);

                const qParams = {
                    fileName: 'techDrawingFile.pdf',
                    openTaskId: taskId,
                }
        
                const response = await client.post((POST_PACKAGING_TECH_DRAWING_FILE.replace("{id}", id).replace("{barcode}", barcode)), formData, {params: qParams});

                if (response.data) {
                    console.log(response.data);
                } else {
                    console.log(response);
                }
                setShowFileSuccessAlert(true);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    }

    const saveData = () => {
        navigate('/home');
    }

    useEffect(() => {
        const fetchJobs = async () => {
            await fetchJobDetail();
        };
        fetchJobs()
    }, []);
  
    if (!packagingJob) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }
    
    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
                <Container
                    header={
                        <Header variant="h2">
                            {packagingJob?.barcodes[0].masterPackName}
                        </Header>
                    }
                >
                 <SpaceBetween direction="vertical" size="m">
                    <ValueWithLabel label="Barcode">{packagingJob?.barcodes[0].barcode}</ValueWithLabel>

                    <div>
                            <FormField label="Master technical drawing" 
                            description="View the master technical drawing as a reference if needed">
                            <Button
                                ariaLabel="Download"
                                iconAlign="left"
                                iconName="file-open"
                                onClick={downloadMasterTechnicalDrawing}
                                >
                            View file
                            </Button>
                        </FormField>
                        </div>
                </SpaceBetween>   
                </Container>

                <Container
                    header={
                        <Header variant="h2"
                            actions={
                                <Button onClick={uploadFile}>Upload</Button>
                            }
                        >
                            Technical drawing
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="m">
                        <FileUpload
                            onChange={({ detail }) => setFileValue(detail.value as File[])}
                            value={fileValue}
                            i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                        />

                        {showFileSuccessAlert && <Alert 
                            dismissible
                            statusIconAriaLabel="Success"
                            type="success">
                            File uploaded successfully!
                        </Alert>}
                    </SpaceBetween>
                </Container>

                <div style={{width: '100%'}}>
                    <div style={{float: 'right'}}>
                        <SpaceBetween size="s" direction="horizontal" >
                            <Button onClick={() => navigate('/home')}>Cancel</Button>
                            <Button variant="primary" onClick={saveData}>Save data</Button>
                        </SpaceBetween>
                    </div>
                </div>
            </SpaceBetween>
        </div>
    )
}

export class ProvideTechnicalDrawing extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                navigationHide={true}
                content={
                <ContentLayout
                    header={
                    <Header
                        data-custom-color="black"
                        variant="awsui-h1-sticky"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Fill out the needed information for the job"
                    >
                        Technical data
                    </Header>
                    }
                >
                    <ProvideTechnicalDataContent />
                </ContentLayout>
                }
                breadcrumbs={<Breadcrumbs />}
            />
            </body>
        )
    }
}