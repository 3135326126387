import React, { useEffect, useState } from "react";
import { PackagingJobProps } from "./BasicInput";
import { Alert, Box, Button, ColumnLayout, Container, DatePicker, FormField, Header, Link, Multiselect, RadioGroup, Select, SpaceBetween, Spinner, StatusIndicator, TokenGroup } from "@amzn/awsui-components-react";
import { PackagingBarcode, PackagingDetails, PackagingJob } from "../NewPackaging";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { ARTWORK_REMINDER, CREATE_ARTWORK_REQUEST, GET_PACKAGING_JOB_DETAIL, GET_USER_LIST } from "src/config/urlConfig";
import { UserResponse } from "src/pages/admin/Users";

const ValueWithLabel = ({ label, children } : {label: string, children: any})  => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

interface Users {
    id: string;
    name: string;
}

const ArtworkContainer = ({index, packaging, job}: 
    {
        index:number, 
        packaging: PackagingBarcode,
        job: PackagingJob,
    }) => {
        
    const [dueDate, setDueDate] = useState("");

    const [currentArtworkPoc, setCurrentArtworkPoc] = useState<any>({value: "Susan Meyer"});
    const [artworkPocs, setArtworkPocs] = useState<any>([]);
    const [artworkPocItems, setArtworkPocItems] = useState<any[]>([]);
    const [artworkDelivered, setArtworkDelivered] = useState(false);

    const [currentArtworkReviewer, setCurrentArtworkReviewer] = useState<any>({value: "Packaging Engineer 1"});
    const [artworkReviewers, setArtworkReviewers] = useState<any>([]);
    const [artworkReviewerItems, setArtworkReviewerItems] = useState<any[]>([]);

    const [requestedInfo, setRequestedInfo] = useState(false);
    const [reminderSent, setReminderSent] = useState(false);

    const client = createClient();

    const navigate = useNavigate();

    const location = useLocation();

    const getUserIds = (users: any[]) => {
        let ids: string[] = [];
        for (const user of users) {
            ids.push(user.value);
        }
        return ids;
    }

    const createArtworkRequest = async() => {
        const reqBody = {
                "requests": [
                    {
                        "barcode": packaging.barcode,
                        "dateDue": Date.parse(dueDate),
                        "externalCompanyType": "SUPPLIER",
                        "artworkPocUserIds": getUserIds(artworkPocs),
                        "artworkReviewerUserIds": getUserIds(artworkReviewers)
                    }
                ]
        }
        
        const response = await client.post(CREATE_ARTWORK_REQUEST.replace("{id}", job.id), reqBody);
        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }
        return;
    }

    const sendRequestReminder = async() => {
        const response = await client.put(ARTWORK_REMINDER.replace("id", job.id).replace("{barcode}", packaging.barcode));
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }
    
    const handleContainerButtonClick = (props: any) => {
        if (requestedInfo == false) {
            setRequestedInfo(true);
            createArtworkRequest();
            return;
        }
        if (reminderSent == false) {
            setReminderSent(true);
            sendRequestReminder();
            setArtworkDelivered(true);
            return;
        }
        navigate('/pe/newpackaging/review/artwork/');
    }

    function getContainerButtonText() {
        if (reminderSent == true) {
            // TODO: The backend calls will change this part, we will keep the reminder sent button unless
            // we get the data back from the supplier
            // It will be displayed only if the current user is the reviewer
            return "Review";
        }
        if (requestedInfo == true) {
            return "Send reminder";
        }
        return "Request information";
    }

    function getStatusText() {
        const data = location.state || {};

        if (data && data.reviewStatus) {
            if (data.reviewStatus == "approved") {
                return "Approved";
            }
            else return "Rejected"
        }

        if (reminderSent == true) {
            // TODO: This logic will change depending upon whether we have the data back from supplier
            return "Waiting for reviewer"
        }
        if (requestedInfo == true) {
            return "Waiting for supplier";
        }
        return "Requesting artwork";
    }

    function getStatusType() {
        const data = location.state || {};

        if (data && data.reviewStatus) {
            if (data.reviewStatus == "approved") {
                return "success";
            }
            else return "error";
        }
        return "pending";
    }

    const [supplierUsers, setSupplierUsers] = useState<any[]>([]);
    const [peUsers, setPeUsers] = useState<any[]>([]);
    const [pocUsers, setPocUsers] = useState<any[]>([]);

    const fetchSupplierUsers = async() => {
        const response = await client.get(GET_USER_LIST);
        if (response.data) {
            const data: UserResponse[] = response.data.users;

            const currentSupplierUsers = data.filter((obj) => obj.externalCompanyId == job.supplierId);
            const parsedDataSupplier = currentSupplierUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setSupplierUsers(parsedDataSupplier);

            const currentPEUsers = data.filter((obj) => obj.roles.includes("PACKAGING_ENGINEER"));

            const parsedDataPE = currentPEUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setPeUsers(parsedDataPE);

            const currentAdminUsers = data.filter((obj) => obj.roles.includes("ADMIN"));

            const parsedDataAdmin = currentAdminUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            console.log(currentAdminUsers);

            setPocUsers(parsedDataSupplier.concat(parsedDataAdmin));
        
            return response.data.users;
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        const fetchUserList = async () => {
            await fetchSupplierUsers();
        };
        fetchUserList()
    }, []);

    return (
    <Container
                header={
                    <Header variant="h2"
                        info={
                            <Link>Info</Link>
                        }
                        description={
                                <StatusIndicator type={getStatusType()}>
                                    {getStatusText()}
                                </StatusIndicator>
                        }
                        actions={
                            <Button onClick={handleContainerButtonClick}>{getContainerButtonText()}</Button>
                        }
                    >
                        {packaging.masterPackName + "(" + packaging.barcode + ")"}
                    </Header>
                    }
            >
                <SpaceBetween direction="vertical" size="l">
                {!requestedInfo && 
                    <Alert type="info">The fields cannot be altered after requesting information</Alert>
                }
                {!requestedInfo && <FormField
                    label="Due date"
                    >
                        <DatePicker
                            onChange={({ detail }) => setDueDate(detail.value)}
                            value={dueDate}
                            placeholder="YYYY/MM/DD"
                        />
                </FormField>}
                {requestedInfo && <ValueWithLabel label="Due date">{dueDate}</ValueWithLabel>}
                {!requestedInfo && <FormField label="Point of contact">
                        <Multiselect 
                                    selectedOptions={artworkPocs}
                                    onChange={({ detail }) =>
                                        setArtworkPocs(detail.selectedOptions)
                                    }
                                    options={pocUsers}
                        />
                    
                </FormField>}
                {requestedInfo && <ValueWithLabel label="Point of contact">{artworkPocs.map(item => item.label).join(', ')}</ValueWithLabel>}
                {reminderSent && <StatusIndicator type="success">Delivered</StatusIndicator>}
                {!requestedInfo && <FormField label="Reviewer">
                    <Multiselect
                                    selectedOptions={artworkReviewers}
                                    onChange={({ detail }) =>
                                        setArtworkReviewers(detail.selectedOptions)
                                    }
                                    options={peUsers}
                                />
                </FormField>}
                {requestedInfo && <ValueWithLabel label="Reviewer">{artworkReviewers.map(item => item.label).join(', ')}</ValueWithLabel>}
                </SpaceBetween>
        </Container>
)}
                

export const Artwork: React.FC<PackagingJobProps> = ({job, onJobDetailsChange}) => {
    const [packagingJob, setPackagingJob] = useState<PackagingJob>();
    
    const client = createClient();

    const { jobId } = useParams<{ jobId: string }>();

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", jobId || ""));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchJob = async () => {
            await fetchJobDetail();
        };
        fetchJob()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner size="big" />
            </div>
        )
    }
    
    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Container
                header={
                        <Header variant="h2">
                            Supplier
                        </Header>
                        }
                    >
                    <SpaceBetween direction="vertical" size="l">
                    <ValueWithLabel label="Supplier name">{packagingJob.supplierName}</ValueWithLabel>
                    </SpaceBetween>
            </Container>
            {packagingJob.barcodes.map((barcode, index) => (
                <ArtworkContainer index={index} packaging={packagingJob.barcodes[index]} job={packagingJob}/>
            ))}
            </SpaceBetween>
        </div>
        )
}