import React, {useEffect, useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {Navigation} from "../common/navigation";
import {
    Box,
    BreadcrumbGroup,
    Button,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";
import {packtypesBreadcrumbs} from "../common/breadcrumbs";
import {GET_PACK_TYPE} from "src/config/urlConfig";
import {PACK_TYPE_TABLE_COLUMN_DEFINITION, PACK_TYPE_TABLE_COLUMN_DISPLAY} from "../common/table-config";
import {createClient} from "src/client/AxiosClient";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={packtypesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export interface PackTypesResponse {
    id: string;
    name: string;
    productTypeId: string;
    productTypeName: string;
}

const PackTypeTable = (props: any) => {
    const [
      selectedItems,
      setSelectedItems
    ] = React.useState<any[]>([]);
    const [
        filteringText,
        setFilteringText
      ] = React.useState("");

    const [rawPackTypesData, setRawPackTypesData] = React.useState<PackTypesResponse[]>([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableItems, setTableItems] = useState<PackTypesResponse[]>([]);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(true);

    const client = createClient();
    const fetchPackTypes = async () => {
        const response = await client.get(GET_PACK_TYPE);
        if (response.data) {
            console.log(response.data.packTypes);
            const data: PackTypesResponse[] = response.data.packTypes;
            const parsedData = data.map(item => ({
                id: item.id,
                name: item.name,
                productTypeId: item.productTypeId,
                productTypeName: item.productTypeName,
            }));

            setTableItems(parsedData);
        
            return response.data.packTypes;
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchPackTypesList = async () => {
            setRawPackTypesData(await fetchPackTypes());
            setTableLoading(false);
        };
        fetchPackTypesList()
    }, []);
    
    return (
      <div>
      <Table
        onSelectionChange={({ detail }) =>
          {
            setSelectedItems(detail.selectedItems);
            setActionButtonDisabled(false);
          }
        }
        selectedItems={selectedItems}
        columnDefinitions={PACK_TYPE_TABLE_COLUMN_DEFINITION}
        columnDisplay={PACK_TYPE_TABLE_COLUMN_DISPLAY}
        items = {tableItems}
        loading={tableLoading}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No resources</b>
            </SpaceBetween>
          </Box>
        }
        // TODO: Filtering is a very complex functionality. Take time to understand what works best for APP
        filter={
          <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Find pack type"
            filteringAriaLabel="Filter pack type"
            onChange={({ detail }) =>
                setFilteringText(detail.filteringText)
            }
          />
        }
        header={
          <Header
            counter={"(" + tableItems.length + ")"}
            info={
                <Link id="main-info-link" variant="info">
                  Info
                </Link>
            }
            variant="h1"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <Button href="#" disabled={true}>Deactivate</Button>
                <Button variant="primary" href="/packtypes/add">
                  Add pack type
                </Button>
              </SpaceBetween>
            }
          >
            Pack types
          </Header>
        }
        pagination={
          <Pagination currentPageIndex={1} pagesCount={1} />
        }
      />
      </div>
    );
}

export class PackType extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout 
                    contentType="table"
                    content={
                      <PackTypeTable />
                    }
                    breadcrumbs={<Breadcrumbs />}
                    headerSelector="#h"
                    navigation={<Navigation activeHref="/packtypes" />}
                />
            </body>
        );
    }
}