import { Box, ColumnLayout, Container, FormField, Header, Input, Select, SpaceBetween } from "@amzn/awsui-components-react"
import { useEffect, useState } from "react";
import { createClient } from "src/client/AxiosClient";
import { SubPackTypesResponse } from "../SubPacktypes";
import { GET_SUB_PACK_TYPES } from "src/config/urlConfig";
import { PackNamesResponse } from "../PackNames";

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

interface PackNameChildProps {
    packNameDetail: PackNamesResponse;
    onPackNameDetailChange: (updatedDetails: Partial<PackNamesResponse>) => void;
}

export const BasicInformation: React.FC<PackNameChildProps> = ({packNameDetail, onPackNameDetailChange}) => {
    const [tableItems, setTableItems] = useState<any[]>([]);
    const [
        selectedOption,
        setSelectedOption
      ] = useState(tableItems[0]);

    const [packname, setPackname] = useState("");
    const [rawSubPackTypesData, setRawSubPackTypesData] = useState<SubPackTypesResponse[]>([]);

    const client = createClient()

    const fetchSubPackTypes = async () => {
        const response = await client.get(GET_SUB_PACK_TYPES);
        if (response.data) {
            const data: SubPackTypesResponse[] = response.data.subPackTypes;
            const parsedData = data.map(item => ({
                value: item.id,
                label: item.name,
            }));

            setTableItems(parsedData);
            setSelectedOption(parsedData[0])
      
          return response.data.subPackTypes;
        }
        else {
          console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchPackTypesList = async () => {
            setRawSubPackTypesData(await fetchSubPackTypes());
        };
        fetchPackTypesList()
    }, []);
    
    const handlePackNameDetailChange = (updatedDetails: Partial<PackNamesResponse>) => {
        onPackNameDetailChange(updatedDetails);
    };

    function getSelectedProductType() {
        if (rawSubPackTypesData.length > 0)
            return rawSubPackTypesData.filter(obj => obj.id === selectedOption.value)[0].productTypeName;
        return "-";
    }

    function getSelectedPackType() {
        if (rawSubPackTypesData.length > 0)
            return rawSubPackTypesData.filter(obj => obj.id === selectedOption.value)[0].packTypeName;
        return "-";
    }

    return (
        <div>
            <Container
                header={
                    <Header variant="h1" description="Choose the name and the sub pack type">
                        Pack name definition
                    </Header>
                }
            >
                <ColumnLayout columns={2}>
                    <div>
                        <SpaceBetween size="l" direction="vertical">
                           <FormField label="Sub pack type" description="The sub pack type influences the specs of the pack name">
                                <Select
                                    selectedOption={selectedOption}
                                    onChange={({ detail }) =>
                                        {
                                            setSelectedOption(detail.selectedOption);
                                            handlePackNameDetailChange({
                                                subPackTypeId: detail.selectedOption.value,
                                                subPackTypeName: detail.selectedOption.label,
                                                packTypeId: rawSubPackTypesData.filter(obj => obj.id === selectedOption.value)[0].packTypeId,
                                                packTypeName: rawSubPackTypesData.filter(obj => obj.id === selectedOption.value)[0].packTypeName,
                                                productTypeId: rawSubPackTypesData.filter(obj => obj.id === selectedOption.value)[0].productTypeId,
                                                productTypeName: rawSubPackTypesData.filter(obj => obj.id === selectedOption.value)[0].productTypeName,
                                            })
                                        }
                                    }
                                    options={tableItems}
                                />
                           </FormField>
                           <FormField label="Pack name" description="The pack name needs to be unique">
                                <Input
                                    onChange={({ detail }) => {
                                        setPackname(detail.value);
                                        handlePackNameDetailChange({
                                            name: detail.value
                                        })
                                    }}
                                    value={packname}
                                />
                           </FormField>
                        </SpaceBetween>
                    </div>
                    <div>
                        <SpaceBetween size="l" direction="vertical">
                            <ValueWithLabel label="Product type">{getSelectedProductType()}</ValueWithLabel>
                            <ValueWithLabel label="Pack type">{getSelectedPackType()}</ValueWithLabel>
                        </SpaceBetween>
                    </div>
                </ColumnLayout>
            </Container>
        </div>
    )
}