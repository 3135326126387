import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Alert, Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Link, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { supplierProvideArtworkBreadcrumbs } from "../common/breadcrumbs";
import YourPDFFile from "src/assets/test.pdf";
import { GET_DOCUMENT_FILE, GET_PACKAGING_JOB_DETAIL, GET_PACK_NAME, GET_SUPPLIER, POST_PACKAGING_ARTWORK_FILE, SUBMIT_ARTWORK, UPLOAD_ARTWORK_PACK_NAME } from "src/config/urlConfig";
import { createClient } from "src/client/AxiosClient";
import { JobDetails } from "../jobs/JobDetail";
import { ManufacturingSite } from "./ProvideSpecsEditor";
import { useNavigate, useParams } from "react-router-dom";
import { MPNDetail } from "../admin/PackNameDetail";
import axios from "axios";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={supplierProvideArtworkBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export const ProvideMasterArtworkContent = () => {

    const [artworkFileValue, setArtworkFileValue] = useState<File[]>([]);
    const [artworkUploaded, setArtworkUploaded] = useState(false);

    const [mpnDetails, setMPNDetails] = useState<MPNDetail>();

    const client = createClient();
    const navigate = useNavigate();

    const url: string = window.location.href;

    const { mpnId } = useParams<{ mpnId: string }>();

    const { taskId } = useParams<{ taskId: string }>();

    const fetchPackName = async () => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', mpnId || ""));
        if (response.data) {
            console.log(response.data.masterPackName);
            setMPNDetails(response.data.masterPackName);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const [showFileSuccessAlert, setShowFileSuccessAlert] = useState(false);

    const uploadMasterArtwork = async() => {
        if (artworkFileValue && artworkFileValue[0]) {
            try {
                const formData = new FormData();
                formData.append('artworkTest', artworkFileValue[0]);

                const qParams = {
                    fileName: 'masterArtworkFile.pdf',
                    openTaskId: taskId,
                }
        
                const response = await client.post(UPLOAD_ARTWORK_PACK_NAME.replace("{id}", mpnDetails?.id || ""), formData, {params: qParams});
        
                if (response.data) {
                    console.log(response.data);
                    setArtworkUploaded(true);
                    setShowFileSuccessAlert(true);
                } else {
                    console.error('Upload failed');
                }
            } catch (error) {
                console.error('Error uploading artwork:', error);
            }
        }
    }

    const viewMasterTechnicalDrawing = () => {
        window.open('/supplier/view/technicaldrawing/'+mpnId, '_blank');
    }

    useEffect(() => {
        const fetchMPN = async () => {
            await fetchPackName();
        };
        fetchMPN()
    }, []);
  
    if (!mpnDetails) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }

    return (
        <div>
            <SpaceBetween size="l" direction="vertical">
                <Container
                    header={
                        <Header variant="h2">
                            {mpnDetails.name}
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <div>
                            <FormField label="Master technical drawing" 
                            description="View the master technical drawing as a reference if needed">
                            <Button
                                ariaLabel="Download"
                                iconAlign="left"
                                iconName="file-open"
                                onClick={viewMasterTechnicalDrawing}
                                >
                            View file
                            </Button>
                        </FormField>
                        </div>
                    </SpaceBetween>
                </Container>


                <Container
                    header={
                        <Header
                            actions={
                                <Button onClick={() => uploadMasterArtwork()}>Upload</Button>
                            }
                        >
                            Master Artwork
                        </Header>
                    }
                >
                        <SpaceBetween direction="vertical" size="m">
                        <FormField label="Artwork">
                        <FileUpload
                            onChange={({ detail }) => setArtworkFileValue(detail.value)}
                            value={artworkFileValue}
                            i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                        />
                        </FormField>
                        {showFileSuccessAlert && <Alert 
                            dismissible
                            statusIconAriaLabel="Success"
                            type="success">
                            File uploaded successfully!
                        </Alert>}
                        </SpaceBetween>
                </Container>
                
                <div style={{width: '100%'}}>
                    <div style={{float: 'right'}}>
                        <SpaceBetween size="s" direction="horizontal" >
                            <Button variant="primary" onClick={() => navigate('/home')}>Go to home</Button>
                        </SpaceBetween>
                    </div>
                </div>
            </SpaceBetween>
        </div>
    )
}

export class ProvideMasterArtwork extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    navigationHide={true}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            variant="awsui-h1-sticky"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                            description="Fill out the needed information for the job"
                        >
                            Artwork
                        </Header>
                        }
                    >
                        <ProvideMasterArtworkContent />
                    </ContentLayout>
                    }
                    breadcrumbs={<Breadcrumbs />}
                />
            </body>
        )
    }
}
