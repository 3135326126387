import { Header } from "@amzn/awsui-components-react";
import { Component } from "react";

export class Forbidden extends Component {
    render() {
        return (
            <body className="awsui-visual-refresh">
                <div>
                    <Header variant="awsui-h1-sticky">
                        Amazon Packaging Portal
                    </Header>
                    <div>
                        <Header variant="h2">
                            403 - Forbidden
                        </Header>
                        You are not allowed to access this page
                    </div>
                </div>
            </body>
        )
    }
}