import React, {useState} from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {
    BreadcrumbGroup,
    Button,
    Checkbox,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {editProductTypesBreadcrumbs} from "../common/breadcrumbs";
import {EDIT_PRODUCT_TYPE} from "src/config/urlConfig";
import {useLocation, useParams} from "react-router-dom";
import {createClient} from "src/client/AxiosClient";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={editProductTypesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

interface ProductTypeDetailProps {
    id: string;
    name: string;
    jobSteps: any[];
}

const Content = (session: any) => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
  
    const elementDetails = location.state as ProductTypeDetailProps;

    // Steps setters
    const [barcodeChecked, setBarcodeChecked] = React.useState(true);
    const [dccodeChecked, setDCCodeChecked] = React.useState(elementDetails.jobSteps.includes('DC_CODE'));
    const [specsChecked, setSpecsChecked] = React.useState(elementDetails.jobSteps.includes('SPECS'));
    const [techDrawingChecked, setTechDrawingChecked] = React.useState(elementDetails.jobSteps.includes('TECHNICAL_DRAWING'));
    const [artworkChecked, setArtworkChecked] = React.useState(elementDetails.jobSteps.includes('ARTWORK'));
    const [reviewChecked, setReviewChecked] = React.useState(elementDetails.jobSteps.includes('REVIEW'));
    const [co2Checked, setCO2Checked] = React.useState(elementDetails.jobSteps.includes('CO2_CALCULATION'));
    const [predbChecked, setPredbChecked] = React.useState(elementDetails.jobSteps.includes('PRE_PACKAGING_DATABASE_UPDATE'));
    const [dbChecked, setDbChecked] = React.useState(elementDetails.jobSteps.includes('PACKAGING_DATABASE_UPDATE'));

    const [productTypeName, setProductTypeName] = useState(elementDetails?.name);

    const client = createClient();

    const getSteps = () => {
        let steps: any[] = [];
        if (barcodeChecked) {
            steps.push("BARCODE");
        }
        if (dccodeChecked) {
            steps.push("DC_CODE");
        }
        if (specsChecked) {
            steps.push("SPECS");
        }
        if (techDrawingChecked) {
            steps.push("TECHNICAL_DRAWING");
        }
        if (artworkChecked) {
            steps.push("ARTWORK");
        }
        if (reviewChecked) {
            steps.push("REVIEW");
        }
        if (co2Checked) {
            steps.push("CO2_CALCULATION");
        }
        if (predbChecked) {
            steps.push("PRE_PACKAGING_DATABASE_UPDATE");
        }
        if (dbChecked) {
            steps.push("PACKAGING_DATABASE_UPDATE");
        }
        return steps;
    }

    const editProductType = async () => {
        const reqBody = {
            "id": id,
            "name": productTypeName,
            "jobSteps": getSteps(),
        }
        const response = await client.put(EDIT_PRODUCT_TYPE.replace('{id}', elementDetails.id), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        window.location.href = '/producttypes';
        return;
    }

    return (
        <form onSubmit={event => event.preventDefault()}>
            <Form
                actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link">Cancel</Button>
                    <Button variant="primary" onClick={() => editProductType()}>Save product type</Button>
                </SpaceBetween>
                }
            >
                <SpaceBetween size="l">
                <Container
                header={<Header 
                            variant="h2" 
                            description="General information about the product type">
                            Product type
                        </Header>}
                >
                    <FormField 
                    label="Name" 
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }>
                        <Input onChange={({ detail }) => setProductTypeName(detail.value)} value={productTypeName}/>
                    </FormField>
                </Container>
                <Container
                header={<Header 
                    variant="h2" 
                    description="Select which steps are required for a creation job for all packagin with this product type">
                    Required Steps
                    </Header>}
                >
                <FormField label="Select required steps" stretch={true}
                    info={
                    <Link id="main-info-link" variant="info">
                        Info
                    </Link>
                    }
                    description="Only the selected steps will be shown in the creation job and filled in the database"
                >
                    <Checkbox onChange={({ detail }) => setBarcodeChecked(detail.checked)} checked={barcodeChecked} disabled>
                        Barcode
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setDCCodeChecked(detail.checked)} checked={dccodeChecked}>
                        DC Code
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setSpecsChecked(detail.checked)} checked={specsChecked}>
                        Specs
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setTechDrawingChecked(detail.checked)} checked={techDrawingChecked}>
                        Technical Drawing
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setArtworkChecked(detail.checked)} checked={artworkChecked}>
                        Artwork
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setReviewChecked(detail.checked)} checked={reviewChecked}>
                        Review
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setCO2Checked(detail.checked)} checked={co2Checked}>
                        CO2 Calculation
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setPredbChecked(detail.checked)} checked={predbChecked}>
                        Pre-packaging database update
                    </Checkbox>
                    <Checkbox onChange={({ detail }) => setDbChecked(detail.checked)} checked={dbChecked}>
                        Packaging database update
                    </Checkbox>
                </FormField>
                </Container>
                </SpaceBetween>
          </Form>
        </form>
    );
};

export class EditProductType extends React.Component {
    render() {
        const mainAppProps = this.props;
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                content={
                <ContentLayout
                    header={
                    <Header
                        variant="h1"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Select which steps are required for the product type"
                    >
                        Edit product type
                    </Header>
                    }
                >
                    <Content session={mainAppProps}/>
                </ContentLayout>
                }
                breadcrumbs={<Breadcrumbs />}
            />
            </body>
        );
    }
}