import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, Form, FormField, Header, Input, Link, RadioGroup, Select, SpaceBetween } from "@amzn/awsui-components-react";
import { addUsersBreadcrumbs } from "../common/breadcrumbs";
import { INTERNAL_USER_ROLE_OPTIONS, USER_COMPANY_OPTIONS} from "./config";
import axios from "axios";
import { CREATE_USER, GET_SUPPLIER_LIST } from "src/config/urlConfig";
import { SupplierResponse } from "../pe/steps/BasicInput";
import {createClient} from "src/client/AxiosClient";


const Breadcrumbs = () => (
    <BreadcrumbGroup items={addUsersBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const Content = (session: any) => {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userCompany, setUserCompany] = useState(USER_COMPANY_OPTIONS[0].value);

    const [userInternalRole, setUserInternalRole] = useState("");

    const [internalCompanySelected, setInternalCompanySelected] = useState(true);
    const [userExternalCompany, setUserExternalCompany] = useState("SUPPLIER");

    const [supplier, setSupplier] = useState("");

    const client = createClient();

    const [supplierItems, setSupplierItems] =useState<any[]>([]);
    const [
        supplierSelectedOption,
        setSupplierSelectedOption
      ] = useState(supplierItems[0]);

    const [agency, setAgency] = useState("");

    const addUser = async () => {

      if (!internalCompanySelected) {
          let roles: any[] = [];
          roles.push(userExternalCompany.toUpperCase());
          let reqBody = {
            "name": userName,
            "email": userEmail,
            "externalCompanyType": userExternalCompany.toUpperCase(),
            "roles": roles,
            "externalCompanyId": "",
          }
          if (userExternalCompany.toUpperCase() == "SUPPLIER") {
            reqBody.externalCompanyId = supplier;
          }

          console.log(reqBody);
          const response = await client.post(CREATE_USER.replace('{userCompany}', userCompany), reqBody);
          if (response.data) {
              console.log(response.data);
          }
          else {
              console.log(response);
          }
          window.location.href = '/users';
          return;
      }

      let roles: any[] = [];
      if (userInternalRole == 'ADMIN') {
        roles.push('ADMIN');
      } else if (userInternalRole == 'ADMIN, PACKAGING_ENGINEER') {
        roles.push('ADMIN');
        roles.push('PACKAGING_ENGINEER');
      } else if (userInternalRole == 'PACKAGING_ENGINEER') {
        roles.push('PACKAGING_ENGINEER');
      } else {
        roles.push('PROCUREMENT');
      }
      const reqBody = {
          "name": userName,
          "userCompany": userCompany,
          "email": userEmail,
          "roles": roles
      }
      const response = await client.post(CREATE_USER.replace('{userCompany}', userCompany), reqBody);
      if (response.data) {
          console.log(response.data);
      }
      else {
          console.log(response);
      }
      window.location.href = '/users';
      return;
  }

    const fetchSuppliers = async () => {
      const response = await client.get(GET_SUPPLIER_LIST);
      if (response.data) {
        const data: SupplierResponse[] = response.data.suppliers;
        const parsedData = data.map(item => ({
          label: item.name,
          value: item.id,
        }));

        setSupplierItems(parsedData);
        setSupplierSelectedOption(parsedData[0]);
    
        return response.data.suppliers;
      }
      else {
        console.log(response);
      }
      return [];
    }

    useEffect(() => {
      const fetchSupplierList = async () => {
          await fetchSuppliers();
      };
      fetchSupplierList()
    }, []);

      return (
          <form onSubmit={event => event.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" href="/users">Cancel</Button>
                  <Button variant="primary" onClick={() => addUser()}>Save user</Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween size="l">
              <Container
                header={<Header 
                          variant="h2" 
                          description="Insert the information about the user">
                          User information
                      </Header>}
              >
                <SpaceBetween size="l">
                    <FormField 
                        label="Name" 
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }>
                      <Input onChange={({ detail }) => setUserName(detail.value)} value={userName}/>
                    </FormField>
                    <FormField 
                        label="E-mail address"
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                    >
                        <Input onChange={({ detail }) => setUserEmail(detail.value)} value={userEmail}/>
                    </FormField>
                    <FormField label="Company" stretch={true}
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                        description="Select user company"
                        >
                        <RadioGroup
                            items={USER_COMPANY_OPTIONS}
                            value={userCompany}
                            onChange={event => {
                              setUserCompany(event.detail.value);
                              if (event.detail.value == USER_COMPANY_OPTIONS[1].value) {
                                setInternalCompanySelected(false);
                              }
                              else setInternalCompanySelected(true);
                            }}
                        />
                    </FormField>
                    <FormField label="External Company Type" stretch={true}
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                        description="Select external company type"
                        >
                        <RadioGroup
                            items={[
                              { label: 'Supplier', value: 'supplier', disabled: internalCompanySelected},
                              { label: 'Agency', value: 'agency', disabled: internalCompanySelected},
                            ]}
                            value={userExternalCompany}
                            onChange={event => {
                              {
                                setUserExternalCompany(event.detail.value);
                              }
                            }
                          }
                        />
                    </FormField>
                    {!internalCompanySelected && userExternalCompany.toLowerCase() == "supplier" &&
                    <FormField label="Supplier">
                        <Select
                          selectedOption={supplierSelectedOption}
                          onChange={({ detail }) =>
                              {
                                  setSupplierSelectedOption(detail.selectedOption);
                                  setSupplier(detail.selectedOption.value || "");
                              }
                          }
                          options={supplierItems}
                      />
                    </FormField>}
                    {!internalCompanySelected && userExternalCompany.toLowerCase() == "agency" &&
                    <FormField label="Agency">
                        <Input onChange={({ detail }) => setAgency(detail.value)}
                                value={agency}
                                placeholder="Enter agency name"/>
                    </FormField>}
                </SpaceBetween>
              </Container>
              <Container
                header={<Header 
                  variant="h2" 
                  description="The permissions define what the user has access to">
                  Permissions
                  </Header>}
              >
                <FormField label="Role" stretch={true}
                  info={
                    <Link id="main-info-link" variant="info">
                      Info
                    </Link>
                  }
                  description="Select a role to set the permission"
                >
                  <SpaceBetween size="l">
                    <RadioGroup
                            items={[
                              { label: 'Packaging Engineer', value: 'PACKAGING_ENGINEER', disabled: !internalCompanySelected},
                              { label: 'Admin & Packaging Engineer', value: 'ADMIN, PACKAGING_ENGINEER', disabled: !internalCompanySelected},
                              { label: 'Admin', value: 'ADMIN', disabled: !internalCompanySelected },
                              //{ label: 'Procurement', value: 'PROCUREMENT', disabled: !internalCompanySelected },                               //removed Procurement for now as not needed
                          ]}
                            value={userInternalRole}
                            onChange={event => setUserInternalRole(event.detail.value)}
                    />
                  </SpaceBetween>
                </FormField>
              </Container>
              </SpaceBetween>
            </Form>
          </form>
        );
};

export class AddUser extends React.Component {
    render() {
        const mainAppProps = this.props;
        return (
            <body className='awsui-visual-refresh'>
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
              <GlobalNav  />
            </div>
            <CustomAppLayout
            headerSelector="#h"
            navigationOpen={false}
            content={
              <ContentLayout
                header={
                  <Header
                    variant="h1"
                    info={
                      <Link id="main-info-link" variant="info">
                        Info
                      </Link>
                    }
                    description="Add a user that should have access to APP"
                  >
                    Add user
                  </Header>
                }
              >
                <Content session={mainAppProps}/>
              </ContentLayout>
            }
            breadcrumbs={<Breadcrumbs />}
          />
          </body>
          );
        }
}
