import {
    Box,
    BreadcrumbGroup,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    Link,
    SpaceBetween,
    Spinner,
    StatusIndicator
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {packnameDetailsBreadcrumbs} from "../common/breadcrumbs";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {Navigation} from "../common/navigation";
import {GET_DOCUMENT_FILE, GET_PACK_NAME} from "src/config/urlConfig";
import {useParams} from "react-router-dom";
import YourPDFFile from "src/assets/test.pdf";
import TechnicalDrawingFile from "src/assets/technicaldrawing.pdf";
import ArtworkFile from 'src/assets/artwork.pdf';
import {Document, Page, pdfjs} from 'react-pdf';
import {createClient} from "src/client/AxiosClient";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Breadcrumbs = () => (
    <BreadcrumbGroup items={packnameDetailsBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export interface MasterPackNameSpecFieldsVersionModel {
    draftVersion: string;
    approvedVersion: string;
    versions: MasterPackNameSpecFieldMap
}

export interface MasterPackNameDocumentModel {
    id: string;
    requested: boolean;
    fileName?: string;
    dueDate?: Date;
    externalCompanyType?: string;
    externalCompanyId?: string;
    updatedByUserId?: string;
    updatedByUserName?: string;

    approval?: ApprovalModel
    comments?: CommentModel[];
}

export interface MasterPackNameDocumentMap {
    key: string;
    value: MasterPackNameDocumentModel[];
}

export interface MasterPackNameSpecFieldMap {
    key: string;
    value: MasterPackNameSpecField[];
}

export interface MasterPackNameSpecField {
    id: string;
    value: string;
    minValue: number;
    maxValue: number;

    label: string;
    unit: string;
    category: string;
    creationOfMPN: boolean;
    filledBySupplier: boolean;
    supplierUI: boolean;
}

export interface MasterPackNameDocumentVersionModel {
    draftVersion: string;
    approvedVersion: string;
    versions: MasterPackNameDocumentMap;
}

export interface MPNDetail {
    id: string;
    name: string;
    subPackTypeId: string;
    subPackTypeName: string;
    packTypeId: string;
    packTypeName: string;
    productTypeId: string;
    productTypeName: string;

    specFields: MasterPackNameSpecFieldsVersionModel
    techDrawing: MasterPackNameDocumentVersionModel
    artwork: MasterPackNameDocumentVersionModel

    approval: ApprovalModel
    comments?: CommentModel[];
}

export interface CommentModel {
    id: string;
    message: string;
    date: Date;
    referenceFileName?: string;
    commentType?: string;
}

export interface ApprovalModel {
    approvalStatus: string;
    approverUserId?: string;
    approverUserName?: string;
}

const PackNameDetailContent = (props: any) => {

    const { id } = useParams<{ id: string }>();

    const client = createClient();

    const [mpnDetails, setMPNDetails] = useState<MPNDetail>();

    const [techDrawingURL, setTechDrawingURL] = useState<string | null>(null);
    const [artworkURL, setArtworkURL] = useState<string | null>(null);

    const fetchPackName = async () => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', id || ""));
        if (response.data) {
            console.log(response.data.masterPackName);
            setMPNDetails(response.data.masterPackName);
            await fetchTechDrawing(response.data.masterPackName);
            await fetchArtwork(response.data.masterPackName);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchTechDrawing = async (details: MPNDetail) => {
        if (!details.techDrawing) {
            return;
        }
        if (details.approval.approvalStatus == "APPROVED") {
            const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", details.techDrawing?.versions[details.techDrawing?.approvedVersion].id))
            if (response.data) {
                setTechDrawingURL(response.data.url);
            }
            console.log(response);
        }
        else {
            const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", details.techDrawing?.versions[details.techDrawing?.approvedVersion].id))
            if (response.data) {
                setTechDrawingURL(response.data.url);
            }
            console.log(response);
        }
    }

    const fetchArtwork = async (details: MPNDetail) => {
        if (!details.artwork) {
            return;
        }
        if (details.approval.approvalStatus == "APPROVED") {
            const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", details.artwork?.versions[details.artwork?.approvedVersion].id))
            if (response.data) {
                setArtworkURL(response.data.url);
            }
            console.log(response);
        }
        else {
            const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", details.artwork?.versions[details.artwork?.approvedVersion].id))
            if (response.data) {
                setArtworkURL(response.data.url);
            }
            console.log(response);
        }
    }

    function getStatus() {
        if (mpnDetails?.approval.approvalStatus == "APPROVED") {
            return "success";
        }
        return "pending"
    }

    useEffect(() => {
        const fetchPackNameDetails = async () => {
            await fetchPackName();
        };
        fetchPackNameDetails();
    }, []);

    if (!mpnDetails) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    const specFieldsByCategory: { [key: string]: MasterPackNameSpecField[] } = {};
    mpnDetails.specFields.versions[1].forEach((field: MasterPackNameSpecField) => {
        if (!specFieldsByCategory[field.category]) {
            specFieldsByCategory[field.category] = [];
        }
        specFieldsByCategory[field.category].push(field);
    });

    return (
        <div>
            <SpaceBetween size="xl" direction="vertical">
                <Container
                    header={
                        <Header variant="h2"
                            description={
                                <StatusIndicator type={getStatus()}>{mpnDetails.approval.approvalStatus}</StatusIndicator>
                            }
                        >
                            {mpnDetails.name}
                        </Header>
                    }
                >
                    <SpaceBetween size="l" direction="vertical">
                        <ValueWithLabel label="Pack type">{mpnDetails.packTypeName}</ValueWithLabel>
                        <ValueWithLabel label="Sub pack type">{mpnDetails.subPackTypeName}</ValueWithLabel>
                        <ValueWithLabel label="Product type">{mpnDetails.productTypeName}</ValueWithLabel>
                    </SpaceBetween>
                </Container>
                <Container
                    header={
                        <Header variant="h2">
                            Master specs
                        </Header>
                    }
                >
                    <div>
                    <SpaceBetween size="l" direction="vertical">
                    {Object.entries(specFieldsByCategory).map(([category, fields]) => (
                        <div>
                        <Header variant="h3">{category}</Header>
                            <SpaceBetween size="l" direction="vertical">
                            <ColumnLayout columns={3}>
                                {fields.map(field => (
                                    
                                        <div><ValueWithLabel label={field.label}>{field.value} {field.unit}</ValueWithLabel></div>
                                    
                                ))}
                                </ColumnLayout>
                            </SpaceBetween>
                        </div>
                    ))}
                    </SpaceBetween>
                </div>
                </Container>
                <Container
                    header={
                        <Header variant="h2">
                            Master technical drawing
                        </Header>
                    }
                >
                    {techDrawingURL && (
                    <div style={{height: "82rem"}}>
                        <iframe style={{width: "100%", height: "80rem", overflow: "auto"}} src={techDrawingURL}/>
                    </div>
                    )}
                </Container>
                <Container
                    header={
                        <Header variant="h2">
                            Master artwork
                        </Header>
                    }
                >
                     {artworkURL && (
                    <div style={{height: "82rem"}}>
                        <iframe style={{width: "100%", height: "80rem", overflow: "auto"}} src={artworkURL}/>
                    </div>
                    )}
                </Container>
            </SpaceBetween>
        </div>
    )
}

export class PackNameDetail extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
            <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
              <GlobalNav  />
            </div>
            <CustomAppLayout
            content={
              <ContentLayout
                header={
                  <Header
                    variant="h1"
                    info={
                      <Link id="main-info-link" variant="info">
                        Info
                      </Link>
                    }
                    description="Detailed data for specific pack name"
                  >
                    Pack name Details
                  </Header>
                }
              >
                <PackNameDetailContent />
              </ContentLayout>
            }
            breadcrumbs={<Breadcrumbs />}
            headerSelector="#h"
            navigation={<Navigation activeHref="/packname/detail" />}
            navigationOpen={false}
          />
          </body>
        )
    }
}