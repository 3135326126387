import React, { forwardRef } from 'react';
import { AppLayout, AppLayoutProps, Badge, Box, Button, Link, SpaceBetween } from '@amzn/awsui-components-react';

import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import enMessages from '@amzn/awsui-components-react/polaris/i18n/messages/all.en.json';

export const CustomAppLayout = forwardRef<AppLayoutProps.Ref, AppLayoutProps>((props, ref) => {
    return (
      <I18nProvider locale="en" messages={[enMessages]}>
        <AppLayout ref={ref} {...props} />
      </I18nProvider> 
    );
});