import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Link, Modal, Multiselect, Select, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import { supplierProvideTechnicalDataBreadcrumbs } from "../common/breadcrumbs";
import { JobDetails } from "../jobs/JobDetail";
import { createClient } from "src/client/AxiosClient";
import { GET_PACKAGING_JOB_DETAIL, GET_SUPPLIER, POST_PACKAGING_TECH_DRAWING_FILE, SUBMIT_SPECS } from "src/config/urlConfig";
import { PackagingBarcode } from "../pe/NewPackaging";
import { useNavigate } from "react-router-dom";
import { ManufacturingSite } from "./ProvideSpecsEditor";
import FlashbarTest from "src/components/Flashbar";
import useBroadcastChannel from "./useBroadcastChannel";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={supplierProvideTechnicalDataBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children }: { label: string, children: any }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

const SiteContainer = ({ onRemove, mansites, packagingJob }: {
    onRemove: () => void,
    mansites: ManufacturingSite[],
    packagingJob: JobDetails
}) => {
    const [selectedOption, setSelectedOption] = React.useState<any>();

    const getSitesOptions = () => {
        const mappedOptions = mansites?.map((site) => ({
            label: site.name,
            value: site.id,
            description: site.country,
        }));

        return mappedOptions;
    }

    const navigate = useNavigate();

    const openSpecsEditor = () => {
        navigate('/supplier/providedata/specs/' + packagingJob.id + '/barcode/' + packagingJob.barcodes[0].barcode + '/site/' + selectedOption.label, { state: mansites[0] });
    }

    return (
        <div>
            <Container
                header={
                    <Header
                        actions={
                            <Button onClick={onRemove}>
                                Remove
                            </Button>
                        }
                    >
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="m">

                    <FormField label="Manufacturing sites"
                        description="Select all the manufacturing sites where you want to produce this packaging"
                    >
                        <div>Just a test message.</div>
                        <Select
                            selectedOption={selectedOption}
                            onChange={({ detail }) => {
                                setSelectedOption(detail.selectedOption);
                            }}
                            options={getSitesOptions()}
                        />
                    </FormField>

                    <div>
                        <Button onClick={openSpecsEditor}>Open specs editor</Button>
                    </div>
                </SpaceBetween>
            </Container>
        </div>
    )
}

export const ProvideTechnicalDataContent = () => {

    const [packagingJob, setPackagingJob] = useState<JobDetails>();
    const [currentBarcode, setCurrentBarcode] = useState<PackagingBarcode>();

    const [showFlashbar, setShowFlashbar] = useState(false);
    const [submitReady, setSubmitReady] = useState(false);

    useBroadcastChannel("spec_channel", (message) => {
        if (message.data === "Specs_saved") {
            setShowFlashbar(true);
            setSubmitReady(true);
        }
    });

    const [
        selectedOptions,
        setSelectedOptions
    ] = React.useState<any>([]);

    const [selectedOption, setSelectedOption] = React.useState<any>();

    const [fileValue, setFileValue] = React.useState<File[]>([]);

    const [sameSpecsChecked, setSameSpecsChecked] = React.useState(false);

    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    const [siteContainerCount, setSiteContainerCount] = useState(1);

    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [sameSpecsModalVisible, setSameSpecsModalVisible] = useState(false);

    const client = createClient();

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/[^/]+\/specs\/([^/]+)\/barcode\/[^/]+/);

    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    const barcodeMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/[^/]+\/specs\/[^/]+\/barcode\/([^/]+)/);

    let barcode = "";
    if (barcodeMatch) {
        barcode = barcodeMatch[1];
    }

    const [mansites, setManSites] = useState<ManufacturingSite[]>([]);

    const getSitesOptions = () => {
        const mappedOptions = mansites?.map((site) => ({
            label: site.name,
            value: site.id,
            description: site.country,
        }));

        return mappedOptions;
    }

    const navigate = useNavigate();

    const openSpecsEditor = () => {
        const serializedState = encodeURIComponent(JSON.stringify(selectedOptions));
        let currentBc = "";
        if (currentBarcode) {
            currentBc = currentBarcode.barcode;
        }

        const url = `/supplier/providedata/specs/${packagingJob?.id}/barcode/${currentBc}?state=${serializedState}`;
        window.open(url, '_blank');
        // navigate('/supplier/providedata/specs/'+packagingJob?.id+'/barcode/'+packagingJob?.barcodes[0].barcode+'/site/'+mansites[0].name, {state: mansites[0]});
    }

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data.packageJob);
            setCurrentBarcode(response.data.packageJob.barcodes.filter((obj) => obj.barcode == barcode)[0])
            await fetchSuppliers(response.data.packageJob.supplierId);
            return response.data.packageJob;
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchSuppliers = async (supplierId: string) => {
        console.log("supplier: " + packagingJob?.supplierName + supplierId);
        const response = await client.get(GET_SUPPLIER.replace("{id}", supplierId));
        if (response.data) {
            const data: any = response.data.supplier;
            console.log(data);
            setManSites(data.manufacturingSites);
            return;
        }
        else {
            console.log(response);
        }
        return [];
    }

    const saveData = async () => {
        const taskIdMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/([^/]+)\/specs\/[^/]+\/barcode\/[^/]+/);

        let taskId = "";
        if (taskIdMatch) {
            taskId = taskIdMatch[1];
        }

        const reqBody = {
            "openTaskId": taskId
        }
        console.log(reqBody);

        const response = await client.put(SUBMIT_SPECS.replace("{id}", id), reqBody);

        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }

        navigate('/home');
    }

    const addSiteContainer = () => {
        if (siteContainerCount < mansites.length) {
            setSiteContainerCount(prevCount => prevCount + 1);
        }
    };

    const handleCancel = () => {
        setCancelModalVisible(true);
    }

    const handleRemoveSiteContainer = () => {
        if (siteContainerCount > 1) {
            setSiteContainerCount(prevCount => prevCount - 1);
        }
    };

    useEffect(() => {
        const fetchJobs = async () => {
            await fetchJobDetail();
        };
        fetchJobs()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner size="big" />
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
                <Container
                    header={
                        <Header variant="h2"
                            actions={
                                <Button onClick={addSiteContainer} disabled={addButtonDisabled}>
                                    Add manufacturing site with new specs
                                </Button>
                            }
                        >
                            {currentBarcode && currentBarcode.masterPackName}
                            {showFlashbar && (
                                <FlashbarTest
                                    messageType="success"
                                    message="Specs saved successfully! You may now Submit!"
                                    onDismiss={() => setShowFlashbar(false)}

                                />
                            )}
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="m">
                        <ValueWithLabel label="Barcode">{currentBarcode && currentBarcode.barcode}</ValueWithLabel>

                        <Toggle onChange={({ detail }) => {
                            setSameSpecsModalVisible(true);
                            //setSameSpecsChecked(detail.checked);

                        }}
                            checked={sameSpecsChecked}
                            disabled={sameSpecsChecked}
                        >
                            Same specs for all manufacturing sites
                        </Toggle>

                    </SpaceBetween>
                </Container>

                <Container>
                    <SpaceBetween direction="vertical" size="m">

                        <FormField label="Manufacturing sites"
                            description="Select all the manufacturing sites where you want to produce this packaging."
                        >
                            {sameSpecsChecked && <Multiselect
                                selectedOptions={selectedOptions}
                                onChange={({ detail }) => {
                                    setSelectedOptions(detail.selectedOptions);
                                }
                                }
                                options={getSitesOptions()}
                            />}
                            {!sameSpecsChecked &&
                                <Select
                                    selectedOption={selectedOption}
                                    onChange={({ detail }) => {
                                        setSelectedOption(detail.selectedOption);
                                        setSelectedOptions([detail.selectedOption]);
                                    }}
                                    options={getSitesOptions()}
                                />

                            }
                        </FormField>

                        <div>
                            <Button onClick={openSpecsEditor}>Open specs editor</Button>
                        </div>
                    </SpaceBetween>
                </Container>

                {Array.from({ length: siteContainerCount > 0 ? siteContainerCount - 1 : 0 }, (_, index) => (
                    <SiteContainer key={index} mansites={mansites} packagingJob={packagingJob}
                        onRemove={handleRemoveSiteContainer}
                    />
                ))}

                <div style={{ width: '100%' }}>
                    <div style={{ float: 'right' }}>
                        <SpaceBetween size="s" direction="horizontal" >
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button variant="primary" onClick={saveData} disabled={!submitReady}>Submit specs</Button>
                        </SpaceBetween>
                    </div>
                </div>

                {/* Cancel without saving modal */}
                <Modal
                    onDismiss={() => setCancelModalVisible(false)}
                    visible={cancelModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => setCancelModalVisible(false)}>Cancel</Button>
                                <Button variant="primary" onClick={() => {
                                    navigate('/home');
                                }}>Leave without saving</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header={<Header variant="h1">Leave without saving?</Header>}
                >
                    Do you really want to leave without saving?
                    Your inputs will be lost.
                </Modal>

                {/* Same specs confirmation modal. Irreversible action if saved */}
                <Modal
                    onDismiss={() => setSameSpecsModalVisible(false)}
                    visible={sameSpecsModalVisible}
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => setSameSpecsModalVisible(false)}>Cancel</Button>
                                <Button variant="primary" onClick={() => {
                                    setSameSpecsChecked(true);
                                    setAddButtonDisabled(true);
                                    setSameSpecsModalVisible(false);
                                }}>Use same specs</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header={<Header variant="h1">Use same specs for all manufacturing sites?</Header>}
                >
                    Caution: This is an irreversible action for the job
                </Modal>
            </SpaceBetween>
        </div>
    )
}

export class ProvideSpecs extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    navigationHide={true}
                    content={
                        <ContentLayout
                            header={
                                <Header
                                    data-custom-color="black"
                                    variant="awsui-h1-sticky"
                                    info={
                                        <Link id="main-info-link" variant="info">
                                            Info
                                        </Link>
                                    }
                                    description="Fill out the needed information for the job"
                                >
                                    Specs
                                </Header>
                            }
                        >
                            <ProvideTechnicalDataContent />
                        </ContentLayout>
                    }
                // breadcrumbs={<Breadcrumbs />}
                />
            </body>
        )
    }
}