import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Input, Link, Modal, SpaceBetween, Spinner, StatusIndicator } from "@amzn/awsui-components-react";
import { Document, Page, pdfjs } from 'react-pdf';
import { newPackagingArtworkBreadcrumbs } from "../common/breadcrumbs";
import ArtworkFile from 'src/assets/artwork.pdf';
import { useNavigate } from "react-router-dom";
import { JobDetails } from "../jobs/JobDetail";
import { createClient } from "src/client/AxiosClient";
import { SupplierResponse } from "../admin/Suppliers";
import { GET_DOCUMENT_FILE, GET_PACKAGING_JOB_DETAIL, GET_SUPPLIER, REVIEW_ARTWORK } from "src/config/urlConfig";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Breadcrumbs = () => (
    <BreadcrumbGroup items={newPackagingArtworkBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

const SupplierInfoContent = (props: any) => {
    return (
        <Container
            header={
                <Header variant="h2">
                    Supplier name
                </Header>
            }
        >
            <ColumnLayout columns={2} borders="vertical">
                <ValueWithLabel label="Supplier name">Supplier 3</ValueWithLabel>
                <ValueWithLabel label="Manufacturing sites">1</ValueWithLabel>
            </ColumnLayout>
        </Container>
    )
}

const ArtworkReviewContent = (props: any) => {
    const [rejectArtworkButtonDisabled, setRejectArtworkButtonDisabled] = useState(false);
    const [approveArtworkButtonDisabled, setApproveArtworkButtonDisabled] = useState(false);

    const [rejectArtworkModalVisible, setRejectArtworkModalVisible] = useState(false);

    const openRejectArtworkModal = () => {
        setRejectArtworkModalVisible(true);
    };

    const [approveArtworkModalVisible, setApproveArtworkModalVisible] = useState(false);

    const openApproveArtworkModal = () => {
        setApproveArtworkModalVisible(true);
    };

    const [reviewStatus, setReviewStatus] = useState("");

    const [rejectArtworkComment, setRejectArtworkComment] = useState("");
    const [fileValue, setFileValue] = React.useState<File[]>([]);

    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    const navigate = useNavigate();

    const client = createClient();
    
    const navigateToHome = () => {
        // save to backend the current state
        navigate('/home');
    }

    function getStatusText() {
        if (reviewStatus == "approved") {
            return "Approved";
        }
        if (reviewStatus == "rejected") {
            return "Rejected";
        }
        return "Approval pending";
    }

    function getStatusType() {
        if (reviewStatus == "approved") {
            return "success";
        }
        if (reviewStatus == "rejected") {
            return "error";
        }
        return "pending";
    }

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/review\/task\/[^/]+\/artwork\/([^/]+)\/barcode\/[^/]+/);

    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    let barcode = "";
    const pattern: RegExp = /\/barcode\/([^/#?]+)/;
    const barcodeMatch: RegExpMatchArray | null = url.match(pattern);

    if (barcodeMatch) {
        barcode = barcodeMatch[1];
    }

    const taskIdMatch: RegExpMatchArray | null = url.match(/\/review\/task\/([^/]+)\/artwork\/[^/]+\/barcode\/[^/]+/);
    let taskId = "";
    if (taskIdMatch) {
        taskId = taskIdMatch[1];
    }

    const [supplier, setSupplier] = useState<SupplierResponse>();

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data);
            await fetchSuppliers(response.data.packageJob.supplierId);
            await fetchReviewDocument(response.data.packageJob);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchSuppliers = async (supplierId: string) => {
        console.log("supplier: " + packagingJob?.supplierName + supplierId);
        const response = await client.get(GET_SUPPLIER.replace("{id}", supplierId));
        if (response.data) {
            const data: any = response.data.supplier;
            setSupplier(response.data.supplier);
            console.log(data);
            return;
        }
        else {
          console.log(response);
        }
        return [];
    }


    const [s3url, setS3Url] = useState<string | null>(null);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

    function onDocumentLoadSuccess() {
        setIsDocumentLoaded(true);
    }

    const fetchReviewDocument = async (job: JobDetails) => {
    const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", job.barcodes[0].manufacturingSites[0].artwork.id))
        if (response.data) {
            setS3Url(response.data.url);
        }
        console.log(response);
    }

    const approveDocument = async() => {

        const pattern: RegExp = /#([a-fA-F0-9-]+)$/;
        const siteMatch: RegExpMatchArray | null = url.match(pattern);
        let siteId = ""
        if (siteMatch) {
            siteId = siteMatch[1];
        }

        const qParams = {
            openTaskId: taskId
        }
        const response = await client.post(REVIEW_ARTWORK.replace("{id}", id).replace("{barcode}", barcode).replace("{manufacturingSiteId}", siteId).replace("{approvalStatus}", "APPROVED"), {params: qParams});
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    const rejectDocument = async() => {

        const pattern: RegExp = /#([a-fA-F0-9-]+)$/;
        const siteMatch: RegExpMatchArray | null = url.match(pattern);
        let siteId = ""
        if (siteMatch) {
            siteId = siteMatch[1];
        }

        const qParams = {
            openTaskId: taskId,
            comment: rejectArtworkComment
        }

        const response = await client.post(REVIEW_ARTWORK.replace("{id}", id).replace("{barcode}", barcode).replace("{manufacturingSiteId}", siteId).replace("{approvalStatus}", "REJECTED"), {params: qParams});
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }

    useEffect(() => {
        const fetchJobs = async () => {
            await fetchJobDetail();
        };
        fetchJobs();
    }, []);
  
    if (!packagingJob) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Container 
                header={
                <Header variant="h2"
                // TODO: Change status indicator text based on approval state
                    description={<StatusIndicator type={getStatusType()}>{getStatusText()}</StatusIndicator>}
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button disabled={rejectArtworkButtonDisabled} onClick={openRejectArtworkModal}>Reject</Button>
                            <Button disabled={approveArtworkButtonDisabled} variant="primary" onClick={openApproveArtworkModal}>Approve</Button>
                        </SpaceBetween>
                    }
                >
                    All manufacturing sites
                </Header>
            }
            >
                <ValueWithLabel label="Artwork">
                {s3url && (
                    <div style={{height: "82rem"}}>
                        <iframe style={{width: "100%", height: "80rem", overflow: "auto"}} src={s3url}/>
                    </div>
                )}
                </ValueWithLabel>
            </Container>
            <div style={{width: '100%'}}>
                <div style={{float: 'right'}}><Button onClick={navigateToHome}>Close Review</Button></div>
            </div>
            </SpaceBetween>
            {/* Approve artwork modal */}
            <Modal
                onDismiss={() => setApproveArtworkModalVisible(false)}
                visible={approveArtworkModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setApproveArtworkModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        approveDocument();
                        setApproveArtworkModalVisible(false);
                        setRejectArtworkButtonDisabled(true);
                        setApproveArtworkButtonDisabled(true);
                        setReviewStatus("approved");
                    }}>Approve</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Approve artwork</Header>}
            >
                Are you sure that you want to approve the artwork?
                Once approved, you will need to create a revision job to adjust the artwork
            </Modal>
            {/* Reject artwork Modal */}
            <Modal
                onDismiss={() => setRejectArtworkModalVisible(false)}
                visible={rejectArtworkModalVisible}
                footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setRejectArtworkModalVisible(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => {
                        rejectDocument();
                        setRejectArtworkModalVisible(false);
                        setRejectArtworkButtonDisabled(true);
                        setApproveArtworkButtonDisabled(true);
                        setReviewStatus("rejected");
                    }}>Reject</Button>
                    </SpaceBetween>
                </Box>
                }
                header={<Header variant="h1">Reject artwork</Header>}
            >
                <SpaceBetween size="l">
                    <FormField
                        label="Comment"
                        description="Explain what needs to be corrected"
                    >
                    <Input
                        onChange={({ detail }) => setRejectArtworkComment(detail.value)}
                        value={rejectArtworkComment}
                        placeholder="Enter comment"
                    />
                    </FormField>
                    <FormField
                        label="Upload file (optional)"
                        description="Upload a file if you need to provide additional information to the supplier."
                        >
                        <FileUpload
                            onChange={({ detail }) => setFileValue(detail.value)}
                            value={fileValue}
                            i18nStrings={{
                            uploadButtonText: e =>
                                e ? "Choose files" : "Choose file",
                            dropzoneText: e =>
                                e
                                ? "Drop files to upload"
                                : "Drop file to upload",
                            removeFileAriaLabel: e =>
                                `Remove file ${e + 1}`,
                            limitShowFewer: "Show fewer files",
                            limitShowMore: "Show more files",
                            errorIconAriaLabel: "Error"
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                            constraintText="Hint text for file requirements"
                        />
                        </FormField>
                </SpaceBetween>
            </Modal>
        </div>
    )
}

export class ArtworkReview extends React.Component {
    render () {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            variant="h1"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                        >
                            Review artwork
                        </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="xl">
                        <SupplierInfoContent/>
                        <ArtworkReviewContent/>
                        </SpaceBetween>
                    </ContentLayout>
                    }
                    breadcrumbs={<Breadcrumbs />}
                />
            </body>
        )
    }
}