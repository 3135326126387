import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Alert, Box, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, FileUpload, FormField, Header, Link, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { supplierProvideArtworkBreadcrumbs } from "../common/breadcrumbs";
import YourPDFFile from "src/assets/test.pdf";
import { GET_DOCUMENT_FILE, GET_PACKAGING_JOB_DETAIL, GET_PACK_NAME, GET_SUPPLIER, POST_PACKAGING_ARTWORK_FILE, SUBMIT_ARTWORK } from "src/config/urlConfig";
import { createClient } from "src/client/AxiosClient";
import { JobDetails } from "../jobs/JobDetail";
import { ManufacturingSite } from "./ProvideSpecsEditor";
import { useNavigate } from "react-router-dom";
import { MPNDetail } from "../admin/PackNameDetail";
import axios from "axios";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={supplierProvideArtworkBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export const ProvideArtworkContent = () => {

    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    const [mansites, setManSites] = useState<ManufacturingSite[]>([]);

    const [fileValue, setFileValue] = React.useState<{ [key: string]: File[] }>({});

    const [mpnDetails, setMPNDetails] = useState<MPNDetail>();

    const client = createClient();

    const url: string = window.location.href;
    const idMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/[^/]+\/artwork\/([^/]+)\/barcode\/[^/]+/);
    
    let id = "";
    if (idMatch) {
        id = idMatch[1];
    }

    const barcodeMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/[^/]+\/artwork\/[^/]+\/barcode\/([^/]+)/);

    let barcode = "";
    if (barcodeMatch) {
        barcode = barcodeMatch[1];
    }

    const taskIdMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/([^/]+)\/artwork\/[^/]+\/barcode\/[^/]+/);
    
    let taskId = "";
    if (taskIdMatch) {
        taskId = taskIdMatch[1];
    }

    const downloadMasterArtwork = async() => {  

        const response = await client.get(GET_DOCUMENT_FILE.replace("{id}", mpnDetails?.artwork.versions[1].id));
        window.open(response.data.url, '_blank');
          
    }

    const fetchPackName = async (mpnId: string) => {
        const response = await client.get(GET_PACK_NAME.replace('{id}', mpnId));
        if (response.data) {
            console.log(response.data.masterPackName);
            setMPNDetails(response.data.masterPackName);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchSuppliers = async (supplierId: string) => {
        const response = await client.get(GET_SUPPLIER.replace("{id}", supplierId));
        if (response.data) {
            const data: any = response.data.supplier;
            console.log(data);
            setManSites(data.manufacturingSites);
            return;
        }
        else {
          console.log(response);
        }
        return [];
    }

    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", id));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data);
            await fetchSuppliers(response.data.packageJob.supplierId);
            const barcodeObject = response.data.packageJob.barcodes.filter((obj) => obj.barcode == barcode);
            await fetchPackName(barcodeObject[0].masterPackNameId);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const [showFileSuccessAlert, setShowFileSuccessAlert] = useState(false);

    const uploadFile = async(siteKey: string, siteId: string) => {
        if (fileValue && fileValue[siteKey] && fileValue[siteKey][0]) {
            try {
                const formData = new FormData();
                formData.append('artworkFile', fileValue[siteKey][0]);

                const qParams = {
                    fileName: 'artworkFile.pdf',
                    openTaskId: taskId,
                }
        
                const response = await client.post((POST_PACKAGING_ARTWORK_FILE.replace("{id}", id).replace("{barcode}", barcode).replace("{manufacturingSiteId}", siteId)), formData, {params: qParams});

                if (response.data) {
                    console.log(response.data);
                } else {
                    console.log(response);
                }
                setShowFileSuccessAlert(true);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    }

    const navigate = useNavigate();

    const saveData = async() => {
        const taskIdMatch: RegExpMatchArray | null = url.match(/\/supplier\/providedata\/task\/([^/]+)\/artwork\/[^/]+\/barcode\/[^/]+/);
    
        let taskId = "";
        if (taskIdMatch) {
            taskId = taskIdMatch[1];
        }

        const reqBody = {
            "openTaskId": taskId
        }
        console.log(reqBody);

        const response = await client.put(SUBMIT_ARTWORK.replace("{id}", id), reqBody);

        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }

        navigate('/home');
    }

    useEffect(() => {
        const fetchJobs = async () => {
            await fetchJobDetail();
        };
        fetchJobs()
    }, []);
  
    if (!packagingJob) {
        return (
              <div>
                  <Spinner size="big" />
              </div>
          )
    }

    return (
        <div>
            <SpaceBetween size="l" direction="vertical">
                <Container
                    header={
                        <Header variant="h2">
                            {packagingJob?.barcodes[0].masterPackName}
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <ValueWithLabel label="Barcode">
                        {packagingJob?.barcodes[0].barcode}
                        </ValueWithLabel>
                        <ValueWithLabel label="Manufacturing sites">
                            {packagingJob.barcodes[0].manufacturingSites.map(site => site.name).join(', ')}
                        </ValueWithLabel>
                        <div>
                            <FormField label="Master artwork" 
                            description="View the master artwork as a reference if needed">
                            <Button
                                ariaLabel="Download"
                                iconAlign="left"
                                iconName="file-open"
                                onClick={downloadMasterArtwork}
                                >
                            View file
                            </Button>
                        </FormField>
                        </div>
                    </SpaceBetween>
                </Container>

                {packagingJob.barcodes[0].manufacturingSites.map((site, index) => {
                const siteKey = `site_${index}`;
                return (
                <Container
                    key={index}
                    header={
                        <Header
                            actions={
                                <Button onClick={() => uploadFile(siteKey, site.id)}>Upload</Button>
                            }
                        >
                            {site.name}
                        </Header>
                    }
                >
                        <SpaceBetween direction="vertical" size="m">
                        <FormField label="Artwork">
                            <FileUpload
                                onChange={({ detail }) => setFileValue(prevState => ({
                                    ...prevState,
                                    [siteKey]: detail.value, // Updating fileValue based on site key
                                }))}
                                value={fileValue[siteKey] || []}
                                i18nStrings={{
                                uploadButtonText: e =>
                                    e ? "Choose files" : "Choose file",
                                dropzoneText: e =>
                                    e
                                    ? "Drop files to upload"
                                    : "Drop file to upload",
                                removeFileAriaLabel: e =>
                                    `Remove file ${e + 1}`,
                                limitShowFewer: "Show fewer files",
                                limitShowMore: "Show more files",
                                errorIconAriaLabel: "Error"
                                }}
                                showFileLastModified
                                showFileSize
                                showFileThumbnail
                                tokenLimit={3}
                            />
                        </FormField>
                        {showFileSuccessAlert && <Alert 
                            dismissible
                            statusIconAriaLabel="Success"
                            type="success">
                            File uploaded successfully!
                        </Alert>}
                        </SpaceBetween>
                </Container>
                );
            })}
                
                <div style={{width: '100%'}}>
                    <div style={{float: 'right'}}>
                        <SpaceBetween size="s" direction="horizontal" >
                            <Button onClick={() => navigate('/home')}>Cancel</Button>
                            <Button variant="primary" onClick={saveData}>Save data</Button>
                        </SpaceBetween>
                    </div>
                </div>
            </SpaceBetween>
        </div>
    )
}

export class ProvideArtwork extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                    <GlobalNav  />
                </div>
                <CustomAppLayout
                    headerSelector="#h"
                    navigationOpen={false}
                    navigationHide={true}
                    content={
                    <ContentLayout
                        header={
                        <Header
                            variant="awsui-h1-sticky"
                            info={
                            <Link id="main-info-link" variant="info">
                                Info
                            </Link>
                            }
                            description="Fill out the needed information for the job"
                        >
                            Artwork
                        </Header>
                        }
                    >
                        <ProvideArtworkContent />
                    </ContentLayout>
                    }
                    breadcrumbs={<Breadcrumbs />}
                />
            </body>
        )
    }
}
