import { SpaceBetween, Container, Header, Box, Alert, Link, ColumnLayout, FormField, Input, Button, Spinner } from "@amzn/awsui-components-react"
import { PackagingJobProps } from "./BasicInput";
import { useEffect, useState } from "react";
import { createClient } from "src/client/AxiosClient";
import { GET_EPACK_FILE, GET_PACKAGING_JOB_DETAIL, POST_CO2_VALUE } from "src/config/urlConfig";
import { JobDetails } from "src/pages/jobs/JobDetail";
import { useParams } from "react-router-dom";

const ValueWithLabel = ({ label, children } : {label: string, children: any})  => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export const CO2: React.FC<PackagingJobProps> = ({job, onJobDetailsChange}) => {

    const [carbonValues, setCarbonValues] = useState<{ [key: string]: string }>({});
    const [packagingJob, setPackagingJob] = useState<JobDetails>();

    const client = createClient();
    const { jobId } = useParams<{ jobId: string }>();

    const submitCarbonValues = async() => {
        const barcodeValues = Object.keys(carbonValues).map((barcode) => ({
            barcode,
            value: parseFloat(carbonValues[barcode]),
        }));

        const reqBody = { barcodes: barcodeValues };
        
        const response = await client.post(POST_CO2_VALUE.replace("{id}", packagingJob?.id || ""), reqBody);
        if (response.data) {
            console.log(response.data);
        }
        else {
            console.log(response);
        }
    }
    
    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", jobId || ""));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const downloadEpackFile = async() => {
        const response = await client.get(GET_EPACK_FILE.replace("{id}", packagingJob ? packagingJob.id : ""), {
            responseType: 'blob',
          });
        if (response) {
            const contentDisposition = response.headers['content-disposition'];
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition || '');
            const filename = matches ? matches[1].replace(/['"]/g, '') : 'downloaded_file.csv';

            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', filename);
            downloadLink.style.display = 'none';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            console.log(`File downloaded: ${filename}`);
        }
        else {
            console.log(response);
        }
        return;
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchJobDetail();
        };
        fetchData()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner/>
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Alert statusIconAriaLabel="Info">If you have more packaging specific values, adjust the default values 
            to have a more accurate CO2 calculation</Alert>

            <Container>
                <SpaceBetween size="m" direction="vertical">
                     <ValueWithLabel label="Epack file">
                        <Button variant="primary" onClick={downloadEpackFile}>Download</Button>
                    </ValueWithLabel>
                </SpaceBetween>
            </Container>
            {/* {packagingJob.barcodes.map((barcode, index) => (
            <Container
                header={
                    <Header variant="h2"
                        info={
                            <Link>Info</Link>
                        }
                        actions={
                            <Button onClick={submitCarbonValue}>Submit</Button>
                        }
                    >
                        {packagingJob.barcodes[index].masterPackName + "(" + packagingJob.barcodes[index].barcode + ")"}
                    </Header>
                }
            >

                <SpaceBetween direction="vertical" size="m">
                    <FormField label="CO2 Value">
                        <Input onChange={({ detail }) => setCarbonValue(detail.value)}
                                value={carbonValue}/>
                    </FormField>
                    

                </SpaceBetween>
            </Container>
            ))} */}
            {packagingJob.barcodes.map((barcode, index) => (
                <Container
                    key={index}
                    header={
                        <Header
                            variant="h2"
                            info={<Link>Info</Link>}
                            actions={<Button onClick={submitCarbonValues}>Submit</Button>}
                        >
                            {packagingJob.barcodes[index].masterPackName +
                                "(" +
                                packagingJob.barcodes[index].barcode +
                                ")"}
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="m">
                        <FormField label="CO2 Value">
                            <Input
                                onChange={({ detail }) =>
                                    setCarbonValues((prevValues) => ({
                                        ...prevValues,
                                        [packagingJob.barcodes[index].barcode]: detail.value,
                                    }))
                                }
                                value={carbonValues[packagingJob.barcodes[index].barcode] || ""}
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            ))}
            </SpaceBetween>
        </div>
    )
}