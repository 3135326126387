import React, { useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import {CustomAppLayout} from "../common/common-components";
import {
  Box,
    BreadcrumbGroup,
    Button,
    Container,
    ContentLayout,
    Form,
    FormField,
    Header,
    Input,
    Link,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {addSupplierBreadcrumbs} from "../common/breadcrumbs";
import {CREATE_SUPPLIER} from "src/config/urlConfig";
import {createClient} from "src/client/AxiosClient";


const Breadcrumbs = () => (
    <BreadcrumbGroup items={addSupplierBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export interface MSShort {
  id?: string;
  name: string;
  country: string;
}

const AddSiteContainer = ({ onSiteChange, index }: { onSiteChange: (site: MSShort, index: number) => void, index: number }) => {
  const [msName, setMsName] = useState("");
  const [msCountry, setMsCountry] = useState("");

  const handleSiteDataChange = () => {
    const siteData: MSShort = {
      name: msName,
      country: msCountry,
    };
    onSiteChange(siteData, index);
  };

  return (
    <div>
      <Container>
      <FormField label="Manufacturing site">
        <SpaceBetween size="m" direction="vertical">
          <ValueWithLabel label="Name">
            <Input onChange={({ detail }) => setMsName(detail.value)} value={msName} onBlur={handleSiteDataChange} />
          </ValueWithLabel>
          <ValueWithLabel label="Country">
            <Input onChange={({ detail }) => setMsCountry(detail.value)} value={msCountry} onBlur={handleSiteDataChange} />
          </ValueWithLabel>
        </SpaceBetween>
      </FormField>
      </Container>
    </div>
  );
};

const Content = (session: any) => {
  const [supplierName, setSupplierName] = React.useState("");
  const [sites, setSites] = useState<MSShort[]>([]);

  const handleSiteChange = (site: MSShort, index: number) => {
    const updatedSites = [...sites];
    updatedSites[index] = site;
    setSites(updatedSites);
  };

  const client = createClient();

  const addSupplier = async () => {
    const reqBody = {
      "name": supplierName,
      "manufacturingSites": sites,
    }
    const response = await client.post(CREATE_SUPPLIER, reqBody);
    if (response.data) {
      console.log(response.data);
    } else {
      console.log(response);
    }
    window.location.href = '/suppliers';
  };

  const addSiteContainers = () => {
    setSites([...sites, { name: '', country: '' }]);
  };

  const siteContainers = sites.map((site, index) => (
    <AddSiteContainer key={index} onSiteChange={handleSiteChange} index={index} />
  ));

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" href="/suppliers">Cancel</Button>
            <Button variant="primary" onClick={() => addSupplier()}>Save supplier</Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="xl">
          <Container
            header={
              <Header variant="h2" description="Insert the general information about the supplier"
                  actions={
                    <Button onClick={addSiteContainers}>Add manufacturing site</Button>
                  }
              >
                Supplier information
              </Header>
            }
          >
            <SpaceBetween size="l" direction="vertical">
              <FormField
                label="Name"
                info={
                  <Link id="main-info-link" variant="info">
                    Info
                  </Link>
                }
              >
                <Input onChange={({ detail }) => setSupplierName(detail.value)} value={supplierName} />
              </FormField>
            </SpaceBetween>
          </Container>
          <div className="AddSiteContainer">
            <SpaceBetween direction="vertical" size="l">
            {siteContainers}
            </SpaceBetween>
          </div>
          
        </SpaceBetween>
      </Form>
    </form>
  );
};

export class AddSupplier extends React.Component {
    render() {
      const mainAppProps = this.props;
      return (
        <body className='awsui-visual-refresh'>
        <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <GlobalNav  />
        </div>
        <CustomAppLayout
        headerSelector="#h"
        navigationOpen={false}
        content={
          <ContentLayout
            header={
              <Header
                variant="h1"
                info={
                  <Link id="main-info-link" variant="info">
                    Info
                  </Link>
                }
                description="Suppliers are listed as companies for the user"
              >
                Add Supplier
              </Header>
            }
          >
            <Content session={mainAppProps}/>
          </ContentLayout>
        }
        breadcrumbs={<Breadcrumbs />}
      />
      </body>
      );
    }
}
