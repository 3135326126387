import React, { useEffect, useState } from "react";
import GlobalNav from "src/components/navigation/GlobalNav";
import { CustomAppLayout } from "../common/common-components";
import { Box, BreadcrumbGroup, Button, Cards, Checkbox, ColumnLayout, Container, ContentLayout, Form, FormField, Header, Input, Link, SpaceBetween, Spinner, Tabs } from "@amzn/awsui-components-react";
import { editSubPackTypeBreadcrumbs } from "../common/breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { createClient } from "src/client/AxiosClient";
import { GET_MASTER_FIELDS, GET_SUB_PACK_TYPE, PUT_SUB_PACK_TYPE } from "src/config/urlConfig";

const Breadcrumbs = () => (
    <BreadcrumbGroup items={editSubPackTypeBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export interface SubPackType {
    id: string;
    name: string;
    packTypeId: string;
    packTypeName: string;
    productTypeId: string;
    productTypeName: string;
    dcCodeFields: any;
    specFields: any;
}

export interface Field {
    category: string;
    creationOfMPN?: boolean;
    filledBySupplier?: boolean;
    id: string;
    label: string;
    supplierUI?: boolean;
    unit: string;
}

interface Spec {
    category: string;
    id: string;
    label: string;
    unit: string;
    checked: boolean;
}

const EditSubPackTypeContent = (props: any) => {

    const [subPackTypeName, setSubPackTypeName] = useState("");

    const navigate= useNavigate();

    const url = window.location.href;
    const regex = /subpacktypes\/edit\/(.*)/;
    const match = url.match(regex);
    let id = "";
    if (match) {
        id = match[1];
    }

    const editSubPackType = async() => {
        const selectedSpecs = specs.filter((spec) => spec.checked === true);
        const selectedSpecObjects = selectedSpecs.map((spec) => {
            return {
              id: spec.id,
              severity: "NON_CRITICAL"
            };
        });

        const designParameterFields = masterFields.filter((field) => field.category === "Design Parameter");
        const designParameterFieldIds = designParameterFields.map((field) => field.id);

        const reqBody = {
            name: subPackTypeName,
            packTypeId: subPackType.packTypeId,
            specFields: selectedSpecObjects,
            dcCodeFields: designParameterFieldIds
        }
        
        console.log(reqBody);

        const response = await client.put(PUT_SUB_PACK_TYPE.replace("{id}", id), reqBody);
        console.log(response);
        navigate('/subpacktypes');
    }

    const [activeTabId, setActiveTabId] = React.useState(
        "specsfields"
    );

    const [subPackType, setSubPackType] = useState<any>();

    const [masterFields, setMasterFields] = useState<any>();

    const [specs, setSpecs] = useState<Spec[]>([]);

    const client = createClient();
    const fetchSubPackType = async () => {
        const response = await client.get(GET_SUB_PACK_TYPE.replace("{id}", id || ""));
        if (response.data) {
            setSubPackType(response.data.subPackType);
            setSubPackTypeName(response.data.subPackType.name);
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return [];
    }

    const fetchMasterFields = async() => {
        const response = await client.get(GET_MASTER_FIELDS);
        if (response.data) {
            console.log(response.data);
            setMasterFields(response.data.masterFields);
            const initialSpecs = response.data.masterFields.map((item) => ({
                category: item.category,
                id: item.id,
                label: item.label,
                unit: item.unit,
                checked: false,
            }))
            setSpecs(initialSpecs);
        } else {
            console.log(response);
        }
    }

    const categorizedItems: { [key: string]: Spec[] } = {};
    if (masterFields) {
        masterFields.forEach((item) => {
            if (!categorizedItems[item.category]) {
            categorizedItems[item.category] = [];
            }
            categorizedItems[item.category].push(item);
        });
    }

    const handleSpecChange = (specIndex: number, checked: boolean) => {
        const updatedSpecs = [...specs];
        updatedSpecs[specIndex].checked = checked;
        setSpecs(updatedSpecs);
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchSubPackType();
            await fetchMasterFields();
        };
        fetchData();
    }, []);

    if (!subPackType || !masterFields) {
        return (
            <div>
                <Spinner/>
            </div>
        )
    }

    return (
        <form onSubmit={event => event.preventDefault()}>
            <Form
                actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link">Cancel</Button>
                    <Button variant="primary" onClick={() => editSubPackType()}>Save sub pack type</Button>
                </SpaceBetween>
                }
            >
                <SpaceBetween size="l" direction="vertical">
                <Container
                header={<Header 
                            variant="h2" 
                            description="General information about the sub pack type">
                            Sub pack type
                        </Header>}
                >
                    <SpaceBetween size="l" direction="vertical">
                    <FormField 
                    label="Name" 
                    info={
                        <Link id="main-info-link" variant="info">
                        Info
                        </Link>
                    }>
                        <Input onChange={({ detail }) => setSubPackTypeName(detail.value)} value={subPackType.name}/>
                    </FormField>
                    <FormField
                        label="Pack type"
                        info={
                            <Link id="main-info-link" variant="info">
                            Info
                            </Link>
                        }
                    >
                        <Input disabled={true} value={subPackType.packTypeName} />
                    </FormField>
                    <ValueWithLabel label="Product type">{subPackType.productTypeName}</ValueWithLabel>
                    </SpaceBetween>
                </Container>
                <Tabs
                    onChange={({ detail }) =>
                        setActiveTabId(detail.activeTabId)
                    }
                    activeTabId={activeTabId}
                    tabs={[
                        {
                            label: "Spec fields",
                            id: "specsfields",
                            content: (
                                <div>
                                    <SpaceBetween direction="vertical" size="l">
                                    <div>
                                        Select which information should be part of the specs sheet for this sub pack type
                                    </div>
                                
                                    <Container>
                                    <ColumnLayout columns={3}>
                                    {masterFields.map((spec, specIndex) => (
                                                spec && (
                                                    <SpecCheckbox
                                                        key={spec.id}
                                                        index={specIndex}
                                                        spec={spec}
                                                        handleSpecChange={handleSpecChange}
                                                    />
                                                )
                                            ))}
                                    </ColumnLayout>
                                    </Container>
                                    </SpaceBetween>
                                    </div>
                            ),
                        },
                        {
                            label: "DC code information",
                            id: "dccodeinfo",
                            content: <DCCodeInfoContent />,
                        }
                    ]}
                />
                </SpaceBetween>
          </Form>
        </form>
    );
}

const DCCodeInfoContent = (props: any) => {
    return (
        <div>
        <SpaceBetween direction="vertical" size="l">
        <div>
            Select which information should be sent to procurement when requesting a dc code for this sub pack type
        </div>
        <Cards
        cardDefinition={{
                header: (item: any) => (
                    <Header variant="h3">{item.name}</Header>
                ),
                sections: [
                  {
                    id: "content",
                    content: item => item.content
                  },
                ]
              }}
        cardsPerRow={[
            { cards: 3},
        ]}
        items={[
            {
                name: "Dimensions and Weight",
                content: (
                <div>
                    <SpaceBetween direction="vertical" size="m">
                    <div><Checkbox disabled checked><ValueWithLabel label="Internal length">cm</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="External length">cm</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="Weight">g</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="Internal width">cm</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="External width">cm</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="Board surface">m sq.</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="Internal height">cm</ValueWithLabel></Checkbox></div>
                    <div><Checkbox disabled checked><ValueWithLabel label="External height">cm</ValueWithLabel></Checkbox></div>
                    </SpaceBetween>
                </div>
                )
            },
        ]}
    />
    </SpaceBetween>
    </div>
    )
}

const SpecCheckbox = ({ spec, index, handleSpecChange }) => {
    const [checked, setChecked] = useState(false);
  
    return (
      <div>
        <Checkbox checked={checked} onChange={({ detail }) => {
            setChecked(detail.checked);
            handleSpecChange(index, detail.checked);
        }}>
          <ValueWithLabel label={spec.label}>{spec.unit}</ValueWithLabel>
        </Checkbox>
      </div>
    );
};

export class EditSubPackType extends React.Component {
    render() {
        return (
            <body className='awsui-visual-refresh'>
                <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
                <GlobalNav  />
                </div>
                <CustomAppLayout
                headerSelector="#h"
                navigationOpen={false}
                content={
                <ContentLayout
                    header={
                    <Header
                        variant="h1"
                        info={
                        <Link id="main-info-link" variant="info">
                            Info
                        </Link>
                        }
                        description="Define which fields are part of this sub pack type specs"
                    >
                        Edit sub pack type
                    </Header>
                    }
                >
                    <EditSubPackTypeContent />
                </ContentLayout>
                }
                breadcrumbs={<Breadcrumbs />}
            />
            </body>
        )
    }
}