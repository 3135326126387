import { Box, BreadcrumbGroup, Button, Container, DatePicker, FormField, Header, Link, Multiselect, Select, SpaceBetween, Spinner, StatusIndicator, Toggle, TokenGroup } from "@amzn/awsui-components-react"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { newPackagingBreadcrumbs } from "src/pages/common/breadcrumbs";
import { PackagingJobProps, SupplierResponse } from "./BasicInput";
import { createClient } from "src/client/AxiosClient";
import { JobDetails } from "src/pages/jobs/JobDetail";
import { CREATE_TECH_DATA_REQUEST, GET_PACKAGING_JOB_DETAIL, GET_SUPPLIER, GET_SUPPLIER_LIST, GET_USER_LIST } from "src/config/urlConfig";
import { SupplierDetail } from "src/pages/common/types";
import { UserResponse } from "src/pages/admin/Users";
import { PackagingJob } from "../NewPackaging";
const Breadcrumbs = () => (
    <BreadcrumbGroup items={newPackagingBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

const ValueWithLabel = ({ label, children } : {label: string, children: any}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

interface Users {
    id: string;
    name: string;
}

const PackagingContainer = ({job, packName, barcode} : {job: PackagingJob, packName: string, barcode: string}) => {
    const [requestSpecsChecked, setRequestSpecsChecked] = useState(false);

    const [dueDate, setDueDate] = useState("");

    const [pocs, setPocs] = useState<any>([]);

    const [reviewers, setReviewers] = useState<any>([]);

    const [specsRequested, setSpecsRequested] = useState(false);

    const [reminderSent, setReminderSent] = useState(false);

    const navigate = useNavigate();

    const getUserIds = (users: any[]) => {
        let ids: string[] = [];
        for (const user of users) {
            ids.push(user.value);
        }
        return ids;
    }

    const createFinalSpecsRequest = async() => {
        let reqBody: any = {};
            reqBody = {
                "requests": [
                    {
                        "barcode": barcode,
                        "technicalDataType": "FROM_SUPPLIER_DATA",
                        "dateDue": Date.parse(dueDate),
                        "specsPocUserIds": getUserIds(pocs),
                        "specsReviewerUserIds": getUserIds(reviewers),
                        "finalSpecs": true,
                    }
                ]
            }
        
        console.log(reqBody);
        const response = await client.post(CREATE_TECH_DATA_REQUEST.replace("{id}", job.id), reqBody);
        if (response.data) {
            console.log(response.data);
        } else {
            console.log(response);
        }
        return;
    }

    const handleSpecsRequest = () => {
        if (specsRequested == false) {
            setSpecsRequested(true);
            createFinalSpecsRequest();
            return;
        }
        if (reminderSent == false) {
            setReminderSent(true);
            return;
        }
        navigate('/pe/newpackaging/review/finalspecs');
    }

    function getDescriptionText() {
        if (reminderSent) {
            return "Waiting for reviewer"
        }
        if (specsRequested) {
            return "Waiting for supplier"
        }
        if (requestSpecsChecked) {
            return "Requesting final specs"
        }
        return "Use draft as final specs"
    }

    function getButtonText() {
        if (reminderSent) {
            return "Review";
        }
        if (specsRequested) {
            return "Send reminder"
        }
        return "Request final specs";
    }


    const [supplierUsers, setSupplierUsers] = useState<any[]>([]);
    const [peUsers, setPeUsers] = useState<any[]>([]);

    const client = createClient();

    const fetchSupplierUsers = async() => {
        const response = await client.get(GET_USER_LIST);
        if (response.data) {
            const data: UserResponse[] = response.data.users;

            const currentSupplierUsers = data.filter((obj) => obj.externalCompanyId == job.supplierId);
            const parsedDataSupplier = currentSupplierUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setSupplierUsers(parsedDataSupplier);

            const currentPEUsers = data.filter((obj) => obj.roles.includes("PACKAGING_ENGINEER"));

            const parsedDataPE = currentPEUsers.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setPeUsers(parsedDataPE);
        
            return response.data.users;
        } else {
            console.log(response);
        }
    }

    useEffect(() => {
        const fetchUserList = async () => {
            await fetchSupplierUsers();
        };
        fetchUserList()
    }, []);

    return (
            <Container
                header={
                    <Header variant="h2"
                        description={<StatusIndicator type="info">{getDescriptionText()}</StatusIndicator>}
                        info={
                            <Link>Info</Link>
                        }
                        actions={
                            <Button disabled={!requestSpecsChecked} onClick={handleSpecsRequest}>{getButtonText()}</Button>
                        }
                    > 
                        {packName + "(" + barcode + ")"}
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                 <FormField label="Final specs" description="Request the final specs from the supplier if anything changed during testing">
                    <Toggle disabled={specsRequested} onChange={({ detail }) =>
                        setRequestSpecsChecked(detail.checked)
                    }
                        checked={requestSpecsChecked}>
                    </Toggle>
                </FormField>
                {requestSpecsChecked && !specsRequested &&
                    <FormField label="Due date">
                        <DatePicker
                            onChange={({ detail }) => setDueDate(detail.value)}
                            value={dueDate}
                            placeholder="YYYY/MM/DD"
                        />
                    </FormField>
                }
                {requestSpecsChecked && specsRequested &&
                    <ValueWithLabel label="Due date">{dueDate}</ValueWithLabel>
                }
                {requestSpecsChecked && !specsRequested &&
                    <FormField label="Point of contact">
                    <Multiselect 
                        selectedOptions={pocs}
                        onChange={({ detail }) =>
                            setPocs(detail.selectedOptions)
                        }
                        options={supplierUsers}
                    />
                    
                </FormField>
                }
                {requestSpecsChecked && specsRequested &&
                    <ValueWithLabel label="Point of contact">{pocs.map(item => item.label).join(', ')}</ValueWithLabel>
                }
                {requestSpecsChecked && !specsRequested &&
                    <FormField label="Reviewer">
                    <Multiselect
                        selectedOptions={reviewers}
                        onChange={({ detail }) =>
                            setReviewers(detail.selectedOptions)
                        }
                        options={peUsers}
                    />
                </FormField>
                }
                {requestSpecsChecked && specsRequested &&
                    <ValueWithLabel label="Reviewer">{reviewers.map(item => item.label).join(', ')}</ValueWithLabel>
                }
                </SpaceBetween>
            </Container>
)}

export const ReviewPackaging: React.FC<PackagingJobProps> = ({job, onJobDetailsChange}) => {
    const client = createClient();
    const [packagingJob, setPackagingJob] = useState<PackagingJob>();

    const { jobId } = useParams<{ jobId: string }>();
    
    const fetchJobDetail = async () => {
        const response = await client.get(GET_PACKAGING_JOB_DETAIL.replace("{id}", jobId || ""));
        if (response.data) {
            setPackagingJob(response.data.packageJob);
            console.log(response.data);
        }
        else {
            console.log(response);
        }
        return [];
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchJobDetail();
        };
        fetchData()
    }, []);

    if (!packagingJob) {
        return (
            <div>
                <Spinner/>
            </div>
        )
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="xl">
            <Container
                header={
                    <Header variant="h2">
                        Supplier
                    </Header>
                }

            >
                <ValueWithLabel label="Supplier name">{packagingJob.supplierName}</ValueWithLabel>
            </Container>
            {packagingJob.barcodes.map((barcode, index) => (
                <PackagingContainer job={packagingJob} packName={packagingJob?.barcodes[index].masterPackName} barcode={packagingJob?.barcodes[index].barcode}/>
            ))}
            </SpaceBetween>
        </div>
    )
}