import * as React from 'react';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';

interface FlashbarTestProps {
    message: string;
    messageType: 'success' | 'info' | 'warning' | 'error';
    onDismiss: () => void;
}

const FlashbarTest: React.FC<FlashbarTestProps> = ({ message, messageType, onDismiss }) => {
  // Define the type for items
  const [items, setItems] = React.useState<FlashbarProps.MessageDefinition[]>([
    {
      type: messageType,
      content: message,
      //action: <Button>View instance</Button>,
      dismissible: true,
      dismissLabel: 'Dismiss message',
      onDismiss: onDismiss,
      id: 'message_1'
    }
  ]);

  return <Flashbar items={items} />;
};

export default FlashbarTest;