import React from 'react';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';

const navHeader = { text: 'APP', href: '/home' };
export const navItems: SideNavigationProps['items'] = [
  {
    type: 'link',
    text: 'Users',
    href: '/users'
  },
  {
    type: 'link',
    text: 'Suppliers',
    href: '/suppliers'
  },
  {
    type: 'link',
    text: 'Job overview',
    href: '/jobs'
  },
  {
    type: 'link',
    text: 'Product type',
    href: '/producttypes'
  },
  {
    type: 'link',
    text: 'Pack type',
    href: '/packtypes'
  },
  {
    type: 'link',
    text: 'Sub pack type',
    href: '/subpacktypes'
  },
  {
    type: 'link',
    text: 'Pack name',
    href: '/packnames'
  },
];

const defaultOnFollowHandler: SideNavigationProps['onFollow'] = event => {
};

interface NavigationProps {
  activeHref?: string;
  header?: SideNavigationProps['header'];
  items?: SideNavigationProps['items'];
  onFollowHandler?: SideNavigationProps['onFollow'];
}

export function Navigation({
  activeHref,
  header = navHeader,
  items = navItems,
  onFollowHandler = defaultOnFollowHandler,
}: NavigationProps) {
  return <SideNavigation items={items} header={header} activeHref={activeHref} onFollow={onFollowHandler} />;
}

export const supplierNavItems: SideNavigationProps['items'] = [
  {
    type: 'link',
    text: 'Packaging Repository',
    href: '/packages'
  },
];

export function SupplierNavigation({
  activeHref,
  header = navHeader,
  items = supplierNavItems,
  onFollowHandler = defaultOnFollowHandler,
}: NavigationProps) {
    return <SideNavigation items={items} header={header} activeHref={activeHref} onFollow={onFollowHandler} />;
}